import {Dialog, IconButton, Box, Button, Divider, Chip} from '@mui/material'
import CloseIcon from 'shared/assets/icons/Close'
import React from 'react'
import {ReactComponent as DefinitionsRectangle} from '../assets/svg/confirmationPopUp/DefinitionsRectangle.svg'
import {ReactComponent as ConfirmationRectangle1} from 'shared/assets/svg/confirmationPopUp/ConfirmationRectangle1.svg'
import {ReactComponent as ConfirmationRectangle2} from 'shared/assets/svg/confirmationPopUp/ConfirmationRectangle2.svg'
import {ReactComponent as ConfirmationPin1} from 'shared/assets/svg/confirmationPopUp/ConfirmationPin.svg'
import {ReactComponent as ConfirmationPin2} from 'shared/assets/svg/confirmationPopUp/ConfirmationPin2.svg'
import {ReactComponent as ConfirmationIcon} from 'shared/assets/svg/confirmationPopUp/ConfirmationIconModerate.svg'
import {ReactComponent as Background} from 'shared/assets/svg/confirmationPopUp/BackgroundAconfirmation.svg'
import theme from "../../app/Theme";

export default function DefinitionConfirmationModal({
                                                        definition,
                                                        wait,
                                                        agreeFunc,
                                                        goToSettingsFunc
                                                    }) {

    const content = (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '504px',
            gap: '24px',
            padding: '12px 16px'
        }}>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center'}}>
                <Box
                    className='modal-header'
                    sx={{display: 'flex'}}
                >
                    Well done, your weather definition is created!
                </Box>
                <IconButton
                    className='modal__close-button'
                    onClick={agreeFunc}
                    sx={{marginLeft: 'auto', padding: '4px', width: '32px', height: '32px'}}
                    disabled={wait}
                >
                    <CloseIcon sx={{width: '16px', height: '16px'}}/>
                </IconButton>
            </Box>
            <Box style={{position: "relative", height: "80px", overflow: "hidden"}}>
                <Box style={{position: "absolute", left: '0px', top: '0px'}}>
                    <Background/>
                </Box>
                <Box style={{position: "absolute", left: '0px', top: '0px'}}>
                    <ConfirmationRectangle1/>
                </Box>
                <Box style={{position: "absolute", left: '0px', top: '35px'}}>
                    <ConfirmationRectangle2/>
                </Box>
                <Box sx={{position: 'absolute', left: '205px', top: '10px'}}>
                    <ConfirmationPin1/>
                </Box>
                <Box sx={{position: 'absolute', right: '125px', top: '0px'}}>
                    <ConfirmationPin2/>
                </Box>
                <Box sx={{position: 'absolute', right: '110px', top: '52px'}}>
                    <ConfirmationIcon/>
                </Box>
                <Box sx={{position: 'absolute', right: '0px', top: '0px', overflow: "hidden"}}>
                    <DefinitionsRectangle style={{borderRadius: "0px 12px 12px 0px"}}/>
                </Box>
            </Box>
            <Box style={{
                height: "48px",
                padding: "8px 16px",
                border: "1px solid var(--palette-grey-100)",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <div style={{fontSize: "16px", fontWeight: "500", color: "var(--palette-grey-900)"}}>
                    {definition.name}
                </div>
                <Chip
                    variant={theme.palette.severity.variant[definition.severity]}
                    size={'large'}
                    label={definition.severity}
                />
            </Box>
            <Box
                className='modal-delete-text'
                sx={{width: '100%', fontSize: '16px'}}
            >
                You can overview a weather definition created. To manage weather definition, go to Settings > Weather
                definition.
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: "12px"}}>
                <Divider/>
                <Box sx={{display: 'flex', gap: '12px'}}>
                    <Button
                        style={{fontWeight: '400', height: '40px', width: '100%'}}
                        disabled={wait}
                        onClick={agreeFunc}
                    >
                        Got it
                    </Button>
                    <Button
                        style={{fontWeight: '400', height: '40px', width: '100%'}}
                        variant={'outlined'}
                        color={'secondary'}
                        onClick={goToSettingsFunc}
                        disabled={wait}
                    >
                        Go to Settings
                    </Button>
                </Box>
            </Box>
        </Box>
    )
    return (
        <Dialog
            className='report-modal'
            open={true}
            onClose={agreeFunc}
            sx={{'& .MuiPaper-root': {maxWidth: '700px'}}}
        >
            {content}
        </Dialog>
    )
}
