import {createSvgIcon} from "@mui/material/utils";

export default function StartIcon(props) {
    const Icon = createSvgIcon(
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSvgIcon-root MuiSvgIcon-fontSizeLarge css-18wq8ra" fill="none"
             focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PlayCircleOutlineIcon" tabIndex="-1">
            <path
                d="m10 16.5 6-4.5-6-4.5zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8" fill="currentColor"></path>
        </svg>, 'Start')
    return <Icon {...props} data-cy="start-icon"/>
}