export class AbstractTextProduct {
    constructor(options) {
        const defaultOptions = {
            name: new Date().getTime().toString(),
            isEnabled: false,
            timeCache: 60000, //time cache life and updateTime. 0 - without update
            //map is required
            bottomLayerForFillLayers: 'tunnel-service-track-casing' //for positioning over tiles
        }
        this.options = Object.assign(defaultOptions, options);
        this.options.sourceName = 'source-' + this.options.name;
        this._canvas = this.options.map.getCanvas();
        this.layers = [];
        this._mouseEnterHandler = this._mouseEnterHandler.bind(this);
        this._mouseLeaveHandler = this._mouseLeaveHandler.bind(this);
    }

    _update(cb) {
        if (!this.options.isEnabled) return;
        this._getSource(source => {
            if (!this.options.isEnabled) return;
            const nameSource = this.options.sourceName;
            let plantedSrc = this.options.map.getSource(nameSource);
            if (!plantedSrc) {
                this.options.map.addSource(nameSource, source);
            } else {
                plantedSrc.setData(source.data);
            }
            (typeof (cb) === "function") && cb();
        });
    }

    enable() {
        this.options.isEnabled = true;
        this._update(() => {
            let layers = this.createLayers();
            layers.forEach(layer => {
                this.options.map.addLayer(layer, 'place-continent');
            });
        });
        if (Array.isArray(this.options.events)) {
            this.options.events.forEach(eventName =>
                this.options.map.on(eventName,
                    () => this._update()));
        }
        window.clearInterval(this.intervalIndex);
        if (this.options.timeCache) {
            this.intervalIndex = window.setInterval(() => this._update(), this.options.timeCache);
        }
    }

    disable() {
        this.options.isEnabled = false;
        window.clearInterval(this.intervalIndex);
        this.layers.forEach(layer => {
            const l = this.options.map.getLayer(layer.id);
            if (l){
                this.options.map.removeLayer(layer.id);
            }
            this.options.map.off('mouseenter', layer.id, this._mouseEnterHandler);
            this.options.map.off('mouseleave', layer.id, this._mouseLeaveHandler);
        });
        const src = this.options.map.getSource(this.options.sourceName);
        if (src){
            this.options.map.removeSource(this.options.sourceName);
        }
        if (Array.isArray(this.options.events)) {
            this.options.events.forEach(eventName =>
                this.options.map.off(eventName,
                    () => this._update()));
        }
        this.layers = [];
    }

    _getSource(cb) {
        // for override
    }

    createSingleLayer(opts) {
        if (!opts) {
            return;
        }
        const index = this.layers.length;
        let layer = {
            id: this.options.name + index,
            type: opts.type,
            source: this.options.sourceName
        }
        opts.paint && (layer.paint = opts.paint);
        opts.filter && (layer.filter = opts.filter);
        opts.layout && (layer.layout = opts.layout);
        this.layers.push(layer);
        this.options.map.on('mouseenter', layer.id, this._mouseEnterHandler);
        this.options.map.on('mouseleave', layer.id, this._mouseLeaveHandler);
        return layer;
    }

    _mouseEnterHandler(){
        this._canvas.style.cursor = 'pointer';
    }

    _mouseLeaveHandler(){
        this._canvas.style.cursor = '';
    }



    createLayers() {
        // for override
        return [];
    }

    mapData(data) {
        // for override
        return [];
    }

    setTime(time){
        // for override
    }

    loadImage(name, srcStringBase64, isSdf = false) {
        const map = this.options.map;
        if (map.hasImage(name)) {
            return;
        }
        map.loadImage(srcStringBase64,
            function (error, image) {
                error && console.log(error);
                map.addImage(name, image, {
                    'sdf': isSdf
                });
            }
        );
    }
}