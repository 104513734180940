import {Chip, Link, Paper} from '@mui/material';
import Modal from "@mui/material/Modal";
import dayjs from "dayjs";
import React, {useState} from 'react';
import theme from "app/Theme";
import {Spacer} from "shared/ui/Spacer";
import MapPinIcon from "../../../shared/assets/icons/MapPin";
import {AlertPopup} from "./AlertPopup";
import 'pages/alerts/ui/AlertsPage.css';
import {ShowReportButton} from "features/alert/ShowReportButton";


export default function AlertListItem({alert, alertMoreButton}) {
    const [isOpenAlertModal, setIsOpenAlertPopup] = useState(false);

    const handleOpenAlertPopup = event => {
        event.stopPropagation()
        setIsOpenAlertPopup(true)
    }
    const handleCloseAlertPopup = event => {
        event.stopPropagation()
        setIsOpenAlertPopup(false)
    }
    return (
        <Paper
            className={'alertListItem row'}
            onClick={handleOpenAlertPopup}
            sx={{
                boxShadow: 'none',
                width: '100%',
                padding: '16px',
                gap: '16px',
                justifyContent: 'flex-start',
                flexWrap: 'wrap',
                cursor: 'pointer',
                ":hover": {
                    boxShadow: theme.palette.shadow.middle,
                    '& .subtitle': {
                        color: theme.palette.primary.dark,
                    },
                },
            }}>
            <Modal
                open={isOpenAlertModal}
                onClose={handleCloseAlertPopup}
            >
                <AlertPopup
                    alert={alert}
                    onClose={handleCloseAlertPopup}
                    alertMoreButton={alertMoreButton}
                />
            </Modal>
            <div className={'subtitle medium'}>{alert.type}</div>
            <div className={'paragraph'}
                     style={{color: theme.palette.notification.status[alert.category]}}>
                    {alert.category}
            </div>
            <Spacer/>
            <span className={'field'}>
                <div className={'paragraph fieldLabel'}>
                    Start:
                </div>
                <div className={'paragraph fieldText'}>
                    {dayjs(alert.start_date).format('MMM D, h:mm A')}
                </div>
            </span>
            <span className={'field'}>
                <div className={'paragraph fieldLabel'}>
                    End:
                </div>
                <div className={'paragraph fieldText'}>
                    {alert.triggered ? "Ongoing" : dayjs(alert.end_date).format('MMM D, h:mm A')}
                </div>
            </span>
            {alert.api_alert.report &&
                <ShowReportButton reportLink={alert.api_alert.report} style={{width: 'max-content'}}/>
            }
            <Chip
                icon={<MapPinIcon size={'small'}/>}
                label={alert.location}
                size={'small'}
            />
            <Chip
                label={alert.severity}
                variant={theme.palette.weather.variant[alert.severity]}
                size={'small'}
            />
            {alertMoreButton}
        </Paper>

    );
}

export const AlertListItemMemoized = React.memo(AlertListItem);
