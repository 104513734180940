import {createTheme} from '@mui/material/styles';
import React from 'react';

import CloseIcon from "../shared/assets/icons/Close";
import ArrowDownSmallIcon from "shared/assets/icons/ArrowDownSmall";
import CalendarIcon from 'shared/assets/icons/Calendar';
import RadioIcon from "../shared/assets/icons/Radio";
import {MenuItem} from "@mui/material";
import dayjs from "dayjs";


const {palette} = {
        palette: {
            common: {
                white: '#fff',
                black: '#000',
                vividRed: '#E64610',
                electricGreen: '#92DA1A',
                lightGreen: '#97E9AB',
                darkBlue: '#0D2B54',
                violet: '#6D008C',
                pink: '#FBEDFF',
            },
            other: {
                tooltip: '#2D343D',
            },
            text: {
                primary: '#0F2744',
                secondary: '#728095',
            },
            weather: { // TODO: replace weather to severity
                Critical: {
                    light: '#FEEEEE',
                    middle: '#F58787',
                    main: '#F05454',
                    dark: '#A83B3B',
                },
                Severe: {
                    light: '#FFF9EE',
                    middle: '#FFF3E8',
                    main: '#FA963A',
                    dark: '#E86F00',
                },
                Moderate: {
                    light: '#F8FAFF',
                    middle: '#689DFF',
                    main: '#2773FF',
                    dark: '#1B51B3',
                },
                variant: {
                    Critical: 'red',
                    Severe: 'yellow',
                    Moderate: 'blue',
                    Default: 'grey',
                },
            },
            severity: {
                Critical: {
                    light: '#FEEEEE',
                    middle: '#F58787',
                    main: '#F05454',
                    dark: '#A83B3B',
                },
                Severe: {
                    light: '#FFF9EE',
                    middle: '#FFF3E8',
                    main: '#FA963A',
                    dark: '#E86F00',
                },
                Moderate: {
                    light: '#F8FAFF',
                    middle: '#689DFF',
                    main: '#2773FF',
                    dark: '#1B51B3',
                },
                variant: {
                    Critical: 'red',
                    Severe: 'yellow',
                    Moderate: 'blue',
                    Default: 'grey',
                },
            },
            action: {
                disabledBackground: '#F0F2F5',
                disabled: '#AFB8C0',
            },
            controls: {
                resting: '#E3E7EB',
                disabled: '#EEF1F4'
            },
            primaryRed: {
                light: '#E81830',
                middle: '#DA1A32',
                main: '#921221',
                dark: '#510D15',
            },
            primary: {
                light: '#D8F3FF',
                middle: '#50B2DF',
                main: '#0496D9',
                dark: '#09638C',
            },
            secondary: {
                light: '#f3f9ff',
                middle: '#9aabbb',
                main: '#7f8f9f',
                dark: '#728095',
            },
            success: {
                light: '#E8F9F5',
                middle: '#5CD8B8',
                main: '#16C79A',
                dark: '#0F8B6C',
            },
            error: {
                light: '#FEEEEE',
                middle: '#F58787',
                main: '#F05454',
                dark: '#A83B3B',
            },
            warning: {
                light: '#FFF9EE',
                middle: '#FFF3E8',
                main: '#FA963A',
                dark: '#E86F00',
            },
            info: {
                light: '#F8FAFF',
                middle: '#689DFF',
                main: '#2773FF',
                dark: '#1B51B3',
            },
            grey: {
                background: '#FAFBFE',
                50: '#F8FAFF',
                100: '#F0F2F5',
                200: '#AFB8C0',
                300: '#94A3B2',
                400: '#7F8F9F',
                500: '#677A8E',
                600: '#5A6672',
                700: '#48515B',
                800: '#333C47',
                900: '#171E27',
            },
            shadow: {
                small: '0px 1px 3px 0px rgba(103, 122, 142, 0.24)',
                middle: '0px 2px 6px 0px rgba(103, 122, 142, 0.24)',
                big: '0px 4px 12px 0px rgba(103, 122, 142, 0.24)',
                error: {
                    small: '0px 1px 3px 0px rgba(168, 59, 59, 0.24)',
                },
            },
        }
    }
;

palette.notification = {
    status: {
        Historical: palette.grey[500],
        Live: palette.success.dark,
        Expired: palette.error.dark,
        Forecast: palette.primary.dark,
    }
}
palette.subscription = {
    variant: {
        'No subscription': 'grey',
        'Admin': 'blue',
        'Invite sent': 'yellow',
        'Active subscription': 'green',
        'Subscription expired': 'red',
    }
}

let theme = createTheme({
    palette: palette,

    components: {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    border: '1px solid',
                    borderColor: palette.grey[100],
                    backgroundColor: palette.grey.background,
                }
            },
            variants: [
                {
                    props: {size: 'small'},
                    style: {width: 16, height: 16}
                },
                {
                    props: {size: 'medium'},
                    style: {width: 24, height: 24}
                },
                {
                    props: {size: 'large'},
                    style: {width: 32, height: 32}
                }
            ]
        },
        MuiAccordion: {
            defaultProps: {
                disableGutters: true,
                elevation: 0,
                square: true,
            },
            styleOverrides: {
                root: {
                    padding: 0,
                    boxShadow: 'none',
                    '&:before': {
                        display: 'none',
                    },
                }
            },
            variants: [
                {
                    props: {variant: 'light'},
                    style: {
                        borderRadius: '8px !important',
                        border: '1px solid',
                        borderColor: palette.grey[100],
                        background: palette.grey.background,
                    }
                },
                {
                    props: {variant: 'dark'},
                    style: {
                        backgroundColor: palette.grey[100],
                        borderBottom: '1px solid',
                        borderBottomColor: '#DBE0EA',
                        borderRadius: 0,
                    }
                }
            ]
        },
        MuiAccordionSummary: {
            defaultProps: {
                expandIcon: <ArrowDownSmallIcon/>,
            },
            styleOverrides: {
                root: {
                    minHeight: 0,
                    height: '40px',
                    margin: 0,
                    borderRadius: '8px',
                },
                content: {
                    padding: 0,
                    margin: 0,
                },
            },
            variants: [
                {
                    props: {variant: 'light'},
                    style: {
                        padding: '10px 10px 10px 12px',
                        borderBottom: '1px solid',
                        borderBottomColor: palette.grey[100],
                        background: 'white',
                    }
                },
                {
                    props: {variant: 'dark'},
                    style: {
                        paddingRight: '20px',
                    }
                }
            ]
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    flexDirection: 'column',
                    border: 0,
                }
            },
            variants: [
                {
                    props: {variant: 'light'},
                    style: {
                        borderBottomRadius: '8px',
                        background: palette.grey.background,
                        padding: '8px 10px 8px 10px',
                    }
                },
                {
                    props: {variant: 'dark'},
                    style: {
                        padding: '8px 16px',
                    }
                }
            ]
        },
        MuiBox: {
            variants: [
                {
                    props: {variant: 'outlined'},
                    style: {
                        border: '1px solid',
                        borderRadius: 8,
                        borderColor: palette.grey[100],
                    }
                }
            ]
        },
        MuiBreadcrumbs: {
            styleOverrides: {
                root: {
                    color: palette.grey[600],
                    lineHeight: '32px',
                    fontSize: '24px',
                }
            }
        },
        MuiDialog: {
            variants: [
                {
                    props: {variant: 'draggable'},
                    style: {
                        pointerEvents: 'none'
                    }
                }
            ]
        },
        MuiDialogTitle: {
            variants: [
                {
                    props: {variant: 'draggable'},
                    style: {
                        backgroundColor: palette.grey.background,
                        border: '1px solid',
                        borderColor: palette.grey[100],
                        borderRadius: '8px',
                        height: '24px',
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'grab',
                        '&:active': {
                            cursor: 'grabbing',
                        }
                    }
                }
            ]
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '0 16px 16px',
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: palette.grey[100],
                    width: '100%',
                }
            },
            variants: [
                {
                    props: {orientation: 'vertical'},
                    style: {
                        width: 'initial',
                    }
                }
            ]
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    padding: '16px 24px',
                }
            },
            variants: [
                {
                    props: {anchor: 'right'},
                    style: {
                        '& .MuiPaper-root': {
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                        }
                    }
                },
                {
                    props: {anchor: 'left'},
                    style: {
                        '& .MuiPaper-root': {
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                        }
                    }
                }
            ]
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '24px',
                    letterSpacing: '0.025em',
                    color: palette.grey[900],
                }
            },
            variants: [
                {
                    props: {variant: 'CheckCircle'},
                    style: {
                        color: palette.grey[500],
                        fontSize: '12px',
                    },
                },
                {
                    props: {variant: 'label'}, // TODO: deprecated. Use className={"label"}
                    style: {
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '4px',
                        fontSize: '14px',
                        lineHeight: '24px',
                        color: palette.grey[700],
                    }
                },
                {
                    props: {variant: 'medium'},
                    style: {fontWeight: 500},
                }
            ]
        },
        MuiSvgIcon: {
            defaultProps: {
                size: 'medium',
            },
            styleOverrides: {
                root: {
                    color: palette.grey[500],
                    pointerEvents: "none",
                }
            },
            variants: [
                {
                    props: {size: 'tiny'},
                    style: {fontSize: '6px !important',}
                },
                {
                    props: {size: 'small'},
                    style: {fontSize: '16px !important',}
                },
                {
                    props: {size: 'medium'},
                    style: {fontSize: '24px !important',}
                },
                {
                    props: {size: 'large'},
                    style: {fontSize: '32px !important',}
                },
                {
                    props: {color: 'info'},
                    style: {color: palette.info.dark},
                },
                {
                    props: {color: 'error'},
                    style: {color: palette.error.dark},
                },
            ]
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    margin: 0,
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    margin: 0,
                    gap: '4px',
                },
                label: {
                    fontSize: '14px',
                    lineHeight: '24px',
                    color: palette.grey[700],
                },
            }
        },
        MuiAutocomplete: {
            defaultProps: {
                disableClearable: true,
                popupIcon: <ArrowDownSmallIcon size={'small'}/>,
                renderOption: ({className, ...props}, option) =>
                    <MenuItem {...props}>
                        {option.label}
                    </MenuItem>,

            },
            styleOverrides: {
                root: {
                    '& .MuiInputBase-root': {
                        paddingTop: 0,
                        paddingBottom: 0,
                    }
                },
                paper: {
                    marginTop: '8px',
                    padding: '12px 8px',
                },
                listbox: {
                    padding: '0',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                    maxHeight: '200px',
                    overflow: 'auto',
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: palette.grey[900],
                    minHeight: '40px',
                    "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: palette.grey[200],
                    },
                    '&.MuiOutlinedInput-root': {
                        borderRadius: '8px',
                        '& fieldset': {
                            borderColor: 'transparent',
                            boxShadow: palette.shadow.small,
                        },
                        '&:hover fieldset': {
                            borderColor: palette.primary.dark,
                            boxShadow: 'none',
                        },
                        '&:hover.Mui-error .MuiOutlinedInput-notchedOutline': {
                            borderColor: palette.error.main,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: palette.primary.dark,
                            borderWidth: '1px',
                            boxShadow: 'none',
                        },
                        '&.Mui-focused.Mui-error fieldset': {
                            borderColor: palette.error.main,
                        },
                        '&.Mui-disabled fieldset': {
                            border: 'none',
                            boxShadow: 'none',
                        },
                    },
                },
                input: {
                    '&::placeholder': {
                        fontSize: '14px',
                        color: palette.grey[400],
                        opacity: 1,
                    }
                }
            },
            variants: [
                {
                    props: {size: 'small'},
                    style: {
                        height: 32,
                        '& input': {
                            fontSize: '14px',
                        }
                    }
                },
            ]
        },
        MuiOutlinedInput: {
            styleOverrides: {
                input: {
                    padding: '10px 10px 10px 12px',
                }
            },
        },
        MuiTextField: {
            defaultProps: {
                placeholder: 'Type in',
                autoComplete: 'off',
            },
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        backgroundColor: 'white',
                    }
                }
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    padding: '1px',
                }
            },
        },
        MuiSelect: {
            defaultProps: {
                IconComponent: props => <ArrowDownSmallIcon size={'small'} {...props}/>,
            },
            styleOverrides: {
                root: {
                    path: {
                        fill: palette.grey[500],
                    },
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    marginTop: '8px',
                    padding: '12px 8px',
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: '0',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    maxHeight: '156px',
                }
            }
        },
        MuiMenuItem: {
            defaultProps: {
                color: 'secondary',
                disableGutters: true,
                disableTouchRipple: true,
            },
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    fontWeight: '400px',
                    display: 'flex',
                    background: palette.common.white,
                    padding: '0 8px',
                    height: '28px',
                    gap: '4px',
                    lineHeight: '28px',
                    alignItems: 'center',
                    borderRadius: '8px',
                    minHeight: 0,
                },
            },
            variants: [
                {
                    props: {color: 'primary'},
                    style: {}
                },
                {
                    props: {color: 'secondary'},
                    style: {
                        color: palette.other.tooltip,
                        '&.Mui-selected, &:select, &.Mui-focus': {
                            backgroundColor: 'transparent',
                            color: palette.primary.dark,
                            '&:hover': {
                                backgroundColor: 'transparent',
                            }
                        },
                        '&.MuiButtonBase-root.Mui-selected': {
                            backgroundColor: 'transparent',
                            color: palette.primary.dark,
                        },
                        '& .MuiLink-root': {
                            color: palette.other.tooltip,
                        },
                    }
                },
                {
                    props: {color: 'success'},
                    style: {}
                },
                {
                    props: {color: 'error'},
                    style: {
                        '& .MuiSvgIcon-root': {color: palette.error.dark,},
                        color: palette.error.dark,
                    }
                },
                {
                    props: {color: 'info'},
                    style: {}
                },
                {
                    props: {color: 'warning'},
                    style: {}
                }
            ]
        },
        MuiButton: {
            defaultProps: {
                variant: 'contained',
                color: 'primary',
                size: 'medium',
            },
            styleOverrides: {
                root: {
                    minWidth: 0,
                    borderRadius: '8px',
                    width: 'auto',
                    fontWeight: 500,
                    padding: '8px 12px',
                    textTransform: 'none',
                    boxShadow: 'none',
                    border: '1px solid',
                    borderColor: 'transparent',
                    ':disabled': {
                        backgroundColor: palette.grey.background,
                        color: palette.grey[200],
                        borderWidth: '1px',
                        borderColor: palette.grey[100],
                    },
                    '&.Mui-disabled': {
                        '& .MuiSvgIcon-root': {
                            color: palette.grey[200],
                        }
                    }
                },
            },
            variants: [
                {
                    props: {size: 'small'},
                    style: {
                        height: '32px',
                        fontSize: '14px',
                    }
                },
                {
                    props: {size: 'medium'},
                    style: {
                        height: '40px',
                        fontSize: '16px',
                    }
                },
                {
                    props: {variant: 'contained'},
                    style: {
                        boxShadow: 'none',
                        ':disabled': {
                            background: palette.grey[100],
                            color: palette.grey[200],
                        },
                        ':hover': {
                            boxShadow: 'none',
                        }
                    }
                },
                {
                    props: {variant: 'outlined'},
                    style: {
                        border: '1px solid transparent',
                        background: 'white',
                        boxShadow: palette.shadow.small,
                        ':disabled': {
                            backgroundColor: palette.grey.background,
                            borderColor: palette.grey[100],
                            boxShadow: 'none',
                            color: palette.grey[200],
                        },
                        ':hover': {
                            border: '1px solid',
                            boxShadow: 'none',
                            backgroundColor: 'white',
                        },
                    }
                },
                {
                    props: {variant: 'text'},
                    style: {
                        border: '1px solid transparent',
                        boxShadow: 'none',
                        ':disabled': {
                            backgroundColor: 'transparent',
                            borderColor: palette.grey[100],
                            color: palette.grey[200],
                        },
                        ':hover': {
                            border: '1px solid',
                            background: 'transparent',
                        },
                    }
                },
                {
                    props: {color: 'primary'},
                    style: {
                        color: palette.primary.dark,
                        '& .MuiSvgIcon-root': {color: palette.primary.dark,},
                        ':hover': {
                            borderColor: palette.primary.dark,
                            color: palette.primary.dark,
                        },
                    }
                },
                {
                    props: {color: 'secondary'},
                    style: {
                        color: palette.grey[800],
                        ':hover': {
                            borderColor: palette.primary.dark,
                            color: palette.primary.dark,
                            '& .MuiSvgIcon-root': {color: palette.primary.dark,},
                        },
                    }
                },
                {
                    props: {color: 'error'},
                    style: {
                        color: palette.error.dark,
                        boxShadow: palette.shadow.error.small,
                        '& .MuiSvgIcon-root': {color: palette.error.dark,},
                        '&:hover': {
                            borderColor: palette.error.dark,
                            color: palette.error.dark,
                        },
                        '&:disabled': {
                            boxShadow: 'none',
                        }
                    }
                },
                {
                    props: {variant: 'contained', color: 'primary'},
                    style: {
                        backgroundColor: palette.primary.dark,
                        color: palette.common.white,
                        '& .MuiSvgIcon-root': {color: 'white',},
                        ':hover': {
                            backgroundColor: palette.common.white,
                            color: palette.primary.dark,
                            '& .MuiSvgIcon-root': {
                                color: palette.primary.dark,
                            },
                        },
                    }
                },
                {
                    props: {variant: 'contained', color: 'error'},
                    style: {
                        backgroundColor: palette.error.light,
                        color: palette.error.dark,
                        '& .MuiSvgIcon-root': {color: palette.error.dark,},
                        ':hover': {
                            backgroundColor: palette.error.light,
                            color: palette.error.dark,
                        },
                    }
                },
                {
                    props: {variant: 'text', color: 'error'},
                    style: {
                        boxShadow: 'none',
                    }
                },
            ]
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    '&.Mui-disabled': {
                        '& .MuiSvgIcon-root': {
                            color: palette.grey[200],
                        }
                    }
                },
            },
            variants: [
                {
                    props: {variant: 'outlined'},
                    style: {
                        border: '1px solid',
                        borderColor: palette.grey[100],
                        backgroundColor: 'white',
                    }
                },
                {
                    props: {size: 'tiny'},
                    style: {width: 24, height: 24},
                },
                {
                    props: {size: 'small'},
                    style: {width: 32, height: 32},
                }
            ]
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: 0,
                    height: 32,
                },
                scroller: {
                    height: 'max-content',
                },
                indicator: {
                    display: 'none',
                },
                flexContainer: {
                    gap: '16px',
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                    borderRadius: 8,
                    textTransform: "none",
                    height: 32,
                    minHeight: 32,
                    padding: '4px 12px',
                    color: palette.secondary.dark,
                    '&.Mui-selected': {
                        backgroundColor: 'white',
                        color: palette.primary.dark,
                    }
                },
            }
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    borderRadius: '8px',
                    backgroundColor: palette.grey[100],
                    padding: '4px',
                    gap: 8,
                    width: 'max-content',
                    height: '40px',
                    background: palette.grey.background,

                }
            },
            variants: [
                {
                    props: {size: 'small'},
                    style: {
                        height: '32px',
                    }
                },
            ]
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    borderColor: 'transparent',
                    borderRadius: '8px !important',
                    padding: '4px 12px',
                    color: palette.grey[500],
                    backgroundColor: 'transparent',
                    height: '32px',
                    textTransform: 'none',
                    textAlign: 'center',
                    display: 'flex',
                    gap: '4px',
                    width: '100%',
                }
            },
            variants: [
                {
                    props: {size: 'small'},
                    style: {
                        height: '24px',
                    }
                },
                {
                    props: {variant: 'secondary'},
                    style: {
                        '&:hover': {
                            color: 'white',
                            backgroundColor: palette.primary.dark,
                        },
                        '&.Mui-selected:hover': {
                            backgroundColor: 'white',
                            borderWidth: '1px',
                            boxShadow: 'none',
                            borderColor: `${palette.primary.dark} !important`,
                            color: palette.primary.dark,
                        },
                        '&.Mui-selected': {
                            borderRadius: "8px",
                            border: "1px solid var(--color-coding-grayscale-100, #F0F2F5)",
                            background: "var(--color-coding-grayscale-0, #FFF)",
                            boxShadow: "0px 1px 2px 0px rgba(97, 97, 97, 0.20)"
                        }
                    }
                },
                {
                    props: {variant: 'secondary blue'},
                    style: {
                        '&:hover': {
                            color: 'white',
                            backgroundColor: palette.primary.dark,
                            '& .MuiSvgIcon-root': {color: 'white'},
                        },
                        '&.Mui-selected:hover': {
                            backgroundColor: 'white',
                            borderWidth: '1px',
                            boxShadow: 'none',
                            borderColor: palette.primary.dark,
                            color: palette.primary.dark,
                            '& .MuiTypography-root': {
                                color: palette.primary.dark,
                            }
                        },
                        '&.Mui-selected': {
                            color: palette.primary.dark,
                            backgroundColor: 'white',
                            boxShadow: palette.shadow.small,
                            '& .MuiSvgIcon-root': {color: palette.primary.dark},
                        }
                    }
                },
                {
                    props: {variant: 'secondary light'},
                    style: {
                        '&:hover': {
                            background: "white",
                            border: '1px solid',
                            borderColor: `${palette.primary.dark} !important`,
                            color: palette.primary.dark,
                            '& .MuiSvgIcon-root': {
                                color: palette.primary.dark,
                            },
                            '& .MuiTypography-root': {
                                color: palette.primary.dark,
                            },
                        },
                        '&.Mui-selected:hover': {
                            backgroundColor: 'white',
                            borderWidth: '1px',
                            boxShadow: 'none',
                        },
                        '&.Mui-selected': {
                            background: "white",
                            boxShadow: 'none',
                            border: '1px solid',
                            borderColor: `${palette.primary.dark} !important`,
                            color: palette.primary.dark,
                            '& .MuiSvgIcon-root': {
                                color: palette.primary.dark,
                            },
                            '& .MuiTypography-root': {
                                color: palette.primary.dark,
                            },
                        },
                        borderRadius: "8px",
                        border: '1px solid',
                        borderColor: `transparent !important`,
                        background: "white",
                        boxShadow: 'none',
                    }
                },
            ]
        },
        MuiSwitch: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    padding: '8px',
                    height: '32px',
                },
                switchBase: {
                    height: '32px',
                    width: '32px',
                    padding: '8px',
                    '&.Mui-checked': {
                        transform: 'translateX(16px)',
                    },
                    '&.Mui-checked + .MuiSwitch-track': {
                        background: palette.primary.dark,
                        opacity: 1,
                    },
                    '&:hover': {
                        background: 'none',

                        '& .MuiSwitch-thumb': {
                            border: '1px solid',
                            borderColor: palette.primary.dark,
                        }
                    },
                    '&.Mui-checked:hover': {
                        background: 'none',

                        '& + .MuiSwitch-track': {
                        background: palette.controls.resting,

                        },
                        '& .MuiSwitch-thumb': {
                            border: '1px solid transparent',
                            background: palette.primary.dark,
                        }
                    },

                    '&:focus': {
                        '& + .MuiSwitch-track': {
                            border: '2px solid',
                            borderColor: palette.primary.dark,
                        },
                        '& .MuiSwitch-thumb': {
                            width: '10px',
                            height: '10px',
                            background: palette.primary.dark,
                        }
                    },
                    '&.Mui-checked:focus + .MuiSwitch-track': {
                        background: palette.controls.resting,
                    },

                    '&.Mui-disabled': {
                        '& .MuiSwitch-thumb': {
                            boxShadow: 'none',
                        },
                        '& + .MuiSwitch-track': {
                            background: palette.controls.disabled,
                            opacity: 1,
                        },
                    },
                },
                thumb: {
                    background: 'white',
                    boxShadow: palette.shadow.small,
                    width: '12px',
                    height: '12px',
                },
                track: {
                    background: palette.controls.resting,
                    width: '32px',
                    height: '16px',
                    borderRadius: '8px',
                    opacity: 1,
                }
            }
        },
        MuiChip: {
            defaultProps: {
                variant: 'grey',
                deleteIcon: <CloseIcon/>,
            },
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    color: palette.grey[800],
                    width: 'max-content',
                    fontWeight: 400,
                },
                label: {
                    fontSize: 16,
                }
            },
            variants: [
                {
                    props: {size: 'small'},
                    style: {
                        height: '24px',
                        '& .MuiChip-label': {
                            fontSize: '14px',
                        }
                    },
                },
                {
                    props: {variant: 'avatar'},
                    style: {
                        backgroundColor: 'white',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: palette.grey[100],
                        color: palette.info.dark,
                    }
                },
                {
                    props: {variant: 'white'},
                    style: {
                        backgroundColor: 'white',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: palette.grey[100],
                        '& .MuiChip-icon, & .MuiChip-deleteIcon': {
                            color: palette.grey[500]
                        }
                    }
                },
                {
                    props: {variant: 'grey'},
                    style: {
                        backgroundColor: palette.grey[100],
                        '& .MuiChip-icon, & .MuiChip-deleteIcon': {
                            color: palette.grey[500]
                        }
                    }
                },
                {
                    props: {variant: 'dark grey'},
                    style: {
                        backgroundColor: palette.grey[100],
                        '& .MuiChip-icon, & .MuiChip-deleteIcon': {
                            color: palette.grey[900]
                        }
                    }
                },
                {
                    props: {variant: 'red'},
                    style: {
                        backgroundColor: palette.error.light,
                        color: palette.error.dark,
                        '& .MuiChip-icon, & .MuiChip-deleteIcon': {
                            color: palette.error.dark,
                            ':hover': {color: palette.error.middle}
                        }
                    }
                },
                {
                    props: {variant: 'yellow'},
                    style: {
                        backgroundColor: palette.warning.light,
                        color: palette.warning.dark,
                        '& .MuiChip-icon, & .MuiChip-deleteIcon': {
                            color: palette.warning.dark,
                            ':hover': {color: palette.warning.main}
                        }
                    }
                },
                {
                    props: {variant: 'green'},
                    style: {
                        backgroundColor: palette.success.light,
                        color: palette.success.dark,
                        '& .MuiChip-icon, & .MuiChip-deleteIcon': {
                            color: palette.success.dark,
                            ':hover': {color: palette.success.middle}
                        }
                    }
                },
                {
                    props: {variant: 'blue'},
                    style: {
                        backgroundColor: palette.info.light,
                        color: palette.info.dark,
                        '& .MuiChip-icon, & .MuiChip-deleteIcon': {
                            color: palette.info.dark,
                            ':hover': {color: palette.info.middle}
                        }
                    }
                },
                {
                    props: {variant: 'violet'},
                    style: {
                        backgroundColor: palette.common.pink,
                        color: palette.common.violet,
                        '& .MuiChip-icon, & .MuiChip-deleteIcon': {
                            color: palette.common.violet,
                            ':hover': {color: palette.error.dark}
                        },
                    }
                }
            ]
        },
        MuiCheckbox: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    width: '16px',
                    height: '16px',
                },
            },
            variants: [
                {
                    props: {variant: 'CheckCircle'},
                    style: {
                        '&.Mui-disabled': {
                            path: {
                                fill: palette.grey[200],
                            }
                        },
                        path: {
                            fill: palette.grey[500],
                        },
                        '&.Mui-checked:hover': {
                            rect: {
                                stroke: palette.primary.dark,
                                fill: palette.primary.dark,
                            },
                            path: {
                                fill: `${palette.primary.dark} !important`,
                            },
                        },
                        ':hover': {
                            rect: {
                                stroke: palette.primary.dark,
                            },
                            path: {
                                fill: palette.primary.dark,
                            },
                        },
                    }
                },
                {
                    props: {indeterminate: false},
                    style: {
                        '&.Mui-disabled&.Mui-checked': {
                            rect: {
                                fill: palette.grey[100],
                                stroke: palette.grey[200],
                            },
                            path: {
                                fill: palette.grey[200],
                            },
                        },
                        '&.Mui-checked': {
                            rect: {
                                stroke: palette.primary.dark,
                            },
                            path: {
                                fill: palette.primary.dark,
                            },
                        },
                        '&.Mui-checked:hover': {
                            rect: {
                                stroke: palette.primary.dark,
                                fill: palette.primary.dark,
                            },
                            path: {
                                fill: palette.common.white,
                            },
                        },
                        rect: {
                            fill: palette.common.white,
                            stroke: palette.grey[200],
                        },
                        ':hover': {
                            rect: {
                                stroke: palette.primary.dark,
                            },
                        },
                        '&.Mui-disabled': {
                            rect: {
                                fill: palette.grey[100],
                                stroke: palette.grey[200],
                            },
                        },
                        '&.Mui-focused': {
                            rect: {
                                strokeWidth: 4,
                            }
                        }
                    }
                },
                {
                    props: {indeterminate: true},
                    style: {
                        rect: {
                            fill: palette.primary.dark,
                        }
                    }
                },
                {
                    props: {indeterminate: true, disabled: true},
                    style: {
                        rect: {
                            fill: palette.grey[200],
                        }
                    }
                },
            ],
        },
        MuiRadio: {
            defaultProps: {
                icon: <RadioIcon/>,
                checkedIcon: <RadioIcon/>,
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    '& .MuiSvgIcon-root': {
                        circle: {
                            fill: 'white',
                            stroke: palette.grey[200],
                        },
                        ellipse: {
                            fill: palette.grey[100],
                        }
                    },
                    ':hover .MuiSvgIcon-root': {
                        circle: {
                            fill: 'white',
                            stroke: palette.primary.dark,
                        },
                        ellipse: {
                            fill: palette.primary.dark,
                        }
                    },
                    '&.Mui-disabled .MuiSvgIcon-root': {
                        circle: {
                            fill: palette.grey[100],
                            stroke: palette.grey[200],
                        },
                        ellipse: {
                            fill: 'white',
                        }
                    },

                    '&.Mui-checked .MuiSvgIcon-root': {
                        circle: {
                            fill: palette.primary.dark,
                            stroke: palette.primary.dark,
                        },
                        ellipse: {
                            fill: 'white',
                        }
                    },
                    '&.Mui-checked:hover .MuiSvgIcon-root': {
                        circle: {
                            fill: 'white',
                            stroke: palette.info.dark,
                        },
                        ellipse: {
                            fill: palette.primary.dark,
                        }
                    },
                    '&.Mui-disabled.Mui-checked .MuiSvgIcon-root': {
                        circle: {
                            fill: palette.grey[100],
                            stroke: 'none',
                        },
                        ellipse: {
                            fill: 'white',
                        }
                    },
                }
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: palette.info.dark,
                    textDecorationColor: palette.info.dark,
                },
            }
        },
        MuiPickersCalendarHeader: {
            styleOverrides: {
                root: {
                    margin: 0,
                    padding: 0,
                    height: 0,
                    width: 0,
                    minHeight: 0,
                    minWidth: 0,
                },
            }
        },
        MuiYearCalendar: {
            styleOverrides: {
                root: {
                    padding: 0,
                    width: '100%',
                    display: 'grid',
                    gap: '16px',
                    gridTemplateColumns: 'repeat(4, 48px)',
                    height: '200px',
                },
            },
        },
        MuiPickersYear: {
            styleOverrides: {
                root: {
                    width: '48px',
                    height: '32px',
                },
                yearButton: {
                    width: '48px',
                    height: '32px',
                    backgroundColor: palette.grey.background,
                    color: palette.grey[800],
                    borderRadius: '8px',
                    fontSize: 14,
                    ':hover': {
                        color: palette.primary.dark,
                        backgroundColor: palette.grey.background,
                    },
                    '&.Mui-selected, &.Mui-selected:focus': {
                        color: palette.common.white,
                        backgroundColor: palette.primary.dark,
                        ':hover': {
                            color: palette.primary.dark,
                            backgroundColor: palette.common.white,
                            boxShadow: palette.shadow.small,
                        }
                    }
                },
            }
        },
        MuiMonthCalendar: {
            styleOverrides: {
                root: {
                    padding: 0,
                    width: '100%',
                    display: 'grid',
                    justifyContent: 'center',
                    gap: '16px',
                    gridTemplateColumns: 'repeat(3, 72px)',
                },
            }
        },
        MuiPickersMonth: {
            styleOverrides: {
                root: {
                    width: '72px',
                },
                monthButton: {
                    margin: 0,
                    width: '72px',
                    height: '32px',
                    fontSize: 14,
                    backgroundColor: palette.grey.background,
                    color: palette.grey[800],
                    borderRadius: '8px',
                    ':hover': {
                        color: palette.primary.dark,
                        backgroundColor: palette.grey.background,
                    },
                    '&.Mui-selected, &.Mui-selected:focus': {
                        color: palette.common.white,
                        backgroundColor: palette.primary.dark,
                        ':hover': {
                            color: palette.primary.dark,
                            backgroundColor: palette.common.white,
                            boxShadow: palette.shadow.small,
                        }
                    }
                }
            }
        },
        MuiDayCalendar: {
            styleOverrides: {
                header: {
                    gap: 8,
                    display: 'flex',
                },
                weekContainer: {
                    width: '100%',
                    margin: 0,
                    marginBottom: '8px',
                    padding: 0,
                },
                weekDayLabel: {
                    color: palette.grey[800],
                    fontWeight: 500,
                    lineHeight: '16px',
                    width: 32,
                    height: 16,
                    margin: 0,
                    marginBottom: '8px',
                    padding: 0,
                },
            }
        },
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    width: '32px',
                    height: '32px',
                    color: palette.grey[900],
                    borderRadius: '8px',
                    margin: 0,
                    '&:focus': {
                        backgroundColor: 'transparent',
                    },
                    '&:hover': {
                        color: palette.primary.dark,
                        backgroundColor: palette.grey.background,
                    },
                    '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
                        color: 'white',
                        backgroundColor: palette.primary.dark
                    },
                }
            }
        },
        MuiPickersLayout: {
            styleOverrides: {
                root: {
                    rowGap: '16px',
                    width: '100%',
                    height: 'max-content',
                },
            }
        },
        MuiDateCalendar: {
            styleOverrides: {
                root: {
                    height: 256,
                    width: '100%',
                },
            },
        },
        MuiPickersPopper: {
            styleOverrides: {
                root: {
                    '& .MuiPaper-root': {
                        width: 336,
                        padding: '24px 24px 16px 24px',
                    }
                }
            }
        },
        MuiDatePicker: {
            defaultProps: {
                'data-cy': 'date-picker',
                showDaysOutsideCurrentMonth: true,
                minDate: dayjs('2000'),
                maxDate: dayjs('2050'),
                dayOfWeekFormatter: day => {
                    // I don't know why
                    // the function DateAdapter.getWeekday isn't calling
                    // since @mui/x-date-pickers 6.17.0
                    // return day.substring(0, 3);
                    const weekDays = {
                        'Mo': 'Mon',
                        'Tu': 'Tue',
                        'We': 'Wed',
                        'Th': 'Thu',
                        'Fr': 'Fri',
                        'Sa': 'Sat',
                        'Su': 'Sun',
                    }
                    return weekDays[day]
                },
                slots: {
                    openPickerIcon: props => <CalendarIcon pointerEvents="none" {...props}/>,
                    switchViewButton: () => <></>,
                    switchViewIcon: () => <></>,
                    nextIconButton: () => <></>,
                    previousIconButton: () => <></>,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 12,
                    padding: '12px 8px',
                    boxShadow: palette.shadow.small,
                }
            },
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    display: 'grid',
                    alignItems: 'center',
                    justifyItems: 'center',
                }
            }
        },
        MuiStepConnector: {
            styleOverrides: {
                vertical: {
                    height: '24px',
                    marginLeft: '24px',
                },
                line: {
                    borderColor: '#D9D9D9',
                    borderWidth: '4px',
                }
            },
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
                placement: 'top',
            },
            styleOverrides: {
                arrow: {
                    color: palette.primary.dark,
                },
                tooltip: {
                    color: 'white',
                    background: palette.primary.dark,
                    borderRadius: '8px',
                    padding: '7px 8px',
                    fontSize: '14px',
                    lineHeight: '20px',
                    letterSpacing: '0.035px',
                },
                popper: {
                    '&[data-popper-placement*="top-start"] .MuiTooltip-arrow': {
                        transform: 'translateX(19px) !important',
                    },
                    '&[data-popper-placement*="top-end"] .MuiTooltip-arrow': {
                        transform: 'translateX(8px) !important',
                    },
                    '&[data-popper-placement*="bottom-start"] .MuiTooltip-arrow': {
                        transform: 'translateX(19px) !important',
                    },
                    '&[data-popper-placement*="bottom-end"] .MuiTooltip-arrow': {
                        transform: 'translateX(8px) !important',
                    },
                }
            },
        }
    },
});


export default theme;