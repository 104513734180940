import {
    Box,
    Button,
    Typography,
    IconButton,
    FormHelperText
} from '@mui/material';
import React, { useState} from 'react';
import ModalComponent from 'shared/ui/ModalComponent';
import CloseIcon from "../../../../shared/assets/icons/Close";
import TextField from "@mui/material/TextField";

export function EditLocationGroupName({editGroupState, onChange}) {

    const [editLocationGroup, setEditLocationGroup] = useState({...editGroupState});

    const saveGroupName = () => {
        onChange(editLocationGroup);
    };

    return (
            <ModalComponent visible={true}>
                <Box sx={{ width: '472px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '24px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch' }}>
                        <h3>Edit location group</h3>
                        <IconButton variant={'outlined'} size={"small"} sx={{height:"32px", padding:"8px"}} onClick={() => onChange(false)}>
                            <CloseIcon sx={{width: '16px', height: '16px'}}/>
                        </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '4px', alignSelf: 'stretch', flexDirection:"column" }}>
                        <FormHelperText sx={{ fontSize: '14px', fontWeight:"400", margin:"0" }}>Location group name</FormHelperText>
                        <TextField sx={{ gap: '8px', width:"100%" }} type='text'
                               placeholder='Location group name'
                               value={editLocationGroup.new}
                               onChange={(e) => setEditLocationGroup({original: editLocationGroup.original, new: e.target.value})}>
                        </TextField>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '12px', alignSelf: 'stretch', height:"40px", paddingTop:"12px", borderTop:"1px solid #F0F2F5" }}>
                        <Button
                            className='spacer regular'
                            onClick={saveGroupName}
                        >
                            Save
                        </Button>
                        <Button
                            className='spacer regular'
                            variant={'outlined'}
                            color={'secondary'}
                            onClick={() => {
                                onChange(false)
                            }}
                        >
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </ModalComponent>
    )

}
