import {Box, IconButton, Menu, Typography, MenuItem, Chip} from '@mui/material';
import {Spacer} from "shared/ui/Spacer";
import MoreIcon from "shared/assets/icons/More";
import React, {useState} from 'react';
import theme from "app/Theme";
import {CheckBox} from "shared/ui/CheckBox";
import AgreeModal from "shared/ui/AgreeModal";
import TrashIcon from "../../../../shared/assets/icons/Trash";
import PenIcon from "../../../../shared/assets/icons/Pen";
import CopyIcon from "../../../../shared/assets/icons/Copy";
import BellIcon from "../../../../shared/assets/icons/Bell";
import 'pages/settings/ui/weatherDefinitions/WeatherDefinitionSetting.css';

export default function WeatherDefinitionLine({definition, onChange, selected}) {

    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteDefinition, setDeleteDefinition] = useState(false);


    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (action) => (event) => {
        event.stopPropagation();
        if (action === "delete") {
            setDeleteDefinition(true);
        } else if (action === "select") {
            if (action) {
                onChange(definition, action, !selected);
            }
        } else if (action === "add_alert") {
            if (action) {
                setAnchorEl(null);
                onChange(definition.id, "edit_configuration", null);
            }
        } else {
            setAnchorEl(null);
            if (action) onChange(definition, action)
        }
    };

    const handleDeleteWeatherDefinition = (agreed) => {
        setAnchorEl(null);
        if (agreed) {
            onChange(definition, 'delete');
        }
        setDeleteDefinition(false);
    }

    return (
        <>
            <Box className={'weatherDefinitionLine'} onClick={handleClose('open')}>
                {<CheckBox
                    checked={selected || false}
                    onClick={handleClose('select')}
                />}
                <Typography>{definition.name}</Typography>
                <Spacer/>
                <Chip
                    label={definition.severity}
                    variant={theme.palette.weather.variant[definition.severity]}
                    size={'small'}
                />
                <Box sx={{display: 'flex'}}>
                    <IconButton id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                variant={'outlined'}
                                size={'tiny'}
                    >
                        <MoreIcon size={'small'}/>
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose('')}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation()
                        }}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem
                            onClick={handleClose('add_alert')}
                            key="add"
                        >
                            <BellIcon size={'small'}/>
                            Add alert
                        </MenuItem>
                        {definition.rules &&
                            <MenuItem
                                onClick={handleClose('duplicate')}
                                key="duplicate"
                            >
                                <CopyIcon size={'small'}/>
                                Duplicate
                            </MenuItem>
                        }
                        {definition.user && definition.rules &&
                            <MenuItem
                                onClick={handleClose('edit')}
                                key="edit"
                            >
                                <PenIcon size={'small'}/>
                                Edit
                            </MenuItem>
                        }
                        {definition.user && definition.rules &&
                            <MenuItem
                                color={'error'}
                                onClick={handleClose('delete')}
                                key="delete"
                            >
                                <TrashIcon size={'small'}/>
                                Delete
                            </MenuItem>
                        }
                    </Menu>
                </Box>
            </Box>
            {deleteDefinition &&
                <AgreeModal
                    data={{
                        message: <>Are you sure you want delete <span style={{
                            fontWeight: "500",
                            color: "var(--palette-grey-900)"
                        }}>{definition.name}</span> weather defintion and <span style={{
                            fontWeight: "500",
                            color: "var(--palette-grey-900)"
                        }}>all alert configurations</span> linked to this definition?</>,
                        title: "Delete weather definition",
                        agreeMsg: "Delete",
                        mode: "deleting",
                        agreeFunc: handleDeleteWeatherDefinition
                    }}
                />
            }
        </>
    )
}
