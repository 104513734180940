import React, { useRef, useState, useEffect } from 'react';
import 'pages/map/ui/timeline/timeline.css';
import icons from 'pages/map/model/svgcollector';

const dayjs = require('dayjs');
const formatTime = 'ddd MMM D, YYYY h:mm A';

/*
        isLoading - is wms loading
        startDate (Date in MS)
        endDate (Date in MS)
        onChangeTime - function for update timelim
    */
export function TimeLineMap({isPlaying, isLoading, startDate, endDate, onChangeTime, onPlayBtn, onChangePlaying, selectedTime }) {

    let speedValues = [1200, 800, 400, 200];
    const animationIndex = useRef();
    const stepAnimation = useRef(1);

    const [currentSpeed, setCurrentSpeed] = useState(1);
    const [currentValueTime, setCurrentValueTime] = useState(50);

    useEffect(() => {
        if (isPlaying && !isLoading) {
            onChangeTimeLineValue(currentValueTime);
            animationIndex.current = window.setTimeout(_animateStep, (currentValueTime === 99 ? 2000 : speedValues[currentSpeed]));
        } else {
            clearTimeout(animationIndex.current);
        }
    },[isPlaying, isLoading]);

    useEffect(()=>{
        const currentPosition = (selectedTime-startDate)*100/(endDate-startDate);
        onTimeLineClick();
        setCurrentValueTime(currentPosition);
        onChangeTimeLineValue(currentPosition);
    },[startDate, endDate])

    useEffect(()=>{
        if (isPlaying) {
            onChangeTimeLineValue(currentValueTime);
            animationIndex.current = window.setTimeout(_animateStep, (currentValueTime === 99 ? 2000 : speedValues[currentSpeed]));
        }
    }, [currentValueTime]);


    function changeSpeed(step) {
        let newValue = currentSpeed + step;
        if (newValue < 0) return;
        if (newValue === speedValues.length) return;
        setCurrentSpeed(newValue);
    }

    function handleSwitch() {
        onPlayBtn(isPlaying);
        onChangePlaying(!isPlaying);
    }

    function _onChangeTimeLineValyByVal(event) {
        let val = event.target.value;
        let currentPosition = parseInt(val);
        if (isNaN(currentPosition)) {
            return;
        }
        if (currentPosition > 99) {
            currentPosition -= 100;
        }
        if (currentPosition < 0) {
            currentPosition += 100;
        }
        onTimeLineClick();
        setCurrentValueTime(currentPosition);
        onChangeTimeLineValue(currentPosition);
    }

    function _animateStep() {
        let newVal = currentValueTime;
        if (currentValueTime === 99) {
            newVal = 0;
        } else {
            newVal += stepAnimation.current;
            newVal = Math.min(99, newVal);
        }
        setCurrentValueTime(newVal);
    }

    function handleNowBtnClick() {
        onChangePlaying(false);

        let msNow = new Date().getTime();
        let n = (msNow-startDate)*100/(endDate-startDate);
        n = Math.round(n);
        
        if(n<0){n=0}
        if(n>100){n=100}
        
        setCurrentValueTime(n);
        onChangeTimeLineValue(n);      
		clearTimeout(animationIndex.current);
	}

    function onTimeLineClick() {
        onChangePlaying(false);
        clearTimeout(animationIndex.current);
    }

    function onChangeTimeLineValue(val) {
        if (isNaN(val) || !endDate || !startDate) return;
        const timeInMs = startDate + (val / 100) * (endDate - startDate);
        setCurrentValueTime(val);
        onChangeTime(timeInMs);
    }

    function _makeFillStyleBackGround(percent) {
        const timelineColor = "rgba(255, 255, 255, 0)";
        return 'linear-gradient(to right,' + timelineColor + (percent - 0.3) + '%, var(--palette-primary-dark) ' + (percent - 1) + '% ' + (percent + 1) + '%,' + timelineColor + (percent + 0.3) +'% 100%)';
    }

    function _makeFillFutureOrPast() {
        const currentTime = dayjs();
        const percentage = ((currentTime - startDate) / (endDate - startDate)) * 100;
        return 'linear-gradient(to right, rgba(2, 28, 55, 0.055)'  + percentage + '%, rgba(255, 255, 255, 0) ' + percentage + '%)';
    }

    function getDateValue(){
        const timeInMs = startDate + (currentValueTime / 100) * (endDate - startDate);
        return dayjs(timeInMs).format(formatTime)
    }

    return (
        <div className={'timeline' + (isPlaying ? ' timelineplaying' : '')} style={{position:"static", display: "flex", gap:"8px", padding: "0 8px"}}>
            <div className="timelinespeed">
                <div className={currentSpeed >= speedValues.length - 1 ? "disabled" : ""}
                    onClick={() => changeSpeed(1)} aria-label="Increase Speed" tabIndex="0" role="button" title="Increase Speed">+</div>
                <div className={currentSpeed <= 0 ? "disabled" : ""}
                    onClick={() => changeSpeed(-1)} aria-label="Decrease Speed" tabIndex="0" role="button" title="Decrease Speed">-</div>
            </div>
            <div className="timelineplaybtn"
                onClick={handleSwitch} dangerouslySetInnerHTML={{ __html: icons[isPlaying ? 'pause' : 'play'] }}
                aria-label={isPlaying ? "Pause" : "Play"} role="button" tabIndex="0"></div>
            <div className="timelinewrapperline">
                <input type="range" min="0" max="99"
                    style={{ background: _makeFillStyleBackGround(currentValueTime) }}
                    className="timelinecursor"
                    onMouseDown={onTimeLineClick}
                    onChange={_onChangeTimeLineValyByVal}
                    value={currentValueTime}
                    aria-label="Time Slider" role="slider" tabIndex="0" />
                <div className="futureorpast" style={{background: _makeFillFutureOrPast()}}/>
                <div className="timelinetime">{ isLoading ? 'Loading...' : getDateValue() }</div>
            </div>
            <div className="timelineplaybtn" onClick={handleNowBtnClick} aria-label="Current Time" tabIndex="0" role="button" title="Current Time">NOW</div>
        </div>
    );
}

