import MoreIcon from "shared/assets/icons/More";
import React from 'react';
import theme from "app/Theme";
import {CheckBox} from "shared/ui/CheckBox";
import {Box, IconButton, Menu, MenuItem, Typography, Chip, Popover} from "@mui/material";
import MapPinIcon from "../../../../shared/assets/icons/MapPin";
import TrashIcon from "../../../../shared/assets/icons/Trash";
import CopyIcon from "../../../../shared/assets/icons/Copy";
import PenIcon from "../../../../shared/assets/icons/Pen";
import './AlertsSettings.css';
import MapMarkerIcon from "../../../../shared/assets/icons/MapMarker";
import PlusSquareIcon from "../../../../shared/assets/icons/PlusSquare";
import MinusSquareIcon from "../../../../shared/assets/icons/MinusSquare";
import CircleIcon from "../../../../shared/assets/icons/Circle";
import PausedChip from "../../../../widgets/alertConfig/ui/PausedChip";

const AlertSettingsLine = ({alert, onChange, selected, isEnable, isNewAlert= false}) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElLocs, setAnchorElLocs] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const openLocations = (event) => {
        event.stopPropagation();
        setAnchorElLocs(event.currentTarget);
    };
    const handleClose = (action) => (event) => {
        event.stopPropagation();
        setAnchorEl(null);
        setAnchorElLocs(null);
        if (action) onChange(action, alert)
    };

    return (
        <Box data-cy={'alert-configuration'} className={'alertsSettingsLine'} onClick={handleClose('open')} style={{backgroundColor : isNewAlert? "var(--palette-info-light)" : "none"}}>
            <CheckBox
                checked={selected || false}
                onClick={(event) => {
                    event.stopPropagation();
                    onChange('select', alert, !selected);
                }}
            />
            {isNewAlert && <CircleIcon size={"tiny"} style={{color: `var(--palette-primary-dark)`}}/>}
            <Box style={{display:"flex", alignItems:"center", gap:"16px"}}>
                <Typography style={{fontSize: "16px", fontWeight: "500"}}>
                    {alert.type}
                </Typography>
                <PausedChip isEnable={isEnable}/>
            </Box>
            <Box sx={{
                marginLeft: 'auto',
                lineHeight: '16px',
                display: "flex",
                gap: "8px",
                height: "32px",
                alignItems: "center"
            }}>
                <Chip
                    icon={<MapMarkerIcon size={'small'}/>}
                    label={alert.locations[0].label}
                    size={'medium'}
                />
                {alert.locations.length > 1 &&
                    <>
                        <span className="paragraph" style={{
                            display: "flex", width: "32px", height: "32px",
                            border: "1px solid var(--palette-grey-100)", borderRadius: "8px",
                            alignItems: "center", justifyContent: "center", cursor: "pointer",
                            fontSize: "14px", fontWeight: "500", color: "var(--palette-primary-dark)"
                        }}
                              onClick={openLocations}>
                            +{alert.locations.length - 1}
                        </span>
                        <Popover
                            open={!!anchorElLocs}
                            anchorEl={anchorElLocs}
                            onClick={(e) => {
                                e.stopPropagation();
                                setAnchorElLocs(null)
                            }}
                            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                            transformOrigin={{vertical: 'top', horizontal: 'right'}}
                        >
                            <Box sx={{maxWidth: "300px", display:"flex", flexWrap:"wrap", gap:"4px"}}>
                                {alert.locations.map(loc => (
                                    <Chip
                                        icon={<MapPinIcon size={'small'}/>}
                                        label={loc.label}
                                        size={'small'}
                                        key={loc.id}
                                    />
                                ))}
                            </Box>
                        </Popover>
                    </>
                }
                <Chip
                    label={alert.severity}
                    variant={theme.palette.weather.variant[alert.severity]}
                    size={'medium'}
                />
            </Box>


            <div>
                <IconButton
                    data-cy={'more-button'}
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    variant={'outlined'}
                    style={{width: "32px", height: "32px"}}
                >
                    <MoreIcon size={'small'}/>
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClick={(e) => e.stopPropagation()}
                    onClose={handleClose('')}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem
                        onClick={handleClose('duplicate')}
                        key="duplicate"
                    >
                        <CopyIcon size={'small'}/>
                        Duplicate
                    </MenuItem>
                    <MenuItem
                        onClick={handleClose('edit')}
                        key="edit"
                    >
                        <PenIcon size={'small'}/>
                        Edit
                    </MenuItem>
                    {isEnable &&
                        <MenuItem
                            onClick={handleClose('pause')}
                            key="pause"
                        >
                            <MinusSquareIcon size={'small'}/>
                            Pause
                        </MenuItem>
                    }
                    {!isEnable &&
                        <MenuItem
                            onClick={handleClose('restart')}
                            key="restart"
                        >
                            <PlusSquareIcon size={'small'}/>
                            Restart
                        </MenuItem>
                    }
                    <MenuItem
                        onClick={handleClose('single_delete')}
                        key="single_delete"
                        color={'error'}
                    >
                        <TrashIcon size={'small'}/>
                        Delete
                    </MenuItem>
                </Menu>
            </div>
        </Box>
    );
};

export default AlertSettingsLine;
