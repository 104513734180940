import React, { useState, useEffect } from 'react';
import {Box, FormControlLabel, FormGroup, IconButton, Typography, MenuItem, Chip, Button} from '@mui/material';
import { CheckBox } from "shared/ui/CheckBox";
import PenIcon from "shared/assets/icons/Pen";
import ClockIcon from "shared/assets/icons/Clock";
import CustomMessagePopup, {tokens} from 'features/notification/CustomMessagePopup';
import { NumberInput } from 'shared/ui/NumberInput';
import Select from '@mui/material/Select';


function NotificationCheckboxTimeWithMessage({ checked, onNotificationDataChange, message='', customTimeSettings, onChange, isDefaultMessage, onSaveAsDefault }) {
    const [showMessagePopup, setShowMessagePopup] = useState(false);
    
    const [customTimeDuration, setCustomTimeDuration] = useState('');
    const [customTimePeriod, setCustomTimePeriod] = useState('');
    const [customTimeHour, setCustomTimeHour] = useState('');

    const [content, setContent] = useState([]);

    const handleSaveAsDefault = () => {
        if(onSaveAsDefault) {
            onSaveAsDefault('custom_time', message);
        }
    };

    useEffect(() => {
        const words = message.split(" ");
        const newContent = words.reduce((acc, word, index) => {
            word = word.trim()
            const isToken = word.startsWith('{{') && word.endsWith('}}');
            const tokenId = isToken ? word.replaceAll(/[{}]/g, '') : null;
            const token = isToken ? tokens.find(t => t.id === tokenId) : null;
    
            if (token) {
                acc.push(
                    <Chip
                        key={`${tokenId}-${index}`}
                        style={{margin: '1px 3px', height: '25px'}}
                        contentEditable={false}
                        label={token.displayName}
                    />
                );
            } else if (isToken && !token) {
                // If this is token format, but token not found add as word
                acc.push(word);
            } else {
                // plain text
                acc.push(word);
            }
    
            // Add space after word except for the last
            if (index < words.length - 1) {
                acc.push(' ');
            }
    
            return acc;
        }, []);
    
        setContent(newContent);
    }, [message, tokens]);
    

    useEffect(() => {
        if (customTimeSettings) {
            setCustomTimeDuration(customTimeSettings.customTimeDuration );
            setCustomTimePeriod(customTimeSettings.customTimePeriod );
            setCustomTimeHour(customTimeSettings.customTimeHour );
        }
    }, [customTimeSettings]);    

    const handleDurationChange = (newValue) => {
        setCustomTimeDuration(newValue);
        onNotificationDataChange('custom_duration', newValue);
    };

    const handlePeriodChange = (e) => {
        const newValue = e.target.value;
        setCustomTimePeriod(newValue);
        onNotificationDataChange('custom_period', newValue);
    };

    const handleHourChange = (e) => {
        const newValue = e.target.value;
        setCustomTimeHour(newValue);
        onNotificationDataChange('custom_hour', newValue);
    };

    const handleChange = (e) => {
        onNotificationDataChange('custom_time', e.target.checked);
    };
    
    // const handleSave = (newMessage) => {
    //     onNotificationDataChange('custom_time', newMessage);
    // };


    return (
        <>
            <FormGroup style={{gap: '8px'}}>
                <FormControlLabel
                    control={<CheckBox checked={checked} onChange={handleChange} />}
                    label="Custom time"
                />
                {checked && (
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', gap: '8px', paddingLeft: '32px'}}>
                        <Box style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'row', alignSelf: 'stretch'}} >
                            <Typography variant="body2">{content}</Typography>
                            <Box style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                gap: '8px',
                                flex: '1 0 0'
                            }}>
                                <IconButton
                                    variant={'outlined'}
                                    size={'tiny'}
                                    onClick={() => setShowMessagePopup(true)}
                                >
                                    <PenIcon size={'small'}/>
                                </IconButton>
                                {isDefaultMessage ? (
                                <Typography style={{color: 'var(--palette-grey-500)'}}>
                                    Default message
                                </Typography>
                                ) : (
                                <Button variant={'outlined'} color={'primary'} style={{height: '32px', whiteSpace: 'nowrap'}} onClick={handleSaveAsDefault}>
                                    Save as default
                                </Button>
                                )}                     
                            </Box>
                        </Box>
                        <FormGroup style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            alignSelf: 'stretch',
                            gap: '8px',
                            padding: '4px 0px 0px'
                        }}>
                            <NumberInput value={customTimeDuration} onChange={handleDurationChange}/>
                            <Select value={customTimePeriod} style={{width:"108px"}} onChange={handlePeriodChange} placeholder='period'>
                                <MenuItem value={'d'}>days</MenuItem>
                                <MenuItem value={'h'}>hours</MenuItem>
                                <MenuItem value={'m'}>minutes</MenuItem>
                            </Select>
                            <Typography>
                                before start of weather event
                            </Typography>
                        </FormGroup>
                    </Box>
                )}
            </FormGroup>
            <CustomMessagePopup 
                visible={showMessagePopup} 
                onCloseModal={() => setShowMessagePopup(false)} 
                // onSaveMessage={handleSave} 
                messageText={message}
                tokens={tokens}
                onChange={onChange}
            />
        </>
    );
}

export default NotificationCheckboxTimeWithMessage;
