import React, { useState } from 'react';
import {Box, IconButton, ClickAwayListener, MenuItem} from '@mui/material'
import moreIcon from '../../shared/assets/weatherIcons/morehoriz.svg';
import DownloadIcon from "../../shared/assets/icons/Download";
import DesktopLoadIcon from "../../shared/assets/icons/DesktopLoad";
import ArrowRightSmallIcon from "shared/assets/icons/ArrowRightSmall";
import DragVerticalIcon from "../../shared/assets/icons/DragVertical";
import TrashIcon from "../../shared/assets/icons/Trash";
import PenIcon from "../../shared/assets/icons/Pen";
import theme from "../../app/Theme";

function TimeLineElipsis({chartRef, timelinePos, period, selectedConfigs, locations, selectedLocation, setUpdated, views = [], onViewAction}) {

    const [showMenu, setShowMenu] = useState(false);
    const [showLoadMenu, setShowLoadMenu] = useState(false);

    const loadMenuItems = views || [];
    const loadMenuItemsObj = {};
    for (let idx in views) {
        loadMenuItemsObj[views[idx].id] = views[idx];
    }

    const hideMenu = () => {
        setShowMenu(false);
        setShowLoadMenu(false);
    }

    const onClickLoadView = (e) => {
        hideMenu();
        const id = e.target.parentElement.dataset.id;
        const view = loadMenuItemsObj[id];
        const newTimelinePos = view["includes"].position? view.data.position : timelinePos.current;
        const newPeriod = view["includes"].scale? view.data.scale : period;
        const newSelectedLocation = view["includes"].location? locations[view.data.location] : selectedLocation;
        let newSelectedConfigs = selectedConfigs;
        const configs = selectedConfigs.configs;
        if (view["includes"].configs && configs) {
            for (let key in configs) configs[key].selected = false;
            view.data.configs.forEach(key => configs[key].selected = true);
            newSelectedConfigs = {configs: configs}
        }
        setUpdated(newPeriod, newTimelinePos, newSelectedConfigs, newSelectedLocation);
    }

    const onClickDownloadCsv = () => {
        chartRef.current.downloadCSV();
        hideMenu();
    }

    const onClickEditView = (e) => {
        e.stopPropagation();
        onViewAction('edit_view', e.currentTarget.parentElement.dataset.id);
    }

    const onClickDeleteView = (e) => {
        e.stopPropagation();
        onViewAction('delete_view', e.currentTarget.parentElement.dataset.id);
    }


    return (
        <Box sx={{position: 'relative', fontSize: '14px', lineHeight: '32px', color: '#171e27'}}>
                <IconButton className={'timeline-shadow'} sx={{padding: '0', width: '40px', height: '40px', margin: 'auto 0px'}}
                    onClick={() => {setShowMenu(!showMenu)}}>
                    <img src={moreIcon} style={{width: '20px'}} alt={"moreIcon"}/>
                </IconButton>
                { showMenu &&
                <ClickAwayListener onClickAway={() => {setShowMenu(false); setShowLoadMenu(false);}}>
                    <Box className='timeline-shadow' id='timeline-elipsis-menu'
                         sx={{position: 'absolute', top: 45, left: 0, width: '200px', zIndex: 10,
                              margin: '0', backgroundColor: '#ffffff',
                              padding: '10px 15px', cursor: 'default',
                              display: "flex", flexDirection: "column", gap: "4px"
                         }}>
                        <MenuItem onClick={onClickDownloadCsv}>
                            <DownloadIcon size={'small'}/>
                            Download CSV
                        </MenuItem>
                        <MenuItem onClick={() => {setShowLoadMenu(!showLoadMenu)}}>
                            <DesktopLoadIcon size={'small'}/>
                            <Box className={'spacer'}>
                                Load view
                            </Box>
                            <ArrowRightSmallIcon size={'small'}/>
                        </MenuItem>
                        { showLoadMenu &&
                        <Box className='timeline-shadow' id='timeline-load-view-menu' sx={{position: 'absolute', left: 205, top: 32, minWidth: '220px',
                              margin: '0', backgroundColor: '#ffffff', cursor: 'default',
                              padding: '10px 15px', display: "flex", flexDirection: "column", gap: "4px"
                        }}>
                            {views.length ? (loadMenuItems.map(item => (
                                <MenuItem key={item.id} data-id={item.id} sx={{display: 'flex', justifyContent: 'start', flexDirection: 'row', alignItems: 'center'}}
                                    onMouseEnter={(e) => {e.currentTarget.children[2].style.opacity='1'}}
                                    onMouseLeave={(e) => {e.currentTarget.children[2].style.opacity='0'}}
                                    onClick={onClickLoadView}>
                                    <DragVerticalIcon size={'small'}/>
                                    <Box className={'spacer'}>
                                        {item.title}
                                    </Box>
                                    <Box sx={{opacity: '0'}} data-id={item.id}>
                                        <IconButton size={'tiny'} onClick={onClickEditView}>
                                            <PenIcon size={'small'}/>
                                        </IconButton>
                                        <IconButton size={'tiny'} onClick={onClickDeleteView}>
                                            <TrashIcon size={'small'} sx={{color: theme.palette.error.main}}/>
                                        </IconButton>
                                    </Box>
                                </MenuItem>
                            ))) : "No views have been saved yet"}
                        </Box>
                        }
                    </Box>
                </ClickAwayListener>
                }
        </Box>
    );
}

export default TimeLineElipsis;
