import ListIcon from "shared/assets/icons/List";
import MenuIcon from "shared/assets/icons/Menu";
import PlusSquareIcon from "shared/assets/icons/PlusSquare";
import ArrowDownSmallIcon from "shared/assets/icons/ArrowDownSmall";
import { styled } from '@mui/material/styles';
import ArrowUpSmallIcon from "shared/assets/icons/ArrowUpSmall";
import {isEmpty} from "shared/libs/Utils";
import React, {useState, useEffect} from 'react';
import {
    Box,
    IconButton,
    ToggleButton,
    ToggleButtonGroup,
    Popover,
    Backdrop,
    Typography
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import MapPinIcon from '../../shared/assets/icons/MapPin';
import theme from '../../app/Theme';
import dayjs from "dayjs";
import EmptyStateTimeline from "../../shared/ui/emptyStates/EmptyStateTimeline";
import {Spacer} from "../../shared/ui/Spacer";
import {SearchInput} from "../../shared/ui/SearchInput";
import ArrowRightSmallIcon from "../../shared/assets/icons/ArrowRightSmall";
const Color = {
    critical: 'var(--color-support-vivid-red, #E64610)',
    severe: 'var(--color-coding-warning-default, #FA963A)',
    moderate: 'var(--color-primary-light, #50B2DF)',
};
export function TimeLineSidebar({
                                 minDate,
                                 maxDate,
                                 groupList,
                                 selectedLocation,
                                 setSelectedLocation,
                                 severityFilter,
                                 setSeverityFilter,
                                 addLocations,
                                 timelineRange,
                                 chartRef,
                                 timezone,
                             }) {
    const menuStateRef = React.useRef({groups: {}});
    const [menuUpdate, setMenuUpdate] = React.useState(false);
    const [searchFilter, setSearchFilter] = useState('');
    const [severityCount, setSeverityCount] = useState({
        Critical: 0,
        Severe: 0,
        Moderate: 0,
    });

    const [visibleAlerts, setVisibleAlerts] = useState(new Set());

    const chart = chartRef.current;
    const startDate = timelineRange.startDate;
    const endDate = timelineRange.endDate;

    const formattedStartDate = dayjs(startDate).tz(timezone).format("MMM D 'YY");
    const formattedEndDate = dayjs(endDate).tz(timezone).format("MMM D 'YY");


    useEffect(() => {
        const visAlerts = new Set();
        const series = chart?.series;
        if (series) {
            series[0].data.forEach(e => {
                if ((startDate <= e.end_date && endDate >= e.start_date) ||
                    (startDate >= e.start_date && startDate <= dayjs() && e.options.triggered)) {
                    visAlerts.add(e);
                } else {
                    visAlerts.delete(e);
                }
            });
            setVisibleAlerts(visAlerts);
        }
    }, [timelineRange]);

    useEffect(() => {
        const sev = {...severityCount};
        for (const severity in severityCount) {
            sev[severity] = [...visibleAlerts].filter(e => e.severity === severity).length;
        }
        setSeverityCount(sev);
    }, [visibleAlerts]);


    const handleExpanded = (group) => (event, newExpanded) => {
        menuStateRef.current.groups[group] = newExpanded;
        setMenuUpdate(!menuUpdate);
    };

    const style = {
        color: "var(--color-coding-grayscale-600, #5A6672)",
        fontFamily: "Roboto",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "16px",
        letterSpacing: "0.06px"
    }

    // let maxDateLabel = new Date(maxDate).toLocaleDateString();
    // let minDateLabel = new Date(minDate).toLocaleDateString();

    const toggleSeverityFilter = (event, newVal) => {
        if (!newVal) {
            return
        }
        setSeverityFilter(newVal);
    };

    if (groupList.length && !selectedLocation) {
        setSelectedLocation(groupList[0].locations[0]);
    }

    useEffect(() => {
        if (selectedLocation) {
            groupList.forEach(group => {
                const findIndex = group.locations.findIndex((e) => e.id === selectedLocation.id && e.label === selectedLocation.label)
                if (findIndex >= 0) {
                    menuStateRef.current.groups[group.group] = true;
                }
            });
            setMenuUpdate(!menuUpdate);
        }
    }, [selectedLocation]);

    const filterName = (name) => {
        if (searchFilter === '') return true;
        const normName = name.toLowerCase();
        const normFilter = searchFilter.toLowerCase();
        if (normName.indexOf(normFilter) >= 0) return true;
        return false;
    }

    const filterLocation = (loc) => {
        if (!filterName(loc.label)) return false;
        if (severityFilter !== 'critical') return true;
        if (loc.Critical) return true;
        return false;
    }
    const MuiAccordionSummary = styled((props) => (
        <AccordionSummary
            expandIcon={
                <IconButton
                    size='tiny'
                    variant='outlined'
                >
                    <ArrowRightSmallIcon size={'small'}/>
                </IconButton>}
            {...props}
        />
    ))(({ theme }) => ({
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
        '& .MuiButtonBase-root.MuiAccordionSummary-root': {
            padding: '0 30px',
        }
    }));

    const selectedBox = (loc) =>
        <Box
            key={loc.id}
            onClick={() => {
                setSelectedLocation({id: loc.id, label: loc.label})
            }}
            sx={{
                overflowWrap: 'break-word',
                padding: '8px 8px 8px 12px',
                width: '246px',

                ...(selectedLocation && selectedLocation.id === loc.id && {
                        background: "white",
                        border: '1px solid',
                        borderColor: theme.palette.grey[100],
                        borderRadius: "8px",
                    }
                )
            }}
        >
            <Box key={loc.id} sx={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                gap: '14px',
                alignSelf: 'stretch',
                justifyContent: 'space-between',
                ...(selectedLocation?.id !== loc.id && {
                    borderRight: '1px solid transparent'
                })
            }}>
                {
                    (loc.Critical !== 0) &&
                    <Box sx={{
                        width: '12px',
                        height: '12px',
                        borderRadius: '2px',
                        background: Color.critical,
                    }}></Box>
                }
                <Typography
                    style={(selectedLocation && selectedLocation.id === loc.id) ?
                        {
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "24px",
                            letterSpacing: "0.035px",
                            color: '#09638C',
                        } :
                        {
                            color: theme.palette.grey[900],
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            letterSpacing: "0.21px"
                        }
                }> {loc.label}
                </Typography>
                <Spacer />
                {(loc.type === 'point') &&
                    <MapPinIcon size={'small'} sx={(selectedLocation && selectedLocation.id === loc.id) ?
                        {
                            color: 'var(--color-primary-dark, #09638C)',
                        } :
                        {
                        }
                    }
                    />}
            </Box>
            {selectedLocation && selectedLocation.id === loc.id &&
                <Box sx={{
                    flexDirection: 'row',
                    borderRadius: "8px",
                    background: "var(--color-coding-grayscale-0, #FFF)"
                }}>
                    <label style={{
                        color: "var(--color-coding-grayscale-800, #333C47)",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: '500',
                        letterSpacing: "0.12px",
                        lineHeight: "16px",
                    }}>
                        Alerts for {' '}
                        {
                            formattedStartDate + ' - ' + formattedEndDate
                        }
                    </label>

                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "8px",
                        alignSelf: "stretch"
                    }}>
                        <Box flexDirection={'row'} sx={{
                            width: '100%',
                            alignItems: "center",
                            alignSelf: "stretch",
                            display: "flex",
                            flexGrow: 3,
                            flexDirection: 'row',
                        }}>
                            <Box style={{fontSize: '10px'}} sx={{
                                flexGrow: severityCount.Critical,
                                height: "10px",
                                backgroundColor: Color.critical,
                            }}></Box>
                            <Box style={{fontSize: '10px'}} sx={{
                                flexGrow: severityCount.Severe,
                                height: "10px",
                                backgroundColor: Color.severe,
                            }}></Box>
                            <Box style={{fontSize: '10px'}} sx={{
                                flexGrow: severityCount.Moderate,
                                height: "10px",
                                backgroundColor: Color.moderate,
                            }}></Box>
                        </Box>

                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                            alignSelf: "stretch"
                        }}>
                            <Box sx={{
                                width: '8px',
                                height: '8px',
                                borderRadius: '2px',
                                background: Color.critical,
                            }}></Box>
                            <Typography
                                style={style}>{severityCount.Critical} critical
                            </Typography>
                        </Box>

                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                            alignSelf: "stretch"
                        }}>
                            <Box sx={{
                                width: '8px',
                                height: '8px',
                                borderRadius: '2px',
                                background: Color.severe,
                            }}></Box>
                            <Typography
                                style={style}>{severityCount.Severe} severe
                            </Typography>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "4px",
                            alignSelf: "stretch"
                        }}>
                            <Box sx={{
                                width: '8px',
                                height: '8px',
                                borderRadius: '2px',
                                background: Color.moderate,
                            }}></Box>
                            <Typography
                                style={style}>{severityCount.Moderate} moderate
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            }
        </Box>;

    return (
        <Box className={'column gap0 fullHeight TimeLineSidebar'} sx={{maxWidth: "288px", padding: "24px 0 56px 0"}}>
            <Box className={'fullWidth'} sx={{padding: '0 16px', marginBottom: '24px'}}>
                <SearchInput
                    style={{marginBottom: '10px', width:"100%"}}
                    placeholder={'Search for location'}
                    onChange={({target}) => setSearchFilter(target.value)}
                />
                <ToggleButtonGroup
                    className={'fullWidth'}
                    exclusive
                    value={severityFilter}
                    size={'small'}
                    onChange={toggleSeverityFilter}
                >
                    <ToggleButton value={'all'} variant={'secondary blue'} size={'small'}>
                        All
                    </ToggleButton>
                    <ToggleButton value={'critical'} variant={'secondary blue'} size={'small'}>
                        Critical
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Box className={'fullWidth specific'} sx={{overflow: 'auto', display: 'flex', alignItems: 'center'}}>
                {isEmpty(Object.values(groupList)) ?
                    <Box sx={{width:"100%", height:"100%"}}>
                        <EmptyStateTimeline title={"No locations here yet"} text={"Create locations and alerts to see weather events on the timeline when triggered"}/>
                    </Box> :
                <Box className={'column gap8 fullWidth fullHeight'}>
                    {(searchFilter === '' && severityFilter !== 'critical') &&
                        groupList.map(group => (
                            <Accordion
                                className={'fullWidth'}
                                variant={'dark'}
                                key={group.id}
                                expanded={menuStateRef.current.groups[group.group] === true}
                                onChange={handleExpanded(group.group)}
                            >
                                <MuiAccordionSummary
                                    className={'accordion-summary'}
                                    aria-controls={`locgroup-${group.group}-content`}
                                    id={`locgroup-${group.group}-header`}
                                    variant={'dark'}
                                    sx={{padding: '0 20px'}}
                                >
                                    <Typography style={{
                                        overflowWrap: 'break-word',
                                        transition: "0.2s",
                                        fontWeight: menuStateRef.current.groups[group.group] === true ? "500" : "400",
                                        }}>
                                        {group.group} ({group.locations.length})
                                    </Typography>
                                </MuiAccordionSummary>
                                <AccordionDetails variant={'dark'}>
                                    {group.locations.map(loc => {
                                        return selectedBox(loc);
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }
                    {(searchFilter !== '' || severityFilter === 'critical') &&
                        <Box sx={{paddingLeft: '16px', paddingRight: '16px'}}>
                            {groupList.map(group => (
                                group.locations.map(loc => (filterLocation(loc) && selectedBox(loc)))
                            ))}
                        </Box>
                    }
                </Box>}
            </Box>
        </Box>
    );
}


export function TimeLineMenuMini({groupList, selectedLocation, setSelectedLocation, addLocations, onCollapse}) {

    const [selectArea, setSelectArea] = useState(false);

    let locations = [];
    groupList.forEach((group) => {
        locations = locations.concat(group.locations)
    })
    if (locations.length && !selectedLocation) setSelectedLocation(locations[0]);

    const selectPrev = () => {
        let pos = locations.indexOf(selectedLocation)
        if (!pos) pos = locations.length - 1;
        else pos = pos - 1;
        setSelectedLocation(locations[pos]);
    }
    const selectNext = () => {
        let pos = locations.indexOf(selectedLocation)
        if (pos === locations.length - 1) pos = 0;
        else pos = pos + 1;
        setSelectedLocation(locations[pos]);
    }
    const expandGroup = (group) => {
        setSelectedLocation(group.locations[0]);
        onCollapse(false);
    }


    return (

        <div style={{width: '100%'}}>

            <Box className='fullWidth' sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                borderTop: '1px solid #dbe0ea',
            }}>
                <IconButton sx={{background: '#ffffff', marginTop: '30px', marginBottom: '20px'}}
                            onClick={() => onCollapse(false)}>
                    <ListIcon size={'small'}/>
                </IconButton>
            </Box>

            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: '10px 0',
                borderTop: '1px solid #dbe0ea',
                borderBottom: '1px solid #dbe0ea',
            }}>
                <IconButton sx={{background: '#ffffff'}} onClick={selectPrev}>
                    <ArrowUpSmallIcon size={'small'}/>
                </IconButton>
                <IconButton sx={{background: '#ffffff', marginTop: '10px'}} onClick={selectNext}>
                    <ArrowDownSmallIcon />
                </IconButton>
            </Box>

            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: '10px 0',
            }}>
                <IconButton sx={{background: '#ffffff'}} onClick={({currentTarget}) => setSelectArea(currentTarget)}>
                    <MenuIcon size={'small'}/>
                </IconButton>
            </Box>

            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginTop: '30px',
            }}>
                <IconButton sx={{background: '#ffffff'}} onClick={addLocations}>
                    <PlusSquareIcon/>
                </IconButton>
            </Box>


            {selectArea &&
                <Backdrop open={true} style={{zIndex: 1000, opacity: 0.3}}>
                    <Popover
                        open={true}
                        anchorEl={selectArea}
                        onClose={() => {
                            setSelectArea(false)
                        }}
                        anchorOrigin={{vertical: 'top', horizontal: 'right',}}
                        transformOrigin={{vertical: 'top', horizontal: 'left',}}
                        PaperProps={{sx: {padding: 0, background: '#fff'}}}>
                        <Box style={{background: '#fff'}}>
                            <Box sx={{padding: '15px 15px'}}>
                                <span className={'paragraph medium'}>Select area</span>
                            </Box>
                            <Box sx={{width: '100%', borderTop: '1px solid #dbe0ea', padding: '5px 15px'}}>
                                {groupList.map(group => (
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        padding: '5px 0',
                                        cursor: 'pointer'
                                    }} onClick={() => expandGroup(group)}>
                                        <IconButton
                                            size='tiny'
                                            variant='outlined'
                                        >
                                            <ArrowDownSmallIcon size={'small'}/>
                                        </IconButton>
                                        <span className={'paragraph regular'} style={{
                                            marginLeft: '5px',
                                            marginRight: '20px',
                                            whiteSpace: 'nowrap'
                                        }}>{group.group}</span>
                                        <Box className={'paragraph regular'} sx={{
                                            marginLeft: 'auto',
                                            padding: '0 7px',
                                            borderRadius: '8px',
                                            backgroundColor: '#f0f2f5'
                                        }}>{group.locations.length}</Box>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Popover>
                </Backdrop>
            }
        </div>

    );


}
