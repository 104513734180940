import {IconButton} from "@mui/material";
import React, {useRef, useState} from "react";
import './SwitchModeTool.css'

export default function SwitchModeTool({product}) {
    const [isActive, setIsActive] = useState(false);

    const handleClickOnButton = () => {
        setIsActive(prevState => {
            const newIsActive = !prevState
            product.switchMode(newIsActive)

            return newIsActive
        });
    };

    return (
        <IconButton
            data-cy={'switch-mode-tool'}
            onClick={handleClickOnButton}
            variant={'outlined'}
            size={"small"}
            className={`switch-mode-tool ${isActive ? 'active' : ''}`}
        >

        </IconButton>
    )
}