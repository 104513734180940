import React from 'react';
import theme from "app/Theme";
import {Spacer} from "shared/ui/Spacer";
import {Typography, IconButton, TextField} from '@mui/material';
import 'widgets/contact/Contacts.css';
import CloseIcon from "shared/assets/icons/Close";
import CopyTooltip from 'shared/ui/CopyTooltip';
import {PhoneField} from 'shared/ui/PhoneField/PhoneField';
import {formatPhoneNumber} from "shared/libs/Utils";
import ContactStatus from "../../widgets/contact/ContactStatus";

export default function UserDetails({user, onClose}) {
    return (
        <div className={'column gap16'} style={{width: '520px'}}>
            <div className={'row fullWidth'}>
                <h3 style={{color: theme.palette.grey[900],}}>
                    {user.userName}
                </h3>
                <ContactStatus status={user.status}/>
                <Spacer/>
                <IconButton
                    variant={'outlined'}
                    onClick={onClose}
                >
                    <CloseIcon size={'small'}/>
                </IconButton>
            </div>
            <div className={'row gap16 fullWidth'} style={{alignItems: 'start'}}>
                <div className={'column gap8 fullWidth'} style={{padding: '1px'}}>
                    <Typography variant={'label'} sx={{color: theme.palette.grey[500]}}>
                        Email address
                        <TextField
                            autoComplete={'on'}
                            value={user.email}
                            InputProps={{
                                readOnly: true,
                                endAdornment: <CopyTooltip textToCopy={user.email}/>,
                            }}
                        />
                    </Typography>
                    <Typography variant={'label'} sx={{color: theme.palette.grey[500]}}>
                        Phone number
                        <PhoneField
                            errorBorder={user.phone === ''}
                            value={user.phone}
                            InputProps={{
                                readOnly: true,
                                endAdornment: <CopyTooltip textToCopy={formatPhoneNumber(user.phone)}/>,
                            }}
                        />
                    </Typography>
                </div>
            </div>
        </div>
    )
}