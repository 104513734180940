import {Chip, IconButton, Link, Box} from "@mui/material";
import CloseIcon from "shared/assets/icons/Close";
import LinkSquareIcon from "shared/assets/icons/LinkSquare";
import {Spacer} from 'shared/ui/Spacer';
import dayjs from "dayjs";
import React from "react";
import theme from "app/Theme";
import MapPinIcon from "../../../shared/assets/icons/MapPin";
import 'pages/alerts/ui/AlertsPage.css';
import {ShowReportButton} from "../../../features/alert/ShowReportButton";
import {ForecastMinMax} from './AlertForecastMinMax';


export function AlertPopup({alert, onClose, alertMoreButton}) {

    const forecastMinMax = (condition) => {
        const vals = JSON.parse(condition.value);  // [start val, min, max, units]
        return (
            <>
                <div className={'row'}>
                    <div className={'paragraph'}>
                        {condition.name}
                        {vals[0] && ':'}
                    </div>
                    <Spacer/>
                    <Chip
                        label={vals[0] + (vals[3]?' ' + vals[3]: '')}
                        sx={{color: theme.palette.grey[900]}}
                    />
                </div>
                {vals[1] && ForecastMinMax[condition.name] &&
                            ForecastMinMax[condition.name].indexOf('min') >= 0 &&
                            (ForecastMinMax[condition.name].indexOf('max') < 0 || condition.rule.indexOf(' to ') > 0 || condition.rule.indexOf('<') >= 0) &&
                            <div className={'row'}>
                                <div className={'paragraph'}>
                                    Min value for forecast period:
                                </div>
                                <Spacer/>
                                <Chip
                                    label={vals[1] + (vals[3]?' ' + vals[3]: '')}
                                    sx={{color: theme.palette.grey[900]}}
                                />
                            </div>
                }
                {vals[2] && ForecastMinMax[condition.name] &&
                            ForecastMinMax[condition.name].indexOf('max') >= 0 &&
                            (ForecastMinMax[condition.name].indexOf('min') < 0 || condition.rule.indexOf(' to ') > 0 || condition.rule.indexOf('>') >= 0) &&
                            <div className={'row'}>
                                <div className={'paragraph'}>
                                    Max value for forecast period:
                                </div>
                                <Spacer/>
                                <Chip
                                    label={vals[2] + (vals[3]?' ' + vals[3]: '')}
                                    sx={{color: theme.palette.grey[900]}}
                                />
                            </div>
                }
            </>
        );
    }

    return (
        <div className={'alertPopup'}>
            <div className={'row'}>
                <div className={'subtitle medium'}>{alert.type}</div>
                <div className={'paragraph'}
                     style={{color: theme.palette.notification.status[alert.category]}}>
                    {alert.category}
                </div>
                <Spacer/>
                <IconButton variant={'outlined'} onClick={onClose}>
                    <CloseIcon size={'small'}/>
                </IconButton>
            </div>
            <div className={'row fullWidth'}>
                <span className={'field spacer'}>
                    <div className={'paragraph fieldLabel'}>
                        Start:
                    </div>
                    <div className={'paragraph fieldText'}>
                        {dayjs(alert.start_date).format('MMM D, h:mm A')}
                    </div>
                </span>
                <span className={'field spacer'}>
                    <div className={'paragraph fieldLabel'}>
                        End:
                    </div>
                    <div className={'paragraph fieldText'}>
                        {alert.triggered ? "Ongoing" : dayjs(alert.end_date).format('MMM D, h:mm A')}
                     </div>
                </span>
                {alert.api_alert.report &&
                    <ShowReportButton reportLink={alert.api_alert.report}/>
                }
            </div>
            <Box className={'column gap8 fullWidth weatherDefinitionCard'}>
                {alert.conditions.map(condition => {
                    if (condition.value.indexOf('[')) {
                        const value = (condition.type === 'f')? condition.rule : condition.value;  // wl-528
                        return (
                            <div className={'row'}>
                                <div className={'paragraph'}>
                                    {condition.name}
                                    {value && ':'}
                                </div>
                                <Spacer/>
                                {value &&
                                    <Chip
                                        label={value}
                                        sx={{color: theme.palette.grey[900]}}
                                    />}
                            </div>
                         )
                     }
                     else
                         return forecastMinMax(condition)
                })}
            </Box>
                <div style={{width:"160px", height:"160px", borderRadius:"12px", backgroundColor: theme.palette.common.lightGreen, display:"flex", justifyContent:"center", alignItems:"center", margin: "0 auto"}}>
                    <svg style={{width: '130px', height: '130px', fill: "white"}}>
                        <use width={'100%'} height={'100%'} href={`${alert.icon}#svg2`}/>
                    </svg>
                </div>
            <div className={'row'}>
                <Chip
                    icon={<MapPinIcon/>}
                    label={alert.location}
                />
                <Chip
                    label={alert.severity}
                    variant={theme.palette.weather.variant[alert.severity]}
                />
                <Spacer/>
                {alertMoreButton}
            </div>
        </div>
    )
}