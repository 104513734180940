import {MapLibreLocationStatic} from "features/map/ui/mapLibreLocation/MapLibreLocation";
import React, {useEffect, useState, useRef} from 'react';
import {
    Box,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
    Snackbar,
    Alert, Typography
} from '@mui/material';
import AreaIcon from "shared/assets/icons/Area";
import LineIcon from "shared/assets/icons/Line";
import MapPinIcon from "shared/assets/icons/MapPin";
import {LocationSearch} from 'features/location/locationSearcher/locationSearch';
import requester from 'shared/libs/requester/baron-signature.js';
import 'widgets/location/ui/manageLocationScreen/ManageLocationScreen.css';
import {CheckBox} from "shared/ui/CheckBox";
import {postLocation, putLocation, fetchRealLocations, deleteLocation} from 'pages/alerts/api/FetchAlerts';
import theme from "app/Theme";
import {Spacer} from "shared/ui/Spacer";
import {AddLocation} from "features/location/AddLocation";
import ArrowRightSmallIcon from "shared/assets/icons/ArrowRightSmall";
import ArrowLeftSmallIcon from "shared/assets/icons/ArrowLeftSmall";
import MapMarkerIcon from "shared/assets/icons/MapMarker";
import PenIcon from "shared/assets/icons/Pen";
import TrashIcon from "shared/assets/icons/Trash";
import {SearchInput} from "shared/ui/SearchInput";
import {api} from '../../../../shared/libs/Auth'
import ImportCSVModal from "../../../../shared/ui/ImportCSVModal";

const blankLocation = {title: '', radius: 0, group: '', groups: []}

// defaultLocation - geojson and type for editing should be detected automatically
export function ManageLocationScreen({
                                        defaultLocation = {},
                                        onChange,
                                        mapPositionRef,
                                        closeOnSave = false,
                                        editLocation = false,
                                        showLocations = false,
                                        selectLocations = false,
                                        selectedLocationsResult = new Map(),
                                        onCreatingLocation = () => {},
                                        stepOfAlert = false,
                                        lastCreatedLocs,
                                        onLocationCreated = () => {},
                                    }) {
    const [locationSettings, setLocationSettings] = useState(defaultLocation.options || {...blankLocation});
    const [coords, setCoords] = useState(defaultLocation.coordinates || Array.from(selectedLocationsResult.values()).pop() || (mapPositionRef?.current.position ? Object.values(mapPositionRef.current.position) : [-97, 38]));
    const [tmpCoords, setTmpCoords] = useState(defaultLocation.coordinates || Array.from(selectedLocationsResult.values()).pop() ||  (mapPositionRef?.current.position ? Object.values(mapPositionRef.current.position) : [-97, 38]));
    const [chosenCoords, setChosenCoords] = useState(!!editLocation);
    const [selectedSidebarMenu, setSelectedSidebarMenu] = useState(0); // will use: 0-common menu, 1-add point menu, 2-existing location menu;
    const [currentEditType, setCurrentEditType] = useState((!editLocation)?0:1); // will use: 0-no editing, 1-point, 2-line, 3-polygone;
    const [freeze, setFreeze] = useState(false);
    const [searchFilter, setSearchFilter] = useState('');
    const [lastCreatedLocations, setLastCreatedLocations] = useState(lastCreatedLocs || null);
    const [isEditingMode, setIsEditingMode] = useState(false);

    const [locations, setLocations] = useState([]);

    const [groups, setGroups] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState(selectedLocationsResult);
    const [accordeon, setAccordeon] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const mapPositionRefLocal = useRef({});
    const [isImporting, setIsImporting] = useState(false);

    const [snackbar, setSnackbar] = useState({
        open: false,
        message: "",
        severity: "success"
    });
    const openSnackbar = (message, severity) => {
        setSnackbar({ open: true, message, severity });
    };
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({ open: false, message: "", severity: "success" });
    };

    useEffect(() => {
        fetchRealLocations().then((data) => {
            setLocations(data && data.location);
            calcGroups(data.location);
        });
    }, [freeze]);

    useEffect(() => {
        if(selectedLocations.size === 0 && selectedLocationsResult.size !== 0){
            setSelectedLocations(selectedLocationsResult);
        }
    }, [selectedLocationsResult]);

    useEffect(() => {
        onCreatingLocation(currentEditType === 0) //listener of start and end create of location
    }, [currentEditType, selectedSidebarMenu]);

    const calcGroups = (locations) => {
        if (!locations) {
            return;
        }
        const groups = {};
        locations.forEach(location => {
            if (!groups[location.location_group]) groups[location.location_group] = [];
            groups[location.location_group].push(location);
        });
        const groupsList = [];
        let ungrouped = [];
        let accordeon = {};
        for (let key in groups) {
            if (!key) {
                ungrouped = groups[key];
                continue
            }
            groupsList.push({
                group: key,
                locations: groups[key],
            })
            accordeon[key] = false;
        }
        groupsList.sort((a, b) => a.group.localeCompare(b.group));
        const newSettings = {...locationSettings};
        //newSettings.groups = [...groupsList];
        if (ungrouped.length) {
            let key = '';
            groupsList.push({group: key, locations: ungrouped})
            accordeon[key] = false;
        }
        setGroups(groupsList);
        setLocationSettings(newSettings);
        setAccordeon(accordeon);
    };
    let accordeonChanged = group => (event, isExpanded) => {
        let a = {...accordeon};
        a[group] = isExpanded;
        setAccordeon(a);
    };
    let searchStringChanged = ({target}) => {
        let s = target.value.trim();
        deselectAll();
        if (searchFilter.length === 0 && s.length !== 0) {
            let a = {...accordeon};
            for (let g in a) {
                a[g] = true;
            }
            setAccordeon(a);
        }
        else if (s.length === 0) {
            let a = {...accordeon};
            for (let g in a) {
                a[g] = false;
            }
            setAccordeon(a);
        }
        setSearchFilter(s);
    };

    useEffect(()=>{
        if (selectLocations)
            onChange(selectedLocations);
    }, [selectedLocations]);

    function clearEntity() {
        setCoords(undefined);
    }

    function confirmSelection() {
        setCoords(tmpCoords);
        setCurrentEditType(0);
        console.log("confirmSelection", coords, tmpCoords);
    }

    function cancelSelection() {
        setTmpCoords(coords);
        setCurrentEditType(0);
    }

    const onApiResponse = (data, newLocationData) => {
        setFreeze(false);
        if (defaultLocation.coordinates || closeOnSave) {
            onChange(true);
            return
        }
        setLocationSettings({...blankLocation})
        setCurrentEditType(0);
        setSelectedSidebarMenu(0);
        const selected = new Map(selectedLocations);
        selected.set(data.id, data.coordinates);
        setSelectedLocations(selected);

        if (lastCreatedLocations === null) { //Add first new location in lastCreatedLocation array
            setLastCreatedLocations([data]);
            onLocationCreated([data]);
        } else {
            if(isEditingMode){ //Edit location from lastCreatedLocation array
                const newLastCreatedLocations = lastCreatedLocations.map(location => {
                    return location.id === data[0] ? {
                        ...location,
                        label: newLocationData.label,
                        location_group: newLocationData.location_group,
                        coordinates: newLocationData.coordinates
                        }: location
                });
                setLastCreatedLocations(newLastCreatedLocations);
                onLocationCreated(newLastCreatedLocations)
            }
            else{ //Add new location in lastCreatedLocation array
                const newLastCreatedLocations = [...lastCreatedLocations, data];
                setLastCreatedLocations(newLastCreatedLocations);
                onLocationCreated(newLastCreatedLocations);
            }
        }
//        const nextStepBtn = document.getElementById('newalertstep2');
//        if(nextStepBtn) nextStepBtn.click();
    }

    function prepareLocationObj(coords, locationSettings){
        if (!coords || !locationSettings) {
            return;
        }
        const newLocation = {
            label: locationSettings.title || '',
            location_group: locationSettings.group || '',
            coordinates: coords
        }
        if (isEditingMode){
            putLocation(locationSettings.id, newLocation).then((data) => onApiResponse(data, newLocation));
        }
        else{
            if (!defaultLocation.options) { // create new location
                postLocation(newLocation).then(onApiResponse);
            }
            else {
                putLocation(locationSettings.id, newLocation).then(onApiResponse);
            }
        }

        setFreeze(true);
        setChosenCoords(false);
        setIsEditingMode(false);
    }

    const handleEditLastLocation = (id) =>{
        setIsEditingMode(true);

        const idToEdit = id;
        const editingLocation = lastCreatedLocations.find(loc => loc.id === idToEdit);
        setTmpCoords(editingLocation.coordinates);

        let newLocationSettings = Object.assign({}, locationSettings);
            newLocationSettings.group = editingLocation.location_group;
            newLocationSettings.title = editingLocation.label;
            newLocationSettings.id = idToEdit;
        setLocationSettings(newLocationSettings)
    }

    const deleteLocationById = (id) => {
        const idToDelete = id;

        const updatedLastCreatedLocations = lastCreatedLocations.filter(loc => loc.id !== idToDelete);
        setLastCreatedLocations(updatedLastCreatedLocations);
        onLocationCreated(updatedLastCreatedLocations);

        const selected = new Map(selectedLocations);
        selected.delete(idToDelete);
        setSelectedLocations(selected);
        deleteLocation(idToDelete).then(r => {console.log("location deleted"); setFreeze(false)})
    }

    let extractSelectedLocations = (selected) => {
        let ids = [];
        for (const id of selected.keys()) {
            ids.push(parseInt(id));
        }
        return locations.filter(x => ids.includes(x.id));
    };
    const groupLocations = group => {
        let locations = group.locations.sort((a, b) => a.label.localeCompare(b.label));
        if (searchFilter.length > 0) {
            return locations.filter(loc => filterName(loc.label));
        }
        return locations;
    }
    const allSelected = () => {
        if (!groups.length) return false;
        let selected = true;
        groups.forEach((group) => {
            if (!groupSelected(groupLocations(group))) selected = false;
        });
        return selected;
    }
    const allIndeterminate = () => {
        if (!groups.length) return false;
        let total = 0;
        let selected = 0;
        groups.forEach((group) => {
            let locations = groupLocations(group);
            if (locations.length > 0) {
                let n = groupNofSelected(locations);
                total += locations.length;
                selected += n;
            }
        });
        // console.log("allIndetermine", total, selected);
        return selected !== 0 && selected < total;
    }
    const selectAll = () => {
        let state = !allSelected();
        let selected = new Map(selectedLocations);
        groups.forEach((group) => {
            groupLocations(group).forEach((loc) => {
                if(!selected.has(loc.id) && state) selected.set(loc.id, loc.coordinates);
                if (!state) selected.delete(loc.id);
            });
        });
        setSelectedLocations(selected);
    }
    const deselectAll = () => {
        let selected = new Map(selectedLocations);
        groups.forEach((group) => {
            group.locations.forEach((loc) => {
                selected.delete(loc.id);
            });
        });
        setSelectedLocations(selected);
    }
    const groupNofSelected = (locations) => {
        let cnt = 0;
        locations.forEach((loc) => {
            if (selectedLocations.has(loc.id)) {
                cnt++;
            }
        });
        return cnt;
    }
    const groupSelected = (locations) => {
        let selected = true;
        locations.forEach((loc) => {
            if (!selectedLocations.has(loc.id)) selected = false;
        });
        return selected;
    }
    const groupIndeterminate = (locations) => {
        const state0 = !selectedLocations.has(locations[0].id);
        let indet = false;
        locations.forEach((loc) => {
            if (!selectedLocations.has(loc.id) !== state0) indet = true;
        });
        return indet;
    }
    const selectGroup = (ev, locations) => {
        if (ev) ev.stopPropagation();
        let state = !groupSelected(locations);
        let selected = new Map(selectedLocations);
        locations.forEach((loc) => {
            if (!selected.has(loc.id) && state) selected.set(loc.id, loc.coordinates);
            if (!state) selected.delete(loc.id);
        });
        setSelectedLocations(selected);
    }
    const filterName = (name) => {
        if (searchFilter === '') return true;
        const normName = name.toLowerCase();
        const normFilter = searchFilter.toLowerCase();
        if (normName.indexOf(normFilter) >= 0) return true;
        return false;
    }

    const clearLocationSetting = () =>{
        let newLocationSettings = Object.assign({}, locationSettings);
            newLocationSettings.group = "";
            newLocationSettings.groups = [];
            newLocationSettings.radius = 0;
            newLocationSettings.title = "";
        setLocationSettings(newLocationSettings);
    }

    const handleCancel = () =>{
        setChosenCoords(false);
        setCurrentEditType(0);
        setSelectedSidebarMenu(0);
        setIsEditingMode(false);
        clearLocationSetting();
    }

    const handleDelete = (locId) =>{
        setFreeze(true);
        deleteLocationById(locId);
        handleCancel();
        clearLocationSetting();
    }



    const importLocationsActionHandler = (file, groupName, onSuccess, onError) => {
        setIsImporting(true);
        if (!file) {
            console.log("Import cancelled or failed.");
            openSnackbar("Import cancelled or failed", "error");
            setIsImporting(false);
            return;
        }
        console.log("Processing file:", file.name, "for group:", groupName);

        const formData = new FormData();
        formData.append('file', file);
        formData.append('group', groupName);

        api.post('/locations', formData, {
            headers: {
                'Content-Type': undefined
            }
        })
        .then(response => {
            console.log("File uploaded successfully:", response.data);
            openSnackbar("Locations imported successfully", "success");
            fetchRealLocations().then(data => {
                setLocations(data && data.location);
                calcGroups(data.location);
                setIsImporting(false);
            });
            onSuccess(response.data);
        })
        .catch(error => {
            onError(error.response ? error.response.data : { error: error.message });
            setIsImporting(false);
        });        
            // .then(response => {
            //     console.log("File uploaded successfully:", response.data);
            //     openSnackbar("Locations imported successfully", "success");
            //     setErrorMessage(null);
            //     fetchRealLocations().then(data => {
            //         setLocations(data && data.location);
            //         calcGroups(data.location);
            //     });
            // })
            // .catch(error => {
            //     console.error("Failed to upload file:", error);
            //     openSnackbar("Failed to upload file: " + error.message, "error");
            //     setErrorMessage("The file format is incorrect. Please try to upload CSV file.");
            // });
    };


   return (
        <Box className={'column gap16 fullWidth LocationMapSelector'} sx={{flex: '1 1 auto', height:"100%", width:"96.5%", backgroundColor:"white", padding: stepOfAlert? "2px" : "24px", borderRadius:"16px"}}>
            <Box sx={{display:"flex", flexGrow: '1', flexDirection:"row", gap:"12px", alignItems: 'start', overflow: 'hidden'}}>
                <Box sx={{display:"flex", flexDirection:"column", width:"100%", height:"100%", gap:"16px"}}>
                    <LocationSearch
                        requester={requester}
                        onSelect={(data) => {
                            if (data.crds) setTmpCoords(data.crds)
                            setChosenCoords(true)
                            setSelectedSidebarMenu(1)
                            setCurrentEditType(1)
                            setLocationSettings({
                                ...locationSettings,
                                title: data.name,
                            })
                        }}
                        mapPositionRef={mapPositionRef || mapPositionRefLocal}
                    />
                    < MapLibreLocationStatic
                        onChange={(lngLat) => {
                            setChosenCoords(true);
                            setSelectedSidebarMenu(1);
                            setCurrentEditType(1);
                            setTmpCoords([Math.round(lngLat[0]*10000)/10000, Math.round(lngLat[1]*10000)/10000]);
                        }}
                        coordinates={tmpCoords}
                        editMode={1}
                        locations={locations}
                        selectedLocations={extractSelectedLocations(selectedLocations)}
                        chosenCoords={chosenCoords}
                        mapPositionRef={mapPositionRef || mapPositionRefLocal}
                    />
                </Box>

                <Box
                    className='locations column'
                    sx={{
                        minWidth: '384px',
                        width:"384px",
                        height: '100%',
                        overflow: 'hidden',
                        justifyContent: 'flex-start'
                    }}
                >
                    <Box sx={{display:"flex", flexDirection:"column", gap:"12px", height:"100%", paddingTop:"56px"}}>
                        {(selectedSidebarMenu === 0 && currentEditType === 0 && !defaultLocation.coordinates) &&
                            <Box style={{display:"flex", flexDirection:"column", gap:"8px"}}>
                                <Box style={{display:"flex", flexWrap:"wrap", gap:"8px",
                                    width:"100%", height: "148px",
                                    padding:"16px 12px",
                                    border: "solid 1px var(--palette-grey-100)", borderRadius:"8px",
                                    backgroundColor: "var(--palette-grey-background)"
                                }}>
                                    <Typography style={{fontWeight:"400", fontSize:"14px", color:"var(--palette-grey-600)"}}>
                                        New location
                                    </Typography>
                                    <Box style={{display:"flex", flexWrap:"wrap", gap:"8px"}}>
                                        <Button
                                            data-cy={'add-point-button'}
                                            disabled={defaultLocation.coordinates}
                                            startIcon={<MapPinIcon size={'small'}/>}
                                            variant={'outlined'}
                                            color={'secondary'}
                                            onClick={() => {
                                                setCurrentEditType(1);
                                                setIsEditingMode(false);
                                                setChosenCoords(true);
                                                setSelectedSidebarMenu(1);
                                            }}
                                            style={{width:"174px", fontWeight:"400", fontSize:"14px"}}
                                        >
                                            Add point
                                        </Button>
                                        <Button
                                            startIcon={<LineIcon size={'small'}/>}
                                            variant={'outlined'}
                                            color={'secondary'}
                                            onClick={() => setCurrentEditType(2)}
                                            style={{width:"174px", fontWeight:"400", fontSize:"14px"}}
                                            disabled
                                        >
                                            Add line
                                        </Button>
                                        <Button
                                            startIcon={<AreaIcon size={'small'}/>}
                                            variant={'outlined'}
                                            color={'secondary'}
                                            onClick={() => setCurrentEditType(3)}
                                            style={{width:"174px", fontWeight:"400", fontSize:"14px"}}
                                            disabled
                                        >
                                            Add area
                                        </Button>
                                        <ImportCSVModal
                                            actionHandler={importLocationsActionHandler}
                                            initialGroups={groups}
                                            openSnackbar={openSnackbar}
                                            typeOfImportingFiles={"locations"}
                                            buttonTitle={"Import locations"}
                                            modalTitle={"Import locations"}
                                            errorMessage={errorMessage}
                                            isImporting={isImporting}
                                        />
                                    </Box>
                                </Box>
                                {showLocations &&
                                    <Box style={{display:"flex", flexDirection:"column", gap:"8px",
                                        width:"100%", height:"100%",
                                        padding:"16px 12px",
                                        border: "solid 1px var(--palette-grey-100)", borderRadius:"8px",
                                        backgroundColor: "var(--palette-grey-background)"
                                    }}>
                                        <Typography style={{fontWeight:"400", fontSize:"14px", color:"var(--palette-grey-600)"}}>
                                            Your locations
                                        </Typography>
                                        <Button
                                            data-cy={'existing-locations-button'}
                                            variant={'outlined'}
                                            color={'secondary'}
                                            endIcon={
                                            <Box style={{
                                                width:"32px",
                                                height:"32px",
                                                border:"1px solid var(--palette-grey-100)", borderRadius:"8px",
                                                display:"flex", alignItems:"center", justifyContent:"center"
                                            }}>
                                                <ArrowRightSmallIcon
                                                    size={"small"}
                                                />
                                            </Box>}
                                            style={{width:"100%", height:"56px", display:"flex", justifyContent:"space-between", padding:"16px", fontWeight:"400", fontSize:"14px"}}
                                            onClick={()=>{setSelectedSidebarMenu(2); setChosenCoords(false)}}
                                        >
                                            Existing locations
                                        </Button>
                                    </Box>
                                }
                            </Box>
                        }


                        {((selectedSidebarMenu === 1 && currentEditType === 1) || defaultLocation.coordinates && !showLocations) &&
                            <Box sx={{display:"flex", gap:"16px", flexDirection:"column", alignItems:"start"}}>
                                <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", gap:"12px", padding:"2px"}}>
                                    <Button
                                        variant={'outlined'}
                                        color={'secondary'}
                                        sx={{height:"32px", width:"32px"}}
                                        disabled={defaultLocation.coordinates}
                                        onClick={()=>{
                                            setSelectedSidebarMenu(0);
                                            setCurrentEditType(0);
                                            setChosenCoords(false);
                                            handleCancel()
                                        }}
                                    >
                                        <ArrowLeftSmallIcon size={'small'}/>
                                    </Button>
                                    <h4>
                                        {isEditingMode ? 'Add point / Editing': 'Add point'}
                                    </h4>
                                </Box>
                                <Divider/>
                                <AddLocation
                                    onSave={(loc) => {
                                        console.log("AddLocation", loc, coords, tmpCoords);
                                        prepareLocationObj(tmpCoords, loc);
                                    }}
                                    onClose={handleCancel}
                                    defaultValue={locationSettings}
                                    groups={groups}
                                    freeze={freeze}
                                    chosenCoords={chosenCoords}
                                    locations={locations}
                                />
                            </Box>
                        }
                        {showLocations && selectedSidebarMenu === 2 &&
                            <Box sx={{height:"fit-content", overflowY:"auto", display:"flex", flexDirection:"column", gap:"16px"}}>
                                <Box sx={{display:"flex", flexDirection:"row", alignItems:"center", gap:"12px", padding: '2px'}}>
                                    <Button
                                        variant={'outlined'}
                                        color={'secondary'}
                                        sx={{height:"32px", width:"32px"}}
                                        onClick={()=>{setSelectedSidebarMenu(0); setChosenCoords(false)}}
                                    >
                                        <ArrowLeftSmallIcon size={'small'}/>
                                    </Button>
                                    <h4>
                                        Existing locations
                                    </h4>
                                </Box>
                                <Divider/>
                                <SearchInput
                                    placeholder='Search for location'
                                    onChange={searchStringChanged}
                                />
                                <Box
                                    className={'row gap4'}
                                    style={{
                                        padding: '8px 10px 8px 12px',
                                        borderBottom: "1px solid var(--palette-grey-100)",
                                        cursor: "pointer",
                                        marginBottom:"-8px"
                                    }}
                                    onClick={(ev) => selectAll(ev)}
                                >
                                    <CheckBox
                                        checked={allSelected()}
                                        indeterminate={allIndeterminate()}
                                        onClick={(ev) => selectAll(ev)}
                                    />
                                    <Box className={'paragraph'} style={{color: theme.palette.grey[900]}}>
                                        All locations ({locations.length})
                                    </Box>
                                </Box>

                                <Box>
                                    <Box className='locationsInner' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', alignContent: 'flex-start', height:"fit-content"}}>
                                        {groups.map(group => {
                                            let locations = groupLocations(group);
                                            if (locations.length === 0) {
                                                return undefined;
                                            }
                                            return (
                                                <Box className={'column fullWidth'} sx={{ marginBottom: '16px' }}>
                                                    <Accordion
                                                        className={'fullWidth'}
                                                        onChange={accordeonChanged(group.group)}
                                                        variant={'light'}
                                                    >
                                                        <AccordionSummary
                                                            id={`locgroup-${group.group}-header`}
                                                            aria-controls={`locgroup-${group.group}-content`}
                                                            variant={'light'}
                                                        >
                                                            <Box className={'row gap4'}>
                                                                <CheckBox
                                                                    checked={groupSelected(locations)}
                                                                    indeterminate={groupIndeterminate(locations)}
                                                                    onClick={(ev) => selectGroup(ev, locations)}
                                                                />
                                                                <Box className={'paragraph'} style={{color: theme.palette.grey[900]}}>
                                                                    {group.group || 'Ungrouped locations'} ({group.locations.length})
                                                                </Box>
                                                            </Box>
                                                        </AccordionSummary>
                                                        <AccordionDetails
                                                            className={'column gap8 locationGroup'}
                                                            variant={'light'}
                                                        >
                                                            {locations.map(loc => (
                                                                <Box
                                                                    className={'row gap4'}
                                                                    onClick={() => {
                                                                        const selected = new Map(selectedLocations);
                                                                        if (!selected.has(loc.id)) {
                                                                            selected.set(loc.id, loc.coordinates);
                                                                        }else {
                                                                            selected.delete(loc.id);
                                                                        }
                                                                        setSelectedLocations(selected)
                                                                    }}
                                                                    style={{
                                                                        cursor: 'pointer'
                                                                }}
                                                                >
                                                                    <CheckBox checked={selectedLocations.has(loc.id)}/>
                                                                    <Box className={'paragraph'}
                                                                         style={{color: theme.palette.grey[900]}}>
                                                                         {loc.label}
                                                                    </Box>
                                                                    <Spacer/>
                                                                    <MapPinIcon size={'small'}/>
                                                                </Box>
                                                            ))}
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </Box>
                                                );
                                        })}
                                    </Box>
                                </Box>
                            </Box>
                        }
                        </Box>
                    </Box>
            </Box>
            {lastCreatedLocations &&
                <Box style={{maxHeight:"95px", overflowY:"auto", display:"flex", flexDirection:"column", gap:"4px"}}
                     data-cy={'last-created-locations'}
                >
                    {lastCreatedLocations.map((loc)=>{
                        return(
                            <Box style={{display:"flex", flexDirection:"row", padding:"4px 8px",
                                height:"48px", gap:"4px", background: "white", borderRadius:"8px", border: "1px solid var(--palette-grey-100)"}}>
                                <Box style={{display:"flex", flexDirection:"row", alignItems:"center", color: "var(--palette-grey-900)", gap:"8px"}}>
                                    <MapPinIcon/>
                                    <Box style={{fontWeight:"500", fontSize:"14px"}}>{loc.label}</Box>
                                </Box>
                                <Spacer/>
                                <Box style={{display:"flex", flexDirection:"row", gap:"8px", alignItems:"center"}}>
                                    <Box style={{marginRight:"8px", fontWeight:"400", fontSize:"14px", color: "var(--palette-grey-500)"}}>
                                        {loc.location_group}
                                    </Box>
                                    <Button
                                        data-cy={'edit-button'}
                                        variant={'outlined'}
                                        color={'primary'}
                                        size={'small'}
                                        startIcon={<PenIcon size={'small'}/>}
                                        disabled={chosenCoords || isEditingMode}
                                        onClick={()=> {
                                            setCurrentEditType(1);
                                            setSelectedSidebarMenu(1);
                                            setChosenCoords(true);
                                            handleEditLastLocation(loc.id);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        color={'error'}
                                        size={'small'}
                                        startIcon={<TrashIcon size={'small'}/>}
                                        onClick={() => handleDelete(loc.id)}
                                        disabled={chosenCoords || isEditingMode}
                                    >
                                        Remove
                                    </Button>
                                </Box>
                            </Box>
                        )})}
                </Box>
            }
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </Box>
    );
}
