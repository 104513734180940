import stations from './stations.json';
import {AbstractTextProduct} from '../AbstractTextProduct';

export default class Nexrad extends AbstractTextProduct {

    constructor(opts) {
        opts = Object.assign({
            name: "nexrad",
            events: ['dragend', 'zoomend'],
            params: {}
        }, opts);
        super(opts);
    }

    _dataPointToMapPointType([station,lng,lat],id) {
        return {
            id,
            type: 'Feature',
            geometry: {
                type: 'Point',
                coordinates: [lng,lat]
            },
            properties: { station }
        };
    }

    _getSource(cb) {
        cb({
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: stations.map(this._dataPointToMapPointType)
            }
        });
    }

    createLayers() {
        return [//TODO: collisions handling for circles
            this.createSingleLayer({
                type: 'circle',
                paint: {
                    'circle-color': '#000000',
                    'circle-radius': 15,
                    'circle-opacity': [
                        'case',
                            ['boolean', ['feature-state', 'selected'], false],
                            1,
                            0.5
                    ]
                }
            }),
            this.createSingleLayer({
                type: 'symbol',
                layout: {
                    'text-field': '{station}',
                    'text-size': 12
                },
                paint: {
                    "text-color": [
                        'case',
                            ['boolean', ['feature-state', 'selected'], false],
                            "#33aaFF",
                            "#ffffff"
                    ]
                }
            })
        ];
    }
}