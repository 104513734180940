import OpenAlertMenuButton from "features/alert/OpenAlertMenuButton";
import dayjs from "dayjs";
import React, {useState, useEffect} from 'react';
import {Box, Chip, IconButton, Popover, Link, Divider, Badge} from '@mui/material';
import {api} from 'shared/libs/Auth';
import theme from 'app/Theme';
import {Spacer} from 'shared/ui/Spacer';
import CloseIcon from 'shared/assets/icons/Close';
import MapPinIcon from 'shared/assets/icons/MapPin'
import ModalComponent from "shared/ui/ModalComponent";
import {WeatherDefinitionDetails} from "entities/weatherDefinition/ui/WeatherDefinitionDetails";
import {ShowReportButton} from "features/alert/ShowReportButton";

const formatDate = (dateString) => {
    return dayjs(dateString).format('D MMM YYYY')
};

const formatTime = (dateString) => {
    return dayjs.utc(dateString).local().format('h:mm A');
};

const renderCompact = (n, format) => {
    if (!n.subalerts) return [];
    const alerts = n.subalerts;
    const issue_time_iso = n.issue_utc_iso;
    const result = []
    const day_format = format === 'date'? 'MMM D ': 'ddd '  // Jul 04 or Sun
    alerts.forEach(alert => {
        if (alert.type !== 'c' && alert.end_date <= issue_time_iso)
            return
        const start = dayjs.utc(alert.start_date).local();
        let start_date = start.format(day_format);
        let start_time = start.format('h:mm A').replace(':00', '');  // 2:50 PM
        let end_date = 'Ongoing', end_time = '';
        if (alert['type'] !== 'c') {
            let end = dayjs.utc(alert.end_date).local();
            if (end.minute() === 59)
                end = end.add(1, 'minute');
            end_date = end.format(day_format);
            end_time = end.format('h:mm A').replace(':00', '');  // 3:10 PM.
            if (start_date === end_date) {
                end_date = '';
                start_date = start_date.slice(0, -1) + ': '
                if (start_time.slice(-2) === end_time.slice(-2))
                    start_time = start.format('h:mm').replace(':00', '');  // 2:50
            }
        }
        const values = alert['conditions'].map(c => (c.type === 'c')? c.value : c.rule).join(', ')  // 50 F, 5 mph
        result.push(start_date + start_time + '-' + end_date + end_time + ': ' + values)
    });
    if (!result.length)
        result.push('Alert: Cleared')
    return result;
};

// overcomplicated function for wrap tokens into <b> tags
const renderNotificationTemplate = (data) => {
    let template = data.template;
    data.alert_name = data.title;
    data.start_time = formatTime(data.start_utc);
    data.start_date = formatDate(data.start_utc);
    data.end_time = formatTime(data.end_utc);
    data.end_date = formatDate(data.end_utc);
    data.issue_start_time = formatTime(data.issue_utc_iso);
    data.issue_start_date = formatDate(data.issue_utc_iso);

    const regex = /\{\{(.*?)\}\}/g;
    let match;
    let lastIndex = 0;
    const elements = [];

    template = template.split('{{email_template}}')[0]; // WL-466 new section for email template added
//    ['{{ellipsis}}'].forEach(token => {  // WL-466 remove unsupported tokens
//        template = template.replaceAll(token, '');
//    });
    if(template.indexOf('{{ellipsis}}') >= 0) {
        const alerts_num = data.subalerts.length;
        const parts = template.split('{{ellipsis}}').map(p => {
            let part = p.trim();
            if (part.slice(-'{{date_value_compact}}'.length) !== '{{date_value_compact}}' &&
                part.slice(-'{{day_value_compact}}'.length) !== '{{day_value_compact}}' &&
                part.slice(-'{{date_value_verbose}}'.length) !== '{{date_value_verbose}}'
            )
                return part;
            if (alerts_num <= 1)
                return part;
            const token = '{{' + part.split('{{').pop();
            return part + '\n' + Array(alerts_num - 1).fill(token).join('\n');
        });
        template = parts.join('');
    }
    if(template.indexOf('{{date_value_compact}}') >= 0) {
        data['date_value_compact'] = renderCompact(data, 'date');
        data['disable_triggered'] = true;
    }
    if(template.indexOf('{{day_value_compact}}') >= 0) {
        data['day_value_compact'] = renderCompact(data, 'day');
        data['disable_triggered'] = true;
    }
    if(template.indexOf('{{date_value_verbose}}') >= 0) {
        data['date_value_verbose'] = renderCompact(data, 'date');
        data['disable_triggered'] = true;
    }
    let day_val_idx = 0;
    while ((match = regex.exec(template)) !== null) {
        const text = template.slice(lastIndex, match.index);
        if (text) elements.push(text);

        const variable = match[1].trim();
        if (variable === 'date_value_compact' || variable === 'day_value_compact' || variable === 'date_value_verbose') {
            if (data[variable][day_val_idx]) {
                if (!day_val_idx) elements.push(<br/>)
                elements.push(<>{data[variable][day_val_idx]}<br/></>)
            }
            day_val_idx += 1;
        }
        else if (data[variable] !== undefined) {
            elements.push(<b key={variable}>{data[variable]}</b>);
        } else {
            elements.push(<b key={variable}>{match[0]}</b>);
        }

        lastIndex = regex.lastIndex;
    }

    const remainingText = template.slice(lastIndex);
    if (remainingText) elements.push(remainingText);

    return elements;
};


const formatTimeAgo = (timestamp) => {
    const now = new Date();
    const updatedTime = new Date(timestamp);
    const timeDiff = now - updatedTime;

    const minutesAgo = Math.floor(timeDiff / (1000 * 60));
    const hoursAgo = Math.floor(timeDiff / (1000 * 60 * 60));
    const daysAgo = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

    if (minutesAgo < 1) {
        return '<1 min ago';
    } else if (minutesAgo < 60) {
        return `${minutesAgo} min ago`;
    } else if (hoursAgo < 24) {
        return `${hoursAgo} hour${hoursAgo === 1 ? '' : 's'} ago`;
    } else {
        return `${daysAgo} day${daysAgo === 1 ? '' : 's'} ago`;
    }
};

export function NotificationsPopup({isOpen, onClose, onMarkAllAsRead, onNewNotifications, anchorBellEl}) {
    const [notifications, setNotifications] = useState([]);
    const [openDefinition, setOpenDefinition] = useState(null);

    const fetchNotifications = () => {
        api.get('/notifications')
            .then(response => {
                setNotifications(response.data);
                onNewNotifications(response.data);
            })
            .catch(error => {
                console.error('Error fetching notifications:', error);
            });
    };

    const markAllNotificationsAsRead = () => {
        api.post('/notifications')
            .then(() => {
                // fetchNotifications();
                onMarkAllAsRead();
            })
            .catch(error => {
                console.error('Error marking all notifications as read:', error);
            });
    };

    useEffect(() => {
        if (isOpen) {
            markAllNotificationsAsRead();
        } else {
            fetchNotifications();
        }

        const intervalId = setInterval(fetchNotifications, 60000);

        return () => {
            clearInterval(intervalId);
        };
    }, [isOpen]);

    return (
        <Popover
            open={isOpen}
            onClose={onClose}
            anchorEl={anchorBellEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            sx={{
                '& .MuiPaper-root': {
                    background: "none",
                    padding: 0,
                }
            }}
        >
            <Box className={'column'}
                 sx={{
                     padding: '16px 8px 16px 16px',
                     gap: '16px',
                     background: '#fff',
                     border: '1px solid #F0F2F5',
                     boxShadow: '0px 4px 12px rgba(103, 122, 142, 0.24)',
                     borderRadius: '12px'
                 }}
            >
                <div style={{
                    display: 'flex',
                    gap:"28px",
                    alignItems: "center",
                }}>
                    <div className={'subtitle medium'}>Alerts</div>
                    <Link href={'/alerts'} style={{
                        color: "var(--palette-primary-dark)",
                        fontSize: "14px",
                        fontWeight: "500",
                        textDecoration: "none"
                    }}>View
                        all  &gt;</Link>
                    <Spacer/>
                    <IconButton
                        id={'basic-button'}
                        variant={'outlined'}
                        size={'small'}
                        onClick={onClose}
                        sx={{marginLeft: 'auto'}}
                    >
                        <CloseIcon size={'small'}/>
                    </IconButton>
                </div>
                <Divider className={'fullWidth'}/>
                <div className="column"
                     style={{
                         overflowY: "auto",
                         maxWidth: '500px',
                         maxHeight: '500px',
                         gap: '8px',
                         justifyContent: 'flex-start',
                         width: 'max-content',
                     }}>
                    {notifications === undefined || notifications.length === 0 ? (
                        <div className='no-notifications-text'>
                            No notifications available.
                        </div>
                    ) : (
                        notifications.map((notification, notificationKey) => (
                            <div
                                key={notificationKey}
                                className="column fullWidth"
                                style={{
                                    paddingRight: '8px',
                                    overflow: 'unset'
                                }}
                            >
                                <div className={'row'}>
                                    <div className={'subtitle medium'}>{notification.title}</div>
                                    <div className={'paragraph'}
                                         style={{color: theme.palette.notification.status[notification.status]}}>
                                        {notification.status}
                                    </div>
                                    <Spacer/>
                                    <label>
                                        {formatTimeAgo(notification.timestamp)}
                                    </label>
                                    {!notification.seen &&
                                        <Badge badgeContent={' '} color={'error'} variant={'dot'}></Badge>
                                    }
                                    <OpenAlertMenuButton
                                        alert={notification}
                                        setOpenDefinition={setOpenDefinition}
                                    />
                                </div>
                                <Box
                                    className={'paragraph'}
                                    sx={{
                                        color: theme.palette.grey[500],
                                        '& b': {
                                            color: theme.palette.grey[900],
                                        }
                                    }}
                                >
                                    {renderNotificationTemplate(notification)}
                                    <div style={{display:"flex", flexDirection:"column", gap:"8px"}}>
                                        {!notification.disable_triggered &&
                                        <div style={{padding:"2px 16px", border:"1px solid var(--palette-primary-dark)", borderRadius:"8px", width:"fit-content"}}>
                                            {notification.triggered_products.map((triggered, index) => (
                                                <>
                                                    <p key={index} style={{color:"var(--palette-primary-dark)", fontSize:"12px", fontWeight:"400", lineHeight:"16px"}}>{triggered[0]}: {triggered[1]}</p>
                                                    {triggered[2] &&
                                                        <p key={index} _min style={{color:"var(--palette-primary-dark)", fontSize:"12px", fontWeight:"400", lineHeight:"16px"}}>Min value for forecast period: {triggered[2]}</p>}
                                                    {triggered[3] &&
                                                        <p key={index} _max style={{color:"var(--palette-primary-dark)", fontSize:"12px", fontWeight:"400", lineHeight:"16px"}}>Max value for forecast period: {triggered[3]}</p>}
                                                </>
                                            ))}
                                        </div>
                                        }
                                        {notification.report &&
                                            <ShowReportButton reportLink={notification.report}
                                                              style={{width: 'max-content', border:"none"}}/>
                                        }
                                    </div>
                                </Box>
                                <div className={'row'}>
                                    <Chip
                                        icon={<MapPinIcon size={'small'}/>}
                                        label={notification.location}
                                        size={'small'}
                                    />
                                    {notification.severity && (
                                        <Chip
                                            label={notification.severity}
                                            variant={theme.palette.weather.variant[notification.severity]}
                                            size={'small'}
                                        />
                                    )}
                                    <Spacer/>
                                </div>
                                <Divider className={'fullWidth'}/>
                            </div>
                        ))
                    )}
                    <ModalComponent visible={openDefinition} style={{p: 0}}>
                        <WeatherDefinitionDetails
                            definition_id={openDefinition?.id}
                            onChange={() => setOpenDefinition(null)}
                        />
                    </ModalComponent>
                </div>
            </Box>
        </Popover>
    );
}
