import {
    Box,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography, IconButton
} from '@mui/material';
import ArrowDownSmallIcon from "shared/assets/icons/ArrowDownSmall";
import {isEmpty} from "shared/libs/Utils";
import React, {useState, useEffect} from 'react';
import LocationLine from 'entities/location/ui/LocationLine';
import {
    fetchRealLocations,
    putLocation,
    deleteLocation
} from "../../../alerts/api/FetchAlerts";
import {EditLocationGroupName} from './EditLocationGroupName';
import {EntityGroupSelectorPopup} from 'features/entityGroupSelector/EntityGroupSelectorPopup';
import PenIcon from "../../../../shared/assets/icons/Pen";
import TrashIcon from "../../../../shared/assets/icons/Trash";
import {SearchInput} from "shared/ui/SearchInput";
import AgreeModal from "shared/ui/AgreeModal";
import ItemsSelectTopBar from "../../../../shared/ui/ItemSelectTopBar/ItemsSelectTopBar";
import EmptyStateLocations from "../../../../shared/ui/emptyStates/EmptyStateLocations";
import './SavedLocationsSetting.css';
import {CheckBox} from "../../../../shared/ui/CheckBox";
import ProgressModal from "../../../../shared/ui/ProgressModal";


export default function SavedLocationsSetting({onChange, doupdate}) {

    const [isOpenEditLocationGroup, setIsOpenEditLocationGroup] = useState(false);
    const [isOpenMoveLocationToGroup, setIsOpenMoveLocationToGroup] = useState(false);
    const [isOpenDeleteLocationGroup, setIsOpenDeleteLocationGroup] = useState(false);
    // const [isOpenNewLocation, setIsOpenNewLocation] = useState(false);
    const [locations, setLocations] = useState([]);
    const [locationGroups, setLocationGroups] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [accordion, setAccordion] = useState({});
    // const newGroupName = useRef('')
    const [update, setUpdate] = useState(0);

    const [toDelete, setToDelete] = useState({});
    const [deleteLocationsModal, setDeleteLocationsModal] = useState(false);
    const [progressModal, setProgressModal] = useState(false);

    const allLocationsSelected = locations.length > 0 && locations.every(location => toDelete[location.id]);
    const someLocationsSelected = Object.values(toDelete).some(val => val) && !allLocationsSelected;


    if (doupdate && doupdate()) {
        console.log("SavedLocationSetting.doupdate");
        setTimeout(() => {
            setUpdate(Date.now())
        }, 10);
    }
    useEffect(() => {
        fetchRealLocations().then((data) => {
            setLocations(data.location);
            calcGroups(data.location)
        });
    }, [update, isOpenEditLocationGroup, isOpenMoveLocationToGroup, isOpenDeleteLocationGroup]);

    useEffect(() => {
        if (locationGroups.length > 0 && isEmpty(accordion)) {
            const firstGroupName = locationGroups[0].group;
            setAccordion({[firstGroupName]: true});
        }
    }, [locationGroups]);

    const idsToDelete = Object.entries(toDelete).filter(([key, value]) => value).map(([key, value]) => +key);
    const locationsArray = Object.entries(locations).map(([key, location]) => (location));
    const locationsToDeleteArray = locationsArray.filter(location => idsToDelete.includes(location.id));

    let calcGroups = locations => {
        if (!locations) {
            return;
        }
        const groups = {};
        locations.forEach(location => {
            if (!groups[location.location_group]) groups[location.location_group] = [];
            groups[location.location_group].push(location);
        });
        const groupsList = [];
        let ungrouped = [];
        //let accordion = {};
        for (let key in groups) {
            if (!key) {
                ungrouped = groups[key];
                continue
            }
            groupsList.push({
                group: key,
                locations: groups[key],
            });
            //accordion[key] = false;
        }
        //groupsList.sort((a, b) => {return (a.group < b.group)?-1:1})
        groupsList.sort((a, b) => a.group.localeCompare(b.group));
        if (ungrouped.length) {
            let key = '';
            groupsList.push({group: key, locations: ungrouped})
            //accordion[key] = false;
        }
        setLocationGroups(groupsList);
        // setAccordion(accordion);
    };

    const renameLocationGroup = (state) => {
        if (!state || state.original === state.new) {
            setIsOpenEditLocationGroup(false);
            return
        }
        if (!state.new) return;
        let running_requests = 0;
        locations.forEach(loc => {
            if (loc.location_group !== state.original)
                return
            running_requests += 1;
            putLocation(loc.id, {location_group: state.new}).then(() => {
                running_requests -= 1;
                if (!running_requests) setIsOpenEditLocationGroup(false);
            });
        });
        const newIndex = {[state.new]: true}
        setAccordion({...accordion, ...newIndex})
    };

    const locationAction = (location, action, value) => {
        console.log("onAction", action, location, value);
        if (action === 'move') setIsOpenMoveLocationToGroup({
            locations: [location],
            original: location.location_group,
            new: location.location_group
        });
        if (action === 'delete') {
            if (idsToDelete.length < 1) {
                setIsOpenDeleteLocationGroup({locations: [location], delete_locations: true});
            } else {
                setDeleteLocationsModal(true);
            }
        }
        if (action === 'edit') {
            const loc = {
                options: {
                    title: location.label,
                    radius: 0,
                    group: location.location_group,
                    id: location.id,
                },
                coordinates: location.coordinates,
            }
            onChange('edit_location', {location: loc, groups: locationGroups});
        }
        // if (action === 'select') {
        //     let toDel = toDelete;
        //     toDel[location.id] = value;
        //     for (let id in toDel) {
        //         if (toDel[id]) {
        //             setToDelete({...toDel});
        //             return;
        //         }
        //     }
        //     setToDelete({});
        // }
        // else if (action === 'selectall') {
        //     if (value) {
        //         locations.forEach(location => toDelete[location.id] = value);
        //         setToDelete({...toDelete});
        //     }
        //     else {
        //         setToDelete({});
        //     }
        // }
        if (action === 'select') {
            let updatedToDelete = {...toDelete};
            updatedToDelete[location.id] = value;
            setToDelete(updatedToDelete);
        }
        if (action === 'selectall') {
            const newToDelete = {};
            let allCurrentlySelected = !allLocationsSelected;
            locations.forEach(location => newToDelete[location.id] = allCurrentlySelected);
            setToDelete(newToDelete);
        }


    };

    const moveLocationsToGroup = (state) => {
        if (!state) {
            setIsOpenMoveLocationToGroup(false);
            return
        }
        if (!state.new) return;

        let running_requests = 0;
        state.locations.forEach(loc => {
            if (loc.location_group === state.new)
                return
            running_requests += 1;
            putLocation(loc.id, {location_group: state.new}).then(() => {
                running_requests -= 1;
                if (!running_requests) setIsOpenMoveLocationToGroup(false);
            });
        });
        if (!running_requests)
            setIsOpenMoveLocationToGroup(false);
    };

    const deleteLocationGroup = (agree) => {
        if (agree) {
            setProgressModal(true);
            const state = isOpenDeleteLocationGroup;
            if (!state.delete_locations) {
                let running_requests = 0;
                state.locations.forEach(loc => {
                    setProgressModal(false);
                    running_requests += 1;
                    putLocation(loc.id, {location_group: ''}).then(() => {
                        running_requests -= 1;
                        if (!running_requests) setIsOpenDeleteLocationGroup(false);
                    });
                });
            } else {
                let running_requests = 0;
                state.locations.forEach(loc => {
                    running_requests += 1;
                    deleteLocation(loc.id).then(() => {
                        setProgressModal(false);
                        running_requests -= 1;
                        if (!running_requests) setIsOpenDeleteLocationGroup(false);
                    });
                });
            }
        } else {
            setIsOpenDeleteLocationGroup(false);
        }
    };

    let searchStringChanged = ({target}) => {
        let s = target.value.trim();
        if (searchString.length === 0 && s.length !== 0) {
            let a = {};
            for (let g in locationGroups) {
                a[locationGroups[g].group] = true;
            }
            setAccordion(a);
        } else if (searchString.length !== 0 && s.length === 0) {
            // if all groups are open, close them all (???)
            let cntclosed = 0;
            for (let g in accordion) {
                if (!accordion[g]) {
                    cntclosed++;
                    break;
                }
            }
            if (cntclosed === 0) {
                setAccordion({});
            }
        }
        setSearchString(target.value.trim());
    };
    let accordionChanged = group => (event, isExpanded) => {
        let a = {...accordion};
        a[group] = isExpanded;
        setAccordion(a);
    };

    const deleteLocations = (agree) => {
        setDeleteLocationsModal(true);
        if (agree) {
            setProgressModal(true);
            let loc = [];
            idsToDelete.forEach((id)=>{loc.push(deleteLocation(id))})
            Promise.all(loc).then((results) => {
                console.log(results);
                setLocations(locations.filter(location => !idsToDelete.includes(location.id)))
                const groupsFilter = [...new Set(locationsToDeleteArray.map(item => item.location_group))]
                const filteredGroups = locationGroups.map(group => {
                    if (groupsFilter.includes(group.group)) {
                        return {
                            ...group,
                            locations: group.locations.filter(location => !idsToDelete.includes(location.id))
                        };
                    }
                    return group
                }).filter(group => group.locations.length)
                setLocationGroups(filteredGroups)
                setToDelete({});
                setProgressModal(false);
            });
        }
        setDeleteLocationsModal(false);
    }

    const handleChangeDeleteLocations = () => {
        setIsOpenDeleteLocationGroup({
            group: isOpenDeleteLocationGroup.group,
            delete_locations: !isOpenDeleteLocationGroup.delete_locations,
            locations: isOpenDeleteLocationGroup.locations
        })
    }

    const getGroupLocationsID = (group) => {
        return locationGroups.find(groupItem => groupItem.group === group)
            ?.locations.map(location => location.id) || [];
    };

    const allSelected = (group) => {
        const groupLocationsID = getGroupLocationsID(group);
        return groupLocationsID.every(locationId => toDelete[locationId]);
    };

    const allIndeterminate = (group) => {
        const groupLocationsID = getGroupLocationsID(group);

        if (!groupLocationsID.length) return true;

        return groupLocationsID.some(locationId => toDelete[locationId] && !allSelected(group));
    };

    const handleSelectAll = (group) => {
        const groupLocationsID = getGroupLocationsID(group);

        setToDelete(prevState => {
            const updatedToDelete = {...prevState};
            const checkAllSelected = groupLocationsID.every(locationId => prevState[locationId]);
            groupLocationsID.forEach(locationId => updatedToDelete[locationId] = !checkAllSelected)
            return updatedToDelete;
        });
    };

    let s = searchString.toLocaleLowerCase();
    return (
        <Box className="SavedLocationsSettings settings">
            <Box className="settings-toolbar" style={{borderBottom: "1px solid var(--palette-grey-100)"}}>
                <h3>
                    Saved locations
                </h3>
                <SearchInput
                    placeholder='Search'
                    value={searchString}
                    onChange={searchStringChanged}
                />
                <Button
                    data-cy={'new-location-button'}
                    sx={{marginLeft: 'auto'}} onClick={() => {
                    onChange('new_location', {groups: locationGroups})
                }}>New location</Button>
            </Box>

            {/* <ItemsDelete onAction={locationAction} visible={!isEmpty(toDelete)}/> */}
            <ItemsSelectTopBar
                onAction={locationAction}
                visible={Object.values(toDelete).some(value => value)}
                allSelected={allLocationsSelected}
                indeterminate={someLocationsSelected}
            />


            <Box style={{overflow: "auto", padding:"16px 0", display:"flex", flexDirection:"column", gap:"16px", height:"100%"}}>
                {isEmpty(Object.values(locations)) ?
                    <Box sx={{width: "100%", height: "100%"}}>
                        <EmptyStateLocations title={"Locations will appear here"}
                                             text={"You will see locations here once they are created"}/>
                    </Box> :
                    (locationGroups.map(locationGroup => {
                            let locations = locationGroup.locations;
                            locations.sort((a, b) => a.label.localeCompare(b.label));
                            if (s) {
                                locations = locations.filter((l) => l.label.toLocaleLowerCase().indexOf(s) >= 0)
                                if (locations.length === 0) {
                                    return undefined;
                                }
                            }
                            return (
                                <Box className={'savedLocationsList'}>
                                    <Accordion
                                        expanded={accordion[locationGroup.group] === true}
                                        onChange={accordionChanged(locationGroup.group)}
                                        key={locationGroup.group}
                                        sx={{
                                            boxShadow: 'inherit',
                                            alignItems: 'center',
                                            borderRadius: '8px',
                                            overflow: "hidden",
                                            border: '1px solid var(--color-coding-grayscale-100, #F0F2F5)',
                                            background: 'var(--color-coding-grayscale-0, #FFF)',
                                            '&:before': {
                                                display: 'none',
                                            },
                                            '&:first-of-type': {
                                                borderTopLeftRadius: '8px',
                                                borderTopRightRadius: '8px',
                                            },
                                            '& .Mui-expanded': {
                                                background: 'var(--palette-grey-background)',
                                                borderBottomLeftRadius: '0px',
                                                borderBottomRightRadius: '0px',
                                            },
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={
                                                <IconButton
                                                    size='tiny'
                                                    variant='outlined'
                                                >
                                                    <ArrowDownSmallIcon size={'small'}/>
                                                </IconButton>}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            sx={{
                                                width: 'auto',
                                                height: "46px",
                                                display: 'flex',
                                                alignItems: 'center',
                                                background: 'var(--color-coding-grayscale-0, #FFF)',
                                                borderRadius: '8px',
                                            }}
                                        >
                                            <Box sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: '8px',
                                                alignSelf: 'stretch',
                                                borderRadius: '8px',
                                            }}>
                                                <CheckBox
                                                    checked={allSelected(locationGroup.group)}
                                                    indeterminate={allIndeterminate(locationGroup.group)}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleSelectAll(locationGroup.group)
                                                    }}
                                                />
                                                <Box sx={{height: '24px'}}>
                                                    <Typography sx={{
                                                        color: "var(--color-coding-grayscale-900, #171E27)",
                                                        fontSize: '14px',
                                                        fontStyle: "normal",
                                                        fontWeight: '400',
                                                        lineHeight: "24px",
                                                        letterSpacing: "0.16px",
                                                    }}>
                                                        {locationGroup.group || 'Ungrouped locations'} ({locationGroup.locations.length})
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </AccordionSummary>

                                        <AccordionDetails
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                background: 'var(--palette-grey-background)',
                                                padding: '0px 16px'
                                            }}>
                                            {
                                                locations.map(location => {
                                                    return (
                                                        <Box sx={{marginBottom: "8px"}}>
                                                            <LocationLine location={location} onChange={locationAction}
                                                                          selected={toDelete && toDelete[location.id]}/>
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </AccordionDetails>

                                        {locationGroup.group &&
                                            <Box sx={{
                                                display: "flex",
                                                padding: "0px 16px 16px",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                                gap: "8px",
                                                alignSelf: "stretch",
                                                background: 'var(--palette-grey-background)',
                                            }}>
                                                <Button
                                                    className={'regular'}
                                                    variant={'outlined'}
                                                    color={'primary'}
                                                    size={'small'}
                                                    startIcon={<PenIcon size={'small'}/>}
                                                    onClick={() => {
                                                        setIsOpenEditLocationGroup({
                                                            original: locationGroup.group,
                                                            new: locationGroup.group
                                                        })
                                                    }}
                                                >
                                                    Edit group name
                                                </Button>
                                                <Button
                                                    className={'regular'}
                                                    variant={'outlined'}
                                                    color={'error'}
                                                    size={'small'}
                                                    startIcon={<TrashIcon size={'small'}/>}
                                                    onClick={() => setIsOpenDeleteLocationGroup({
                                                        group: locationGroup.group,
                                                        locations: locationGroup.locations,
                                                        delete_locations: false
                                                    })}
                                                >
                                                    Delete group
                                                </Button>
                                            </Box>
                                        }
                                    </Accordion>
                                </Box>
                            )
                        })
                    )}
            </Box>

            {isOpenEditLocationGroup &&
                <EditLocationGroupName
                    editGroupState={isOpenEditLocationGroup}
                    onChange={renameLocationGroup}
                />
            }

            {isOpenMoveLocationToGroup &&
                <EntityGroupSelectorPopup
                    moveToGroupState={isOpenMoveLocationToGroup}
                    onChange={moveLocationsToGroup}
                    groups={locationGroups}
                />
            }

            {isOpenDeleteLocationGroup &&
                <AgreeModal
                    data={{
                        message: <Box className='column' sx={{
                            alignContent: 'stretch',
                            overflow: "hidden",
                            "&.MuiBox-root": {maxWidth: "fit-content"}
                        }}>
                            {isOpenDeleteLocationGroup.group &&
                                <Typography sx={{fontSize: "18px"}}>Are you sure you want
                                    delete "{isOpenDeleteLocationGroup.group}" location group?</Typography>}
                            {!isOpenDeleteLocationGroup.group && isOpenDeleteLocationGroup.locations.length > 1 &&
                                <Typography sx={{fontSize: "18px"}}>Are you sure you want
                                    delete "{isOpenDeleteLocationGroup.locations.length}" saved locations?</Typography>}
                            {!isOpenDeleteLocationGroup.group && isOpenDeleteLocationGroup.locations.length === 1 &&
                                <Typography sx={{fontSize: "18px"}}>Are you sure you want
                                    delete "{isOpenDeleteLocationGroup.locations[0].label}" saved
                                    location?</Typography>}
                            {/*<Box sx={{maxHeight: "200px", overflowY: "auto"}}>{definitionsToDelete.map(definition => <span>{definition.name}<br/></span>)}</Box>*/}
                            {isOpenDeleteLocationGroup.group &&
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: "15px",
                                    cursor: "pointer"
                                }} onClick={handleChangeDeleteLocations}>
                                    <CheckBox checked={isOpenDeleteLocationGroup.delete_locations}
                                              inputProps={{'aria-label': 'controlled'}}
                                    />
                                    <Typography sx={{fontSize: '16px'}}>Also delete locations in this group</Typography>
                                </Box>}
                        </Box>,
                        title: `Delete ${isOpenDeleteLocationGroup.group ? 'group' : isOpenDeleteLocationGroup.locations.length > 1 ? 'locations' : 'location'}`,
                        agreeMsg: "Delete",
                        mode: "deleting",
                        agreeFunc: deleteLocationGroup
                    }}
                />
            }
            {deleteLocationsModal &&
                <AgreeModal
                    data={{
                        message: <Box className='column' sx={{
                            alignContent: 'stretch',
                            overflow: "hidden",
                            "&.MuiBox-root": {width: "100%"}
                        }}>
                            <Typography
                                sx={{fontSize: "18px"}}>{(toDelete.length === 1) ? "Are you sure you want to delete this location?" : "Are you sure you want to delete these locations?"}</Typography>
                            <Box sx={{maxHeight: "200px", overflowY: "auto"}}>{locationsToDeleteArray.map(location =>
                                <span>{location.label}<br/></span>)}</Box>
                        </Box>,
                        title: "Delete selected location groups?",
                        agreeMsg: "Delete",
                        mode: "deleting",
                        agreeFunc: deleteLocations
                    }}
                />
            }
            <ProgressModal visible={progressModal}/>
        </Box>
    )
}
