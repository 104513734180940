import { rasterMetaData } from "shared/libs/mapProducts/RasterMeta";

const PREFIX_SOURCE = 'source-tiles';
const DELTA_OLD_DATA = 3; // 2 times range for mark data is not match to exist layers

export class TileLayer {

    constructor(options) {
        /*
            id: string,
            map: map,
            startDate: timeInMs,
            endDate: timeInMs,
            signature: new Signature (requester3),
            [opacity]: [0,...,1],
            [firstLabelLayerId]: string
        */
        this.signature = options.signature;
        this.map = options.map;
        this.id = options.id;
        this.startDate = options.startDate;
        this.endDate = options.endDate;
        this.time = options.time;
        this.opacity = options.opacity || 1;
        this.firstLabelLayerId = options.firstLabelLayerId || 'building-top';
        this.meta = {
            forecastTime: undefined,
            times: []
        };
        this.isShowing = true;
        this.onInit = options.onInit;
        this._initialized = false;
        this._currentTime = new Date().getTime();
        this._updateMetaData();
    }

    _onUpdateMeta() {
        this._currentTime = new Date().getTime();
        if (!this.meta.times || !this.meta.times.length) {
            return;
        }
        if (!this._initialized) {
            this._initialized = true;
            this.time = this._getClosestTime(this.time);
            this._render();
            this.onInit && this.onInit();
        }
        if (this.meta.times.length <= 1) {
            return;
        }
        const t1 = new Date(this.meta.times[this.meta.times.length - 1]).getTime();
        const t2 = new Date(this.meta.times[0]).getTime();
        const increasedMediumDelta = Math.round(1.1 * Math.abs((t1 - t2) / (this.meta.times.length - 1)));
        this._updaterIndex = window.setTimeout(() => {
            this._updateMetaData();
        }, increasedMediumDelta);
    }

    _updateMetaData() {
        window.clearTimeout(this._updaterIndex);
        rasterMetaData({
            productId: this.id,
            signature: this.signature,
            startDate: this.startDate
        }).then(meta => {
            if (!meta) {
                console.log('Update meta data failed. Product: ', this.id);
                return;
            }
            this.meta = meta;
            this._onUpdateMeta();
        });
    }

    _render() {
        this._clearLayer();
        this._clearSource();
        if (!this.time || !this.isShowing) return;
        this._enable(this.meta.forecastTime ?
            `{baron_tile}${this.id.replace("/", "+")}+${this.meta.forecastTime}/{z}/{x}/{y}.png?valid_time=${this.time}&` :
            `{baron_tile}${this.id.replace("/", "+")}+${this.time}/{z}/{x}/{y}.png?`);
    }

    _enable(url) {
        this.map.addSource(PREFIX_SOURCE + this.id, createSourceObjectMapLibre(url));

        this.map.addLayer(
            createLayerObjectMapLibre({ id: this.id, opacity: this.opacity }),
            this.firstLabelLayerId
        );
    }

    _getClosestTime(time) {
        time = new Date(time).getTime(); // just normalize all time formats
        if (!this.meta.times || !this.meta.times.length) {
            return;
        }
        if (this.meta.times.length === 1) {
            return this.meta.times[0];
        }
        if (this.meta.forecastTime) {
            if (this._currentTime > time) {
                return; // not forecast date for forecast product
            }
        } else {
            if (this._currentTime < time) {
                return; // not current date for current product
            }
        }

        let delta = Number.MAX_VALUE;
        let selectedTime;
        this.meta.times.forEach(t => {
            const currentDelta = Math.abs(new Date(t).getTime() - time);
            if (currentDelta < delta) {
                delta = currentDelta;
                selectedTime = t;
            }
        });

        const t1 = new Date(this.meta.times[this.meta.times.length - 1]).getTime();
        const t2 = new Date(this.meta.times[0]).getTime();
        const increasedMediumDelta = Math.round(DELTA_OLD_DATA * Math.abs((t1 - t2) / (this.meta.times.length - 1)));
        if (increasedMediumDelta < Math.abs(new Date(selectedTime).getTime() - time)) {
            return; // not actual match
        }

        return selectedTime;
    }

    _clearLayer(){
        const l = this.map.getLayer(this.id);
        l && this.map.removeLayer(this.id);
    }

    _clearSource(){
        const s = this.map.getSource(PREFIX_SOURCE + this.id);
        s && this.map.removeSource(PREFIX_SOURCE + this.id);
    }

    hide() {
        this.isShowing = false;
        this._clearLayer();
    }

    show() {
        this.isShowing = true;
        const s = this.map.getSource(PREFIX_SOURCE + this.id);
        if (!s) return;
        const l = this.map.getLayer(this.id);
        if (l) return;
        this.map.addLayer(
            createLayerObjectMapLibre({ id: this.id, opacity: this.opacity }),
            this.firstLabelLayerId
        );
    }

    disable() {
        window.clearTimeout(this._updaterIndex);
        this.hide();
        this._clearSource();
    }

    setOpacity(opacity) {
        this.opacity = opacity;
        const layer = this.map.getLayer(this.id);
        layer && this.map.setPaintProperty(this.id, 'raster-opacity', this.opacity);
    }

    setTimeRange({ startDate, endDate }) {
        this.endDate = endDate || this.endDate;
        if ((this.startDate !== startDate) && startDate) {
            this.startDate = startDate;
            this._updateMetaData();
        }
    }

    setTime(time) {
        if (!this._initialized) {
            this.time = time;
            return;
        }
        const prevTime = this.time;
        this.time = this._getClosestTime(time);
        if (prevTime !== this.time && this.isShowing){
            this._render();
        }
    }

    async getPointQuery(lat, lon, timeRequest) {
        const closestTime = this._getClosestTime(timeRequest);
        if (!closestTime) return;
        const time = encodeURIComponent(this.meta.forecastTime || closestTime);
        const path = `point/${this.id}/${time}.json`;
        const params = {lat, lon, valid_time: this.meta.forecastTime ?closestTime : undefined};

        const url = this.signature.makeUrl({path, params});
		return fetch(url)
            .then(response => response.json())
            .then(data=>{
                return {
                    id: this.id,
                    data
                }
            }).catch(()=>{
                return {
                    id: this.id,
                    data: undefined
                }
            });
    }

    async getPointQueryForWatchesAndWarningsText(lat, lon, timeRequest) {
        const closestTime = this._getClosestTime(timeRequest);
        if (!closestTime) return;
        const time = encodeURIComponent(this.meta.forecastTime || closestTime);
        const path = `reports/alert/all-poly/point.json`;
        const params = {lat, lon, valid_time: this.meta.forecastTime ?closestTime : undefined};

        const url = this.signature.makeUrl({path, params});
        return fetch(url)
            .then(response => response.json())
            .then(data=>{
                return {
                    id: this.id,
                    data: data.alert.data[0].type
                }
            }).catch(()=>{
                return {
                    id: this.id,
                    data: undefined
                }
            });
    }

}

function createLayerObjectMapLibre({id, opacity}){
    return {
        "id": id,
        "type": "raster",
        "source": PREFIX_SOURCE + id,
        "minzoom": 2,
        "maxzoom": 22,
        "scheme": "tms",
        "paint": {
            "raster-opacity": opacity
        }
    }
}

function createSourceObjectMapLibre(url){
    return {
        "type": "raster",
        "tiles": [url],
        "tileSize": 256,
        "attribution":"",
        "scheme": "tms",
        "tilejson": "1.0.0"
    }
}

export class SSRTileLayer extends TileLayer{

    constructor(options) {
        super(options);
        this.firstLabelLayerId ='tunnel-service-track-casing';
        this.isSSRLayer = true;
        this.SSR = map.customSettingSSR;
        if (this.SSR){
            this._updateMetaData();
        } //TODO: solve refresh on dragend*
        options.map.on('change_customSettingSSR', ()=>{
            this.SSR = map.customSettingSSR;
            this._initialized = false;
            this._updateMetaData();
        });
    }

    _updateMetaData() {
        if (!this.SSR) return;
        window.clearTimeout(this._updaterIndex);
        rasterMetaData({
            productId: this.id.replace('{SSR}', this.SSR),
            signature: this.signature,
            startDate: this.startDate
        }).then(meta => {
            if (!meta) {
                console.log('Update meta data failed. Product: ', this.id);
                return;
            }
            this.meta = meta;
            this._onUpdateMeta();
        });
    }

    show() {
        if (!this.isShowing){
            this.isShowing = true;
            this._render();
        }
    }

    _render() {
        if (!this.SSR) return;
        this._clearLayer();
        this._clearSource();
        if (!this.time || !this.isShowing) return;
        this._enable(this.meta.forecastTime ?
            `{baron_tile}${this.id.replace('{SSR}', this.SSR).replace("/", "+")}+${this.meta.forecastTime}/{z}/{x}/{y}.png?valid_time=${this.time}&` :
            `{baron_tile}${this.id.replace('{SSR}', this.SSR).replace("/", "+")}+${this.time}/{z}/{x}/{y}.png?`);
    }

    async getPointQuery(lat, lon, timeRequest) {
        if (!this.SSR) {
            return {
                id: this.id,
                data: undefined
            }
        }
        const closestTime = this._getClosestTime(timeRequest);
        if (!closestTime) return;
        const time = encodeURIComponent(this.meta.forecastTime || closestTime);
        const path = `point/${this.id.replace('{SSR}', this.SSR)}/${time}.json`;
        const params = {lat, lon, valid_time: this.meta.forecastTime ?closestTime : undefined};

        const url = this.signature.makeUrl({path, params});
		return fetch(url)
            .then(response => response.json())
            .then(data=>{
                return {
                    id: this.id,
                    data
                }
            }).catch(()=>{
                return {
                    id: this.id,
                    data: undefined
                }
            });
    }
}