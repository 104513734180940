export const TimeRangeMap = {
    "north-american-radar": -240,
    "baron-hires-maxreflectivity-dbz-all": 3600,
    "C09-0x0374-0": -1440,
    "C39-0x03A1-0": -240,
    "C39-0x038A-0": -240,
    "baron-hires-haildiameter": 3600,
    "C39-0x0310-0": -240,
    "lightning": -240,
    "metar": -240,
    "nhc_tropical_tracks": -240,
    "baron-hires-lightningtotal": 2880,
    "pwr-outage-county-percent": -240,
    "pwr-outage-state-percent": -240,
    "alert-all-poly": -240,
    "flash-flood-risk": 60,
    "precip-liquidaccum-1hr": -240,
    "gfs-liquidaccum-in-surface": 3600,
    "precip-snowaccum-1hr": -240,
    "gfs-snowaccum-in-surface": 3600,
    "precip-totalaccum-24hr": -120,
    "road-condition-current-hires": -180,
    "road-condition": 1440,
    "C39-0x03EA-0": -240,
    "gfs-temp-f-2meter": 11520,
    "C39-0x03EC-0": -240,
    "baron-hires-feelslike-f-2meter": 3600,
    "C39-0x03ED-0": -240,
    "baron-hires-feelslike-2m": 3600,
    "C39-0x0356-0": -240,
    "C39-0x03EF-0": -240,
    "hrrr-windspeed-mph-10meter": 900,
    "gfs-gust-mph-10meter": 3600,
    "waveheight-ft": 3420,
    "extreme-weather-index-temp-min": 3600,
    "extreme-weather-index-hail": 3600,
    "extreme-weather-index-temp-max": 3600,
    "extreme-weather-index-liquidaccum-24hr": 3600,
    "extreme-weather-index-snowaccum-24hr": 3600,
    "extreme-weather-index-windspeed": 3600,
    "spc-day1-outlook": -1440,
    "spc-day1-tornado": -1440,
    "spc-day1-wind": -1440,
    "spc-day1-hail": -1440,
    "spc-day2-outlook": -1440,
    "spc-day2-tornado": -1440,
    "spc-day2-wind": -1440,
    "spc-day2-hail": -1440,
    "spc-day3-outlook": -1440,
    "air-quality-current-pm": -1440,
    "air-quality-pm25": 3600,
    "C39-0x0347-0": -120,
    "gfs-visibility-miles-surface": 3600,
    "C39-0x036B-0": -240,
    "gfs-pressure-mb-surface": 11520,
    "C39-0x03EE-0": -240,
    "baron-hires-relhumidity-2meter": 3600,
    "C09-0x0396-0": -240,
    "hrrr-smoke-surface": 3600,

    "{SSR}-0x0466-1-N": -240,
    "{SSR}-0x0466-1": -240,
    "{SSR}-0x0422-1": -240,
    "{SSR}-0x0265-0": -240,
    "{SSR}-0x0575-1": -240,
    "{SSR}-0x0575-1-N": -240
}