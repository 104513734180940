import {Box, Button, IconButton, Link, Menu, MenuItem, Popover, Typography} from '@mui/material';
import {ShowReportButton} from "features/alert/ShowReportButton";
import AreaIcon from "shared/assets/icons/Area";
import CloseIcon from "shared/assets/icons/Close";
import LinkSquareIcon from "shared/assets/icons/LinkSquare";
import MapPinIcon from "shared/assets/icons/MapPin";
import MoreIcon from "shared/assets/icons/More";
import {Spacer} from "shared/ui/Spacer";
import { weatherBackgroundColors, weatherLabelColors } from '../../../pages/timeline/TimelineChartsColors';
import {fetchWeatherDefinition} from "../../../pages/alerts/api/FetchAlerts";
import MapIcon from "../../../shared/assets/icons/Map";
import WeatherIcon from "../../../shared/assets/icons/Weather";
import React, {useRef, useState} from "react";
import './TimeLineChartsPopup.css'
import {ForecastMinMax} from './AlertForecastMinMax';


export default function TimeLineChartsPopup({tooltip, setTooltipState, openDefinition}) {
    const anchorMoreMenuEl = useRef()
    const [isMoreMenuOpen, setIsMoreMenuOpen] = useState(false)

    const onCloseTooltip = () => {
        setTooltipState(false);
    };

    const handleOpenMoreMenu = () => {
        setIsMoreMenuOpen(true)
    };

    const handleCloseMoreMenu = () => {
        setIsMoreMenuOpen(false);
    }

    const getDefinition = () => {
        fetchWeatherDefinition(tooltip.options.api_alert.wdef_id).then((data)=>{
            openDefinition(data);
        })
    }

    const forecastMinMax = (condition) => {
        const vals = JSON.parse(condition.value);  // [start val, min, max, units]
        const rows = [];
        rows.push(
            <>
                <Box key={condition.name}
                     sx={{display: 'flex', justifyContent: 'start', flexDirection: 'row', alignItems: 'center'}}>
                    <Box sx={{display: 'flex', fontSize: "14px", color: "#485158"}}>
                        {condition.name}:
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        fontSize: "14px",
                        color: "#171e27",
                        'marginLeft': 'auto',
                        lineHeight: '23px',
                        backgroundColor: '#f0f2f5',
                        borderRadius: '8px',
                        padding: '0 10px'
                    }}>
                        {vals[0]}{vals[3] && ' ' + vals[3]}
                    </Box>
                </Box>
                {vals[1] && ForecastMinMax[condition.name] &&
                            ForecastMinMax[condition.name].indexOf('min') >= 0 &&
                            (ForecastMinMax[condition.name].indexOf('max') < 0 || condition.rule.indexOf(' to ') > 0 || condition.rule.indexOf('<') >= 0) &&
                    <Box key={condition.name}
                         sx={{display: 'flex', justifyContent: 'start', flexDirection: 'row', alignItems: 'center'}}>
                        <Box sx={{display: 'flex', fontSize: "14px", color: "#485158"}}>
                            Min value for forecast period:
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            fontSize: "14px",
                            color: "#171e27",
                            'marginLeft': 'auto',
                            lineHeight: '23px',
                            backgroundColor: '#f0f2f5',
                            borderRadius: '8px',
                            padding: '0 10px'
                        }}>
                            {vals[1]}{vals[3] && ' ' + vals[3]}
                        </Box>
                    </Box>
                }
                {vals[2] && ForecastMinMax[condition.name] &&
                            ForecastMinMax[condition.name].indexOf('max') >= 0 &&
                            (ForecastMinMax[condition.name].indexOf('min') < 0 || condition.rule.indexOf(' to ') > 0 || condition.rule.indexOf('>') >= 0) &&
                    <Box key={condition.name}
                         sx={{display: 'flex', justifyContent: 'start', flexDirection: 'row', alignItems: 'center'}}>
                        <Box sx={{display: 'flex', fontSize: "14px", color: "#485158"}}>
                            Max value for forecast period:
                        </Box>
                        <Box sx={{
                            display: 'flex',
                            fontSize: "14px",
                            color: "#171e27",
                            'marginLeft': 'auto',
                            lineHeight: '23px',
                            backgroundColor: '#f0f2f5',
                            borderRadius: '8px',
                            padding: '0 10px'
                        }}>
                            {vals[2]}{vals[3] && ' ' + vals[3]}
                        </Box>
                    </Box>
                }
            </>
        );
        return rows;
    }

    let categoryColors = {
        'Forecast': '#09638C',
        'Live': '#0F8B6C',
        'Historical': '#677A8E'
    }

    const posStyle = {left: tooltip.x};
    posStyle[tooltip.origin] = tooltip.y;

    console.log("tooltip", tooltip)

    return (
        <Box
            id='timelinecharts-tooltip'
            className={'timeline-alert-popup'}
            sx={posStyle}
            onMouseUp={handleCloseMoreMenu}
            onMouseDown={e => e.stopPropagation()}
        >
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'start'}}>
                <Typography className={'subtitle medium'} style={{color: "var(--palette-grey-900)"}}>
                    {tooltip.type}
                </Typography>
                <Box sx={{
                    display: 'flex',
                    fontSize: "14px",
                    color: categoryColors[tooltip.category],
                    marginLeft: '15px',
                    paddingTop: '2px',
                    lineHeight: '21px',
                }}>
                    {tooltip.category}
                </Box>
                <Box sx={{'marginLeft': 'auto'}}>
                    <IconButton variant={'outlined'} size={'tiny'} onClick={onCloseTooltip}>
                        <CloseIcon size={'small'}/>
                    </IconButton>
                </Box>
            </Box>
            <div className={'column gap8 fullWidth'}>
                <Box sx={{
                    display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'stretch',
                    lineHeight: '28px', borderRadius: '8px', border: '1px solid #f0f2f5',
                    padding: '0 10px', flexGrow: 1
                }}>
                    <Box sx={{display: 'flex', fontSize: "14px", color: "#485158"}}>
                        Start:&nbsp;
                    </Box>
                    <Box sx={{display: 'flex', fontSize: "14px", color: "#171e27", 'marginLeft': 'auto'}}>
                        {tooltip.start}
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    justifyContent: 'stretch',
                    lineHeight: '28px',
                    borderRadius: '8px',
                    border: '1px solid #f0f2f5',
                    padding: '0 10px',
                    flexGrow: 1
                }}>
                    <Box sx={{display: 'flex', fontSize: "14px", color: "#485158"}}>
                        End:&nbsp;
                    </Box>
                    <Box sx={{display: 'flex', fontSize: "14px", color: "#171e27", 'marginLeft': 'auto'}}>
                        {tooltip.options.triggered ? 'Ongoing' : tooltip.end}
                    </Box>
                </Box>
                {tooltip.options.api_alert.report &&
                    <ShowReportButton reportLink={tooltip.options.api_alert.report}/>
                }
            <Box sx={{
                display: 'flex', flexDirection: 'column', justifyContent: 'start',
                lineHeight: '32px', borderRadius: '8px', border: '1px solid #f0f2f5',
                padding: '5px 10px', alignContent: 'center', backgroundColor: '#fafbfe'
            }}>
                {tooltip.conditions.map(condition => {
                    if (condition.value.indexOf('['))
                        return (
                            <Box key={condition.name}
                                 sx={{display: 'flex', justifyContent: 'start', flexDirection: 'row', alignItems: 'center'}}>
                                <Box sx={{display: 'flex', fontSize: "14px", color: "#485158"}}>
                                    {condition.name}:
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    fontSize: "14px",
                                    color: "#171e27",
                                    'marginLeft': 'auto',
                                    lineHeight: '23px',
                                    backgroundColor: '#f0f2f5',
                                    borderRadius: '8px',
                                    padding: '0 10px'
                                }}>
                                    {(condition.type === 'f')? condition.rule : condition.value}
                                </Box>
                            </Box>
                        );
                    else
                        return forecastMinMax(condition);
                })}
            </Box>
            </div>
            <Box sx={{
                display: 'flex', flexDirection: 'row', justifyContent: 'start',
                lineHeight: '24px', alignItems: 'center'
            }}>
                <Box sx={{
                    display: 'flex',
                    fontSize: "14px",
                    color: "#171e27",
                    backgroundColor: '#f0f2f5',
                    borderRadius: '8px',
                    padding: '0 10px',
                    alignItems: "center"
                }}>
                    {(tooltip.location_type === 'point') && <MapPinIcon size={'small'}/>}
                    {(tooltip.location_type === 'region') && <AreaIcon size={'small'}/>}
                    <span style={{marginLeft: '5px'}}>{tooltip.location}</span>
                </Box>
                <Box sx={{
                    display: 'flex',
                    fontSize: "14px",
                    color: weatherLabelColors[tooltip.severity],
                    backgroundColor: weatherBackgroundColors[tooltip.severity],
                    borderRadius: '8px',
                    marginLeft: '10px',
                    padding: '0 10px'
                }}>
                    {tooltip.severity}
                </Box>
                <Spacer/>
                <IconButton
                    aria-haspopup='true'
                    variant={'outlined'}
                    size={'tiny'}
                    onClick={handleOpenMoreMenu}
                    ref={anchorMoreMenuEl}
                >
                    <MoreIcon size={'small'}/>
                </IconButton>
            </Box>

            <Menu
                id='timelinecharts-popup-more'
                open={isMoreMenuOpen}
                anchorEl={anchorMoreMenuEl.current}
                onClose={handleCloseMoreMenu}
            >
                <MenuItem>
                    <MapIcon size={'small'}/>
                    <Link
                        className={'spacer'}
                        underline={'none'}
                        href={`/map?alert_id=${tooltip.options.aid}`}
                        onMouseUp={e => e.stopPropagation()}
                    >
                        View on map
                    </Link>
                </MenuItem>
                <MenuItem onClick={getDefinition} onMouseUp={e => e.stopPropagation()}>
                    <WeatherIcon size={'small'}/>
                    View weather definition
                </MenuItem>
            </Menu>

        </Box>
    )
}
