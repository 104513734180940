import {Badge} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, {useEffect, useState} from "react";
import BellIcon from "shared/assets/icons/Bell";
import {NotificationsPopup} from "widgets/notification/NotificationsPopup";
import NotificationSound from "../../shared/assets/sounds/notificationSound.mp3";
import {api} from "../../shared/libs/Auth";

export default function ShowNotificationsPopupButton() {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [isBadgeVisible, setIsBadgeVisible] = useState(false)
    const [uniqueSeenIds, setUniqueSeenIds] = useState(JSON.parse(localStorage.getItem('soundedNotificationIds')) || []); //ids of notifications that have already played sound

    const notificationSound = new Audio(NotificationSound);
    let enableNotificationSound = false;

    useEffect(() => {
        api.get('/user')
            .then(response => {
                const [data] = response.data;
                enableNotificationSound = (data.notification_sound);
            })
            .catch(error => {
                console.error('Error receiving user data:', error);
            })
    }, []);

    const handleOpenPopup = event => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
        setIsPopupOpen(true)
        setIsBadgeVisible(false)
    };

    const handleClosePopup = event => {
        event.stopPropagation()
        setIsPopupOpen(false)
        setAnchorEl(null)
    }

    const handleNotificationsChange = (newNotifications) => {
        setIsBadgeVisible(newNotifications.some(notification => !notification.seen));
        if (newNotifications.some(notification => !notification.seen)) {
            if (enableNotificationSound) {
                newNotifications.forEach(notification => {
                    if (!uniqueSeenIds.includes(notification.alert_id)) {
                        playNotificationSound()
                    }
                })
            }
            newNotifications.forEach(notification => {
                if (!uniqueSeenIds.includes(notification.alert_id)) {
                    const updatedSoundedIds = [...uniqueSeenIds, notification.alert_id]
                    setUniqueSeenIds(updatedSoundedIds)
                    uniqueSeenIds.push(notification.alert_id);
                    localStorage.setItem('soundedNotificationIds', JSON.stringify(uniqueSeenIds));
                }
            });
        }
    }

    const playNotificationSound = () => {
        notificationSound.play().then(() => console.log("New notification"))
            .catch(error => console.log("Notification sound error:", error))
    }

    const handleNewNotifications = (newNotifications) => {
        if (isPopupOpen) {
            handleMarkAllAsRead();
        } else {
            handleNotificationsChange(newNotifications);
        }
    };

    const handleMarkAllAsRead = () => {
        setIsBadgeVisible(false);
    };

    return (
        <>
            <IconButton onClick={handleOpenPopup}>
                <Badge badgeContent={' '} invisible={!isBadgeVisible} color={'error'} variant={'dot'}>
                    <BellIcon/>
                </Badge>
            </IconButton>

            <NotificationsPopup
                anchorBellEl={anchorEl}
                isOpen={isPopupOpen}
                onClose={handleClosePopup}
                onMarkAllAsRead={handleMarkAllAsRead}
                onNewNotifications={handleNewNotifications}
            />
        </>
    )
}