import CloseIcon from "shared/assets/icons/Close";
import {isEmpty} from "shared/libs/Utils";
import React, {useState, useEffect, useCallback} from 'react';
import {
    Accordion,
    MenuItem,
    Button,
    Box,
    Typography,
    IconButton,
    Input
} from '@mui/material';
import { api } from '../../shared/libs/Auth';
import { CheckBox } from "shared/ui/CheckBox";
import DraggableContactLine from 'entities/contact/ui/DraggableContactLine';
import ModalComponent from 'shared/ui/ModalComponent';
import theme from "../../app/Theme";
import { Select } from 'shared/ui/Select';
import  Contacts  from './Contacts'
import {SearchInput} from "shared/ui/SearchInput";
import AgreeModal from "shared/ui/AgreeModal";
import ItemsSelectTopBar from "../../shared/ui/ItemSelectTopBar/ItemsSelectTopBar";
import AccordionSummaryUserGroup from "../../shared/ui/Accordion/AccordionSummaryUserGroup";
import AccordionDetailsUserGroup from "../../shared/ui/Accordion/AccordionDetailsUserGroup";
import TrashIcon from "../../shared/assets/icons/Trash";
import PenIcon from "../../shared/assets/icons/Pen";
import {DraggableCore} from "react-draggable";
import EmptyStateContacts from "../../shared/ui/emptyStates/EmptyStateContacts";
import {EntityGroupSelectorPopup} from 'features/entityGroupSelector/EntityGroupSelectorPopup';
import ProgressModal from "../../shared/ui/ProgressModal";

export default function UsersAndContacts({setShowSidebar}) {
    const [userContacts, setUserContacts] = useState([]);
    const [groupUsers, setGroupUsers] = useState([]);
    const [noGroupUsers, setNoGroupUsers] = useState([]);
    const [checkedContacts, setCheckedContacts] = useState({});
    const allContactsSelected = userContacts.length > 0 && userContacts.every(contact => checkedContacts[contact.id]);
    const someContactsSelected = Object.values(checkedContacts).some(val => val) && !allContactsSelected;

    const [deleteUserGroup, setDeleteUserGroup] = useState(false);
    const [editUserGroup, setEditUserGroup] = useState({ current: '', newName: '' });

    //const [openModalInviteUsers, setOpenModalInviteUsers] = useState(false);
    const [filterSubscription, setFilterSubscription] = useState('All');
    const [filterSearchUsers, setFilterSearchUsers] = useState('');

    const [agreeModalMassDelete, setAgreeModalMassDelete] = useState(false);

    const idsToDelete = Object.entries(checkedContacts).filter(([key, value])=>value).map(([key,value])=>+key);
    const contactsToDelete = userContacts.filter(contact=>idsToDelete.includes(contact.id));

    const [dragPosition, setDragPosition] = useState({position: {x:0,y:0}, contactId: null});
    const [draggableContact, setDraggableContact] = useState(null)
    const [isDragging, setIsDragging] = useState(false);
    const [progressModal, setProgressModal] = useState(false);

    const [allGroups, setAllGroups] = useState([]);

    const [moveToGroupModalOpen, setMoveToGroupModalOpen] = useState(false);

    const [moveContactsState, setMoveContactsState] = useState({
        // items: [],
        original: '',
        new: ''
    });
    

    useEffect(() => {
        const groupSet = new Set();
        userContacts.forEach(contact => {
            if (contact.group && !groupSet.has(contact.group)) {
                groupSet.add(contact.group);
            }
        });
        setAllGroups([...groupSet]);
    }, [userContacts]);

    const isAnyIdSelectedInGroup = (groupName) => {
        const group = groupUsers.find(group => group.groupName === groupName);
        if (!group) return false;
        return group.contacts.some(contact => checkedContacts[contact.id]);
    };

    const areAllIdsSelectedInGroup = (groupName)=> {
        const group = groupUsers.find(group => group.groupName === groupName);
        if (!group) return false;
        const groupIds = group.contacts.map(contact => contact.id);
        return groupIds.every(id => checkedContacts[id]);
    }

    const addGroup = (newGroup) => {
        setAllGroups(prevGroups => [...prevGroups, newGroup]);
    };

    const [showContacts, setShowContacts] = useState(false);

    // Handler for show Contacts
    const handleShowContacts = () => {
        setShowSidebar(true);
        setShowContacts(true);
    };
    const handleHideContacts = () => {
        setShowSidebar(false);
        setShowContacts(false);
    };

    const updateContactsData = (newData) => {
        if (!Array.isArray(newData)) {
            newData = [newData];
        }

        setUserContacts(prevContacts => {
            const updatedContacts = [...prevContacts];

            newData.forEach(newContact => {
                const index = updatedContacts.findIndex(contact => contact.id === newContact.id);
                if (index !== -1) {
                    // update existing contact
                    updatedContacts[index] = newContact;
                } else {
                    //or add new one
                    updatedContacts.push(newContact);
                }
            });

            // update groups
            const groups = {};
            let noGroupContacts = [];

            updatedContacts.forEach(contact => {
                if (contact.group) {
                    const groupName = contact.group;
                    groups[groupName] = groups[groupName] || [];
                    groups[groupName].push(contact);
                } else {
                    noGroupContacts.push(contact);
                }
            });

            setGroupUsers(Object.keys(groups).map(groupName => ({ groupName, contacts: groups[groupName] })));
            setNoGroupUsers(noGroupContacts);

            return updatedContacts;
        });
    };



    const onAction = (contact, action, value) => {
        console.log("onAction", action, contact, value);
    
        const actions = {
            delete: () => setAgreeModalMassDelete(true),
            select: () => {
                const updatedCheckedContacts = { ...checkedContacts, [contact.id]: value };
                if (!value) {
                    delete updatedCheckedContacts[contact.id];
                }
                const filteredCheckedContacts = Object.fromEntries(
                    Object.entries(updatedCheckedContacts).filter(([_, val]) => val)
                );
                setCheckedContacts(filteredCheckedContacts);
            },
            selectall: () => {
                const newChecked = {};
                if (!allContactsSelected) {
                    userContacts.forEach(contact => newChecked[contact.id] = true);
                }
                setCheckedContacts(newChecked);
            },
            openMovePopup: () => {
                const selectedContacts = userContacts.filter(contact => checkedContacts[contact.id]);
                const originalGroupNames = [...new Set(selectedContacts.map(contact => contact.group))];
                const originalGroupName = originalGroupNames.length === 1 ? originalGroupNames[0] : '';
            
                setMoveContactsState({
                    // items: selectedContacts,
                    original: originalGroupName,
                    new: ''
                });
                setMoveToGroupModalOpen(true);
            },
            doMove: (updatedState) => {
                doMoveContactsToGroup(updatedState.new);
            }
        };
    
        const selectedAction = actions[action];
        if (selectedAction) {
            selectedAction(value);
        }
    };
    
    // make groups structure similar to locations
    const modifiedGroups = groupUsers.map(group => ({
        group: group.groupName, 
        // items: group.contacts.map(contact => ({
        //     ...contact,
        //     label: contact.name,
        //     group: group.groupName             
        // }))
    }));


    const doMoveContactsToGroup = async (newGroupName) => {
        const contactsToUpdate = userContacts.filter(contact => checkedContacts[contact.id]);
    
        for (let contact of contactsToUpdate) {
            const updatedContact = { ...contact, group: newGroupName }; // Set new group name
            try {
                await api.put(`/contacts/${contact.id}`, updatedContact);
                // Update local contacts
                updateContactsData(updatedContact);
            } catch (error) {
                console.error('Error updating contact group:', error);
            }
        }
    
        // Clear selection and any modal states after moving
        setCheckedContacts({});
        setMoveToGroupModalOpen(false);
    };
    


    const doDeleteUserGroup = async (agreed) => {
        if (agreed && deleteUserGroup) {
            const groupName = deleteUserGroup.group;
            const contactsToUpdate = userContacts.filter(contact => contact.group === groupName);

            for (let contact of contactsToUpdate) {
                const updatedContact = { ...contact, group: null }; // set group to null
                try {
                    await api.put(`/contacts/${contact.id}`, updatedContact);
                    // update local contacts
                    updateContactsData(updatedContact);
                } catch (error) {
                    console.error('Error updating contact:', error);
                }
            }
        }
        setDeleteUserGroup(false);
    }

    const handleDeleteGroup = (groupName) => {
        setDeleteUserGroup({ group: groupName });
    }
    const deleteUserGroupMessage = (
        <span>
          Are you sure you want delete <span style={{fontWeight:"500", color:"var(--palette-grey-900)"}}>{deleteUserGroup.group}</span> user group?
          <br />
          All users and contacts will remain in the system.
        </span>
    );

    const handleEditGroup = (groupName) => {
        setEditUserGroup({ current: groupName, newName: groupName });
    };



    const saveGroupName = async () => {
        if (editUserGroup && editUserGroup.current) {
            const oldGroupName = editUserGroup.current;
            const newGroupName = editUserGroup.newName;
            const contactsToUpdate = userContacts.filter(contact => contact.group === oldGroupName);


            for (let contact of contactsToUpdate) {
                const updatedContact = { ...contact, group: newGroupName };
                try {
                    await api.put(`/contacts/${contact.id}`, updatedContact);
                    // update local contact
                    updateContactsData(updatedContact);
                } catch (error) {
                    console.error('Error updating contact group name:', error);
                }
            }

            // Set state of modal to close it
            setEditUserGroup({ current: '', newName: '' });
        }
    };


    const loadUserContacts = async () => {
        try {
            const response = await api.get('/contacts');
            const contacts = response.data;

            if (!Array.isArray(contacts)) {
                throw new Error('Data is not an array');
            }

            const groups = {};
            let noGroupContacts = [];

            contacts.forEach(contact => {
                if (contact.group) {
                    const groupName = contact.group;
                    groups[groupName] = groups[groupName] || [];
                    groups[groupName].push(contact);
                } else {
                    noGroupContacts.push(contact);
                }
            });

            setUserContacts(contacts);
            setGroupUsers(Object.keys(groups).sort().map(groupName => ({ groupName, contacts: groups[groupName] })));
            setNoGroupUsers(noGroupContacts);
        } catch (error) {
            console.error('Error fetching contacts:', error);
        }
    };

    useEffect(() => {
        loadUserContacts();
    }, []);

    const filteredNoGroupContacts = noGroupUsers.filter(contact =>
        ((filterSubscription === 'All') || (contact.status === filterSubscription)) &&
        ((filterSearchUsers === '') ||
        (contact.name.toLowerCase().includes(filterSearchUsers.toLowerCase())) ||
        (contact.email.toLowerCase().includes(filterSearchUsers.toLowerCase())) ||
        (contact.phone && contact.phone.toString().toLowerCase().includes(filterSearchUsers.toLowerCase())))
    );

    const handleGroupCheckboxChange = (isChecked, groupName) => {
        const newCheckedContacts = { ...checkedContacts };
        groupUsers.find(group => group.groupName === groupName)?.contacts.forEach(contact => {
            newCheckedContacts[contact.id] = isChecked;
        });
        if (Object.values(newCheckedContacts).every(value => value === false)) {
            setCheckedContacts({});
        } else {
            setCheckedContacts(newCheckedContacts);
        }
    };

    const massDeleteContacts = async (agree) => {
        setAgreeModalMassDelete(true);
        if (agree) {
            setProgressModal(true);

            const deletePromises = Object.keys(checkedContacts)
                .filter(id => checkedContacts[id] === true)
                .map(async (id) => {
                    try {
                        await api.delete(`/contacts/${id}`);
                    } catch (error) {
                        console.error('Error deleting contact:', error);
                    }
                });

            await Promise.all(deletePromises);
            await loadUserContacts();

            setCheckedContacts({});
            setProgressModal(false);
        }
        setAgreeModalMassDelete(false);
    };

    const handleDrag = useCallback((event, data, contact) => {
        if (!isDragging) return;
        setDragPosition({ position: data, contactId: contact.id });
        setDraggableContact(contact);
    }, [isDragging, setDragPosition, setDraggableContact]);

    const stopDrag = () => {
        setDragPosition({position: {x:0, y:0}, contactId:null});
        setIsDragging(false);
        setTimeout(()=> setDraggableContact(null),1);
    }

    const handleDrop = (groupName)=>{
        console.log("draggableContact", draggableContact)
        console.log("groupName", groupName)
        if(!draggableContact)return
        const newData = {
            'name': draggableContact?.name,
            'phone': draggableContact?.phone,
            'email': draggableContact?.email,
            'group': groupName || "",
        }
        const contactId = draggableContact?.id;
        updateDraggableContact(contactId, newData);
        setDraggableContact(null);
        setIsDragging(false);
    }

    const updateDraggableContact = async (contactId, data)=>{
        await api.put(`/contacts/${contactId}`, data)
            .then((response) => {
                console.log("response", response)
                console.log("Group contact updated")
            })
            .catch(error => {
                console.error('Error group contact updating:', error);
            });
        await loadUserContacts();
    }

    return (
        <Box className={'settings'}>
            {showContacts ? (
                <Contacts
                    contacts={userContacts}
                    groups={groupUsers}
                    onHideContacts={handleHideContacts}
                    onUpdateContacts={updateContactsData}
                />
            ) : (<>
                <Box className={"settings-toolbar"} style={{borderBottom: "1px solid var(--palette-grey-100)"}}>
                    <h3>
                        Users and contacts
                    </h3>
                    <SearchInput
                        type='search'
                        placeholder='Search'
                        onChange={({ target }) => setFilterSearchUsers(target.value)}
                    />
                    <Select
                        placeholder={''}
                        defaultValue={filterSubscription}
                        onChange={({ target }) => setFilterSubscription(target.value)}
                        InputProps={{
                            startAdornment:
                                <div className={'paragraph'} style={{ color: theme.palette.grey[400] }}>
                                    Status:
                                </div>
                        }}
                    >
                        <MenuItem value='All'>All</MenuItem>
                        <MenuItem value='Active subscription'>Active subscription</MenuItem>
                        <MenuItem value='Invite sent'>Invite sent</MenuItem>
                        <MenuItem value='Subscription expired'>Subscription expired</MenuItem>
                        <MenuItem value='No subscription'>No subscription</MenuItem>
                    </Select>
                    {/* TODO: implement invite functionality */}
                    {/* <Button onClick={() => setOpenModalInviteUsers(true)}>Invite users</Button> */}
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={handleShowContacts}
                        sx={{marginLeft: 'auto'}}
                    >
                        Add contacts
                    </Button>
                </Box>

        
                <ItemsSelectTopBar 
                    onAction={onAction} 
                    visible={Object.keys(checkedContacts).length > 0} 
                    allSelected={allContactsSelected} 
                    indeterminate={someContactsSelected}
                    type={'usercontacts'}
                />


                {isEmpty(Object.values(userContacts))?
                    <Box sx={{width:"100%", height:"100%"}}>
                        <EmptyStateContacts title={"Users and contacts will appear here"} text={"You will see users and contacts here once they are created"}/>
                    </Box>:
                <Box className={'column gap16 fullWidth'} sx={{ padding: '16px 24px', overflowX: 'hidden', height:"100%", position:"relative", borderTop: '1px solid var(--palette-grey-100)'}}>
                    {groupUsers.map(({ groupName, contacts }) => {
                        let filteredContacts = contacts
                            .filter(c => ((filterSubscription === 'All') || (c.status === filterSubscription)))
                            .filter(c => (
                                (filterSearchUsers === '') ||
                                (c.name.toLowerCase().indexOf(filterSearchUsers.toLowerCase()) > -1) ||
                                (c.email.toLowerCase().indexOf(filterSearchUsers.toLowerCase()) > -1) ||
                                (c.phone && c.phone.toString().toLowerCase().indexOf(filterSearchUsers.toLowerCase()) > -1)
                            ));

                        return (
                            <>
                                {contacts.some(contact => filterSearchUsers === '' ||
                                        (contact.name.toLowerCase().indexOf(filterSearchUsers.toLowerCase()) > -1) ||
                                        (contact.email.toLowerCase().indexOf(filterSearchUsers.toLowerCase()) > -1) ||
                                        (contact.phone && contact.phone.toString().toLowerCase().indexOf(filterSearchUsers.toLowerCase()) > -1)) &&
                                    <Accordion key={groupName} onMouseUp={()=> handleDrop(groupName)}>
                                        <AccordionSummaryUserGroup
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Box className={'row gap8'}>
                                                <CheckBox
                                                    id='aria-label'
                                                    onClick={(e) => e.stopPropagation()}
                                                    checked={areAllIdsSelectedInGroup(groupName)}
                                                    onChange={(e) => handleGroupCheckboxChange(e.target.checked, groupName)}
                                                    indeterminate={isAnyIdSelectedInGroup(groupName) && !areAllIdsSelectedInGroup(groupName)}
                                                />
                                                <Typography>
                                                    {groupName}
                                                </Typography>
                                            </Box>
                                        </AccordionSummaryUserGroup>
                                        <AccordionDetailsUserGroup>
                                            <Box className={'column gap8 fullWidth'}>
                                                {filteredContacts.map(contact => (
                                                    <DraggableCore
                                                        onDrag={(e, data)=> handleDrag(e, data, contact)}
                                                        onStop={() => stopDrag()}
                                                    >
                                                        <Box sx={contact.id === dragPosition.contactId && isDragging ? {
                                                            position:"absolute",
                                                            pointerEvents:"none",
                                                            width:"100%",
                                                            zIndex:"1",
                                                            transform:`translate(0px, ${dragPosition.position.y}px)`, left:"15px", top:"-20px"}:undefined}>
                                                            <DraggableContactLine
                                                                key={contact.id}
                                                                contact={contact}
                                                                onCheckedUser={onAction}
                                                                isChecked={checkedContacts && checkedContacts[contact.id]}
                                                                loadUserContacts={loadUserContacts}
                                                                onUpdateContact={updateContactsData}
                                                                allGroups={allGroups}
                                                                addGroup={addGroup}
                                                                onDrag={()=> {setIsDragging(true)}}
                                                            />
                                                        </Box>
                                                    </DraggableCore>
                                                ))}
                                            </Box>
                                            <Box className={'row gap8'} sx={{ justifyContent: 'flex-end',}}>
                                                <Button
                                                    size={'small'}
                                                    variant={'outlined'}
                                                    onClick={() => handleEditGroup(groupName)}
                                                    startIcon={<PenIcon size={'small'}/>}
                                                >
                                                    Edit group name
                                                </Button>
                                                <Button
                                                    size={'small'}
                                                    variant={'outlined'}
                                                    color={'error'}
                                                    onClick={() => handleDeleteGroup(groupName)}
                                                    startIcon={<TrashIcon size={'small'}/>}
                                                >
                                                    Delete group
                                                </Button>
                                            </Box>
                                        </AccordionDetailsUserGroup>
                                    </Accordion>
                                }
                            </>
                        )
                    })}

                    <Box className={'column gap8 fullWidth'} sx={{minWidth: '800px', minHeight:"100%"}} onMouseUp={()=> handleDrop()}>
                        {filteredNoGroupContacts.map(contact => (
                            <DraggableCore
                                onDrag={(e, data)=> handleDrag(e, data, contact)}
                                onStop={() => stopDrag()}
                            >
                                <Box sx={contact.id === dragPosition.contactId && isDragging ? {
                                    position:"absolute",
                                    pointerEvents:"none",
                                    width:"100%",
                                    zIndex:"1",
                                    transform:`translate(0px, ${dragPosition.position.y}px)`, left:"20px", top:"-20px"}:undefined}>
                                    <DraggableContactLine
                                        key={contact.id}
                                        contact={contact}
                                        onCheckedUser={onAction}
                                        isChecked={checkedContacts && checkedContacts[contact.id]}
                                        loadUserContacts={loadUserContacts}
                                        onUpdateContact = {updateContactsData}
                                        allGroups={allGroups}
                                        addGroup={addGroup}
                                        onDrag={()=> setIsDragging(true)}
                                    />
                                </Box>
                            </DraggableCore>
                        ))}
                    </Box>
                </Box>}

                {deleteUserGroup &&
                    <AgreeModal
                        data={{
                            message: deleteUserGroupMessage,
                            title: "Delete user group",
                            agreeMsg: "Delete",
                            mode: "deleting",
                            agreeFunc: doDeleteUserGroup,
                        }}
                    />
                }
                {moveToGroupModalOpen && (
                    <EntityGroupSelectorPopup
                        moveToGroupState={moveContactsState}
                        onChange={(updatedState) => {
                            if(updatedState !== false) {
                                console.log("Updated State", updatedState);
                                onAction(undefined, 'doMove', updatedState);
                            }
                            setMoveToGroupModalOpen(false);
                        }}
                        groups={modifiedGroups}
                    />
                )}


                <ModalComponent visible={!!editUserGroup.current}>
                    <Box sx={{ width: '520px', display: 'flex', padding: '24px', flexDirection: 'column', alignItems: 'flex-start', gap: '24px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', alignSelf: 'stretch' }}>
                            <Typography variant='h5'>Edit user group</Typography>
                            <IconButton onClick={() => setEditUserGroup(false)}>
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                        <Input
                            sx={{ width: '452px', gap: '8px', padding: '8px 8px 8px 12px' }}
                            type='text'
                            placeholder='User group name'
                            value={editUserGroup.newName}
                            onChange={(e) => setEditUserGroup({ ...editUserGroup, newName: e.target.value })}
                        />
                        <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '16px', alignSelf: 'stretch' }}>
                            <Button sx={{ width: '228px' }} onClick={() => setEditUserGroup(false)} >Cancel</Button>
                            <Button sx={{ width: '228px' }} onClick={saveGroupName}>Save</Button>
                        </Box>
                    </Box>
                </ModalComponent>
                {agreeModalMassDelete &&
                <AgreeModal
                    data={{
                        message: <Box className='column' sx={{alignContent: 'stretch', overflow: "hidden", "&.MuiBox-root": {width:"100%"}}}>
                            <Typography sx={{fontSize: "18px"}}>{(Object.keys(checkedContacts).length === 1) ? "Are you sure you want to delete this contact?" : "Are you sure you want to delete these contacts?"}</Typography>
                            <Box sx={{maxHeight: "200px", overflowY: "auto"}}>{contactsToDelete.map(contact => <span style={{fontWeight:"500", color:"var(--palette-grey-900)"}}>{contact.name}<br/></span>)}</Box>
                        </Box>,
                        title: "Delete contacts",
                        agreeMsg: "Delete",
                        mode: "deleting",
                        agreeFunc: massDeleteContacts
                    }}
                />}
            </>)}
            <ProgressModal visible={progressModal}/>
        </Box>
    )
}
