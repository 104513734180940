import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from "@mui/material";
import React, { useEffect, useState } from "react";
import theme from "app/Theme";
import MapPinIcon from "shared/assets/icons/MapPin";
import { CheckBox } from "shared/ui/CheckBox";
import {Spacer} from "shared/ui/Spacer";

const KEYS = {
    products: {
        groupKey: "product_group",
        nameLine: "name"
    },
    locations: {
        groupKey: "location_group",
        nameLine: "label"
    }
}

const _grouping = (objects, FIELDS) => {
    let g = {};
    objects && objects.forEach(obj => {
        g[obj[FIELDS.groupKey]] ?
            g[obj[FIELDS.groupKey]].push(obj) :
            g[obj[FIELDS.groupKey]] = [obj];
    });
    return g;
}

export function GroupedFilter(
    {
        objects,
        activeIds = [],
        onChange,
        typeFilter = "products",
        isUngrouped = false,
        disableGroupCheckbox = false
    }) {

    const [groups, setGroups] = useState({});
    const [countOfSelectedLayers, setCountOfSelectedLayers] = useState(activeIds.length);
    const FIELDS = (typeFilter === "locations") ? KEYS.locations : KEYS.products;

    useEffect(() => {
        setGroups(_grouping(objects, FIELDS));
    }, [objects]);

    useEffect(() => {
        setCountOfSelectedLayers(activeIds.length);
    }, [activeIds]);

    const handleChangeActiveID = (event, objectID) =>{
        const isChecked = activeIds.includes(objectID);
        if (!isChecked) {
            if(activeIds.length < 5){
                setCountOfSelectedLayers(prevCount => prevCount + 1);
                onChange([...activeIds, objectID]);
            }
        } else {
            setCountOfSelectedLayers(prevCount => prevCount - 1);
            onChange(activeIds.filter(id => (objectID !== id)));
        }
    }

    const _line = (obj) => {
        return (
            <Box className={'row gap4 fullWidth'} sx={{ paddingLeft: '24px', paddingRight: '8px'}} key={`${FIELDS.nameLine}${obj.id}`}>
                <CheckBox
                    checked={activeIds.includes(obj.id)}
                    onClick={(e) => handleChangeActiveID(e, obj.id)}
                />
                <Typography
                    onClick={(e) => handleChangeActiveID(e, obj.id)}
                    style={{cursor: "pointer"}}
                >
                    {obj[FIELDS.nameLine]}
                </Typography>
                {typeFilter === 'locations' &&
                    // use obj.geometry_type
                    <>
                        <Spacer/>
                        <MapPinIcon size={'small'}/>
                    </>
                }
            </Box>
        )
    }

    if (isUngrouped) {
        return (Object.keys(groups).map(groupName => {
            return groups[groupName].map(_line);
        }));
    }

    const _groupLines = (groupName) => {
        const items = groups[groupName].map(_line);
        return (
            <AccordionDetails variant={'dark'}>
                {items}
            </AccordionDetails>
        )
    }

    return (
        <>
            {typeFilter === "products" &&
                <Box style={{minHeight:"52px", color:"var(--palette-grey-500)", padding:"10px 16px", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center"}}>
                    <Box style={{fontSize: "14px", fontWeight:"400"}}>
                        Select up to 5 items
                    </Box>
                    <Box style={{fontSize: "12px", fontWeight:"500", backgroundColor:"white", height:"32px", width:"32px", borderRadius:"8px", display:"flex", alignItems:"center", justifyContent:"center"}}>
                        {countOfSelectedLayers}/5
                    </Box>
                </Box>
            }
            <Box style={{overflow:"auto", display:"flex", flexDirection:"column", gap:"8px"}}>
                {Object.keys(groups).map(groupName => {
                    const group = groups[groupName];
                    return (
                        <Accordion
                            variant={'dark'}
                            defaultExpanded
                            key={`${typeFilter}${groupName}`}
                        >
                            <AccordionSummary variant={'dark'} sx={{ height: '32px' }}>
                                <Box className={'row gap4 fullWidth'}>
                                    {
                                        (disableGroupCheckbox === true) ?
                                            <CheckBox sx={{ display: 'none' }} />
                                            :
                                            <CheckBox
                                                checked={group.some(el => activeIds.includes(el.id))}
                                                indeterminate={group.some(el => !activeIds.includes(el.id)) && group.some(el => activeIds.includes(el.id))}
                                                onClick={(event) => {
                                                    if (group.some(el => !activeIds.includes(el.id))) {
                                                        group.forEach(obj => {
                                                            if (!activeIds.includes(obj.id)) {
                                                                activeIds.push(obj.id);
                                                            }
                                                        });
                                                        onChange([...activeIds]);
                                                    } else {
                                                        onChange(activeIds.filter(activeId => !group.some(el => el.id === activeId)));
                                                    }
                                                    event.stopPropagation();
                                                }}
                                            />}
                                    <Typography style={{ color: theme.palette.grey[900] }}>
                                        {`${groupName} (${group.length})`}
                                    </Typography>
                                </Box>
                            </AccordionSummary>
                            {_groupLines(groupName)}
                        </Accordion>
                    )
                })}
            </Box>
        </>
    )
}