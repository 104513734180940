import React, { useEffect } from 'react';
import './MapLibreLocation.css';
import './style.css';
import 'maplibre-gl/dist/maplibre-gl.css'

import requester from '../../../../shared/libs/requester/baron-signature.js';

//const maplibregl = require('maplibre-gl');
// eslint-disable-next-line import/no-webpack-loader-syntax
import maplibregl from '!maplibre-gl';      // ! is important here
import maplibreglWorker from 'maplibre-gl/dist/maplibre-gl-csp-worker';

maplibregl.workerClass = maplibreglWorker;

function _transformRequestMutator(url, type) {
    if ((url.indexOf('cdnmaps.velocityweather') > -1) || (url.indexOf('vmaps.velocityweather.com') > -1)) {
        const headers = {
            'X-VW-key': requester.getKey(),
            'X-VW-sig': requester.sign.sig,
            'X-VW-ts': requester.sign.ts
        }
        return { url, type, headers };
    }
}

let marker = new maplibregl.Marker();
let map;
let markersForLocations = {};
let markersForSelectedLocations = {};

const defaultZoom = 4;

export const MapLibreLocationStatic = ({onChange, coordinates, editMode, locations, selectedLocations, chosenCoords, mapPositionRef}) => {
    // console.log("MapLibreLocationStatic.selectedLocations", selectedLocations);

    useEffect(() => {
        requester.isready(() => {
            componentDidMount();
        });
        return () => {
            if (map && mapPositionRef) {
                map.off('zoomend');
                map.off('moveend');
            }
        };
    }, [])

    useEffect(()=>{
        requester.isready(() => setTimeout(() => {
            coordinates && marker.setLngLat(coordinates);
            coordinates && map.panTo(coordinates);
        }, 10));
    },[coordinates]);

    useEffect(()=>{
        requester.isready(() => setTimeout(() => {
            if (editMode===1 && map){
                if (chosenCoords) {
                    marker.setLngLat(coordinates).addTo(map);
                }
                else marker.remove();
                map.on('click', onClickMap);
            }else{
                marker.remove();
                map.off('click', onClickMap);
            }
        }, 100));
    }, [editMode, chosenCoords]);

    useEffect(() => {
        requester.isready(() => {
            let mm = {};
            for (let x in locations) {
                let loc = locations[x];
                let id = loc.id;
                let coords = loc.coordinates;

                if (!selectedLocations.some((selectedLoc) => selectedLoc.id === id)) {
                    let marker = new maplibregl.Marker({color: 'grey', scale: 0.5});
                    marker.setLngLat(coords).addTo(map);
                    mm[id] = marker;
                    mm[id].getElement().setAttribute("title", loc.label);
                }
            }
            markersForLocations = mm;
        });
    }, [locations]);

    useEffect(() => {
        requester.isready(() => setTimeout(() => {
            let mm = {};
            for (let x in selectedLocations) {
                let loc = selectedLocations[x];
                let id = loc.id;
                let coords = loc.coordinates;
                let existed = markersForSelectedLocations[id];
                if (existed) {
                    mm[id] = existed;
                    existed.setLngLat(coords)
                    delete markersForSelectedLocations[id];
                }
                else {
                    let marker = new maplibregl.Marker({color: 'red', scale: 0.5});
                    marker.setLngLat(coords).addTo(map);
                    mm[id] = marker;
                }
                mm[id].getElement().setAttribute("title", loc.label);
            }
            for (let x in markersForSelectedLocations) {
                markersForSelectedLocations[x].remove();
            }
            markersForSelectedLocations = mm;
        }, 10));
    }, [selectedLocations]);

    function onClickMap(event){
        onChange(event.lngLat.toArray());
    }

    function componentDidMount() {
        console.log("MapLibreLocation.componentDidMount");
        markersForSelectedLocations = {};
        map = new maplibregl.Map({
            container: 'map',
            style: 'https://cdnmaps.velocityweather.com/styles/planet-full/style.json',
            hash: false,
            transformRequest: _transformRequestMutator,
            minZoom: 2,
            zoom:  mapPositionRef?.current.zoom || defaultZoom,
        });
        map.setCenter(coordinates);

        map.dragRotate.disable();
        map.touchZoomRotate.disableRotation();

        if(mapPositionRef) {
            map.on('zoomend', (e) => {
                mapPositionRef.current.zoom = e.target.getZoom();
            });
            map.on('moveend', () => {
                mapPositionRef.current.position = map.getCenter();
            });
        }

        map.addControl(new maplibregl.NavigationControl({
            showCompass: false
        }), 'bottom-left');
    }

    return (
        <div style={{width: '100%', 'height': '100%'}}>
            <div data-cy={'map'} id="map" style={{borderRadius: '8px'}}></div>
        </div>
    );

};
