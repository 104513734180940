import {Box, Chip, IconButton, MenuItem, Paper, Typography} from '@mui/material'
import Button from '@mui/material/Button'
import {styled} from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import {fetchWeatherIcons, postWeatherDefinition, putWeatherDefinition} from 'pages/alerts/api/FetchAlerts'
import {Select} from 'shared/ui/Select'
import {Spacer} from 'shared/ui/Spacer'
import 'widgets/weatherDefinition/definitionRender/DefinitionRenderPreview.css'
import CheckIcon from 'shared/assets/icons/Check'
import PlusIcon from 'shared/assets/icons/Plus'
import ArrowLeftSmallIcon from 'shared/assets/icons/ArrowLeftSmall'
import React, {useEffect, useState} from 'react'
import theme from 'app/Theme'
import {productsDict} from '../../entities/weatherDefinition/api/Products'
import {WeatherDefinitionGroupCreator} from './WeatherDefinitionGroupCreator'
import AgreeModal from 'shared/ui/AgreeModal'
import {DefinitionRenderPreview} from './definitionRender/DefinitionRenderPreview'
import DefinitionConfirmationModal from '../../shared/ui/DefinitionConfirmationModal'

const DICTIONARY_UNIONS = {
    AND: 'AND',
    OR: 'OR',
}

const IconMenuItem = styled(MenuItem)(() => ({
    width: '24px',
    height: '24px',
    justifyContent: 'center',
    padding: 0,
    border: '1px solid',
    borderRadius: '4px',
    borderColor: theme.palette.grey[100],
    background: theme.palette.grey.background,
}))

const ChipMenuItem = styled(MenuItem)(() => ({
    height: 'max-content',
    ':hover': {
        background: 'none',
    },
}))

/**
 * @param defaultValue
 * @param {number | undefined} defaultValue.icon - The icon id
 * @param onClose
 * @param onSave
 * @param products
 */
export default function NewWeatherDefinitionPage({defaultValue = {}, onClose, onSave =()=>{}, products = []}) {
    const [groups, setGroups] = useState(defaultValue.groups || [createNewGroup()])
    const [name, setName] = useState(defaultValue.name || '')
    const [severity, setSeverity] = useState(defaultValue.severity || '')
    const [definition, setDefinition] = useState({})
    const [icons, setIcons] = useState([])
    const [icon, setIcon] = useState({})
    // const [id, setId] = useState(defaultValue.id || -1);
    const [disableSave, setDisableSave] = useState(false)
    const id = defaultValue.id || -1
    const [showDisagreeModal, setShowDisagreeModal] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)

    useEffect(() => {
        if (name === '' || severity === '' || !validateGroups()) {
            setDisableSave(true)
        } else {
            setDisableSave(false)
        }
        setDefinition({
            groups,
            name,
            severity,
            icon: icon.id,
            id,
        })
    }, [groups, name, severity, icon])

    const validateGroups = () => {
        let state = 'empty'
        groups.forEach((group) => {
            group.lines.forEach((line) => {
                if (!line.product_name || state === 'invalid') return
                if (!line.values.length) {
                    state = 'invalid'
                    return
                }
                if (line.values[0] === '') {
                    state = 'invalid'
                    return
                }
                if (line.relation.indexOf('><') >= 0 && (line.values.length !== 2 || !line.values[1])) {
                    state = 'invalid'
                    return
                }
                const product = productsDict[line.product_name]
                if (!product) { // wl-326
                    state = 'invalid'
                    return
                }
                if (product.result_type !== 'numeric') {
                    if (line.values[0] === 'none' && line.relation === '=') {
                        state = 'invalid'
                        return
                    }
                    if (product.allowed_text_values[0] === 'none' && line.values[0]) { // wl-294
                        if (line.values[0] === 'none' && (line.relation === '<=' || line.relation === '>=')) {
                            state = 'invalid'
                            return
                        }
                        if (product.allowed_text_values.indexOf(line.values[0]) <= 1 && line.relation === '<') {
                            state = 'invalid'
                            return
                        }
                        if (product.allowed_text_values.indexOf(line.values[0]) === product.allowed_text_values.length - 1 && line.relation === '>') {
                            state = 'invalid'
                            return
                        }
                        if (line.relation === '><' && (line.values[0] === 'none' || line.values[1] === 'none')) {
                            state = 'invalid'
                            return
                        }
                    }
                    if (state !== 'invalid' && product.result_type !== 'time') state = 'valid'
                    return
                }
                if (isNaN(parseFloat(line.values[0]))) {
                    state = 'invalid'
                    return
                }
                if (line.values.length === 2 && isNaN(parseFloat(line.values[1]))) {
                    state = 'invalid'
                    return
                }
                if (state !== 'invalid') state = 'valid'
            })
        })
        return (state === 'valid')
    }

    useEffect(() => {
        fetchWeatherIcons().then((data) => {
            const iconsWithNames = data.map((icon) => {
                const formatedName = icon.url.replace('_', ' ').split('/').pop().split('.')[0]
                const name = formatedName.charAt(0).toUpperCase() + formatedName.slice(1)
                return {...icon, name}
            })
            setIcons(iconsWithNames)
            if (defaultValue.icon) {
                setIcon(iconsWithNames.find((icon) => icon.id === defaultValue.icon))
            }
        })
    }, [])

    const handleChangeSeverity = (event) => {
        setSeverity(event.target.value)
    }

    function addNewGroup() {
        setGroups([...groups, createNewGroup()])
    }

    function createNewGroup() {
        return {
            lines: [],
            union: 'AND',
        }
    }

    const removeGroup = (g) => {
        const newGroups = []
        groups.forEach((group) => {
            if (group !== g) newGroups.push(group)
        })
        setGroups(newGroups)
    }

    function saveWeatherDefinitions() {
        setDisableSave(true)
        if (id === -1) // new definition
        {
            postWeatherDefinition(definition).then((data) => {
                if(data.success){
                    definition.id = data.id
                }
                onSave(definition)
                if (!data.error) {
                    onClose(true)
                }
            })
        } else {
            putWeatherDefinition(definition).then((data) => {
                if (!data.error) {
                    onClose(true)
                }
            })
        }
    }

    const unionSelectOptions = []
    for (const key in DICTIONARY_UNIONS) {
        unionSelectOptions.push(<MenuItem
            key={key}
            value={key}
                                >
            {DICTIONARY_UNIONS[key]}
        </MenuItem>)
    }

    useEffect(() => {
        const handleWindowClose = (e) => {
            e.preventDefault()
        }
        window.addEventListener('beforeunload', handleWindowClose)
        return () => {
            window.removeEventListener('beforeunload', handleWindowClose)
        }
    }, [])

    const handleModalClose = (agreed, action) => {
        if (action === 'disagree') {
            if (agreed) {
                onClose()
            }
            setShowDisagreeModal(false)
        } else if (action === 'success') {
            if (agreed) {
                saveWeatherDefinitions()
            }
            setShowSuccessModal(false)
        }
    }
    const handleGoToSettings = (agreed) => {
        if (agreed) {
            saveWeatherDefinitions()
        }
        setShowSuccessModal(false)
        window.location.href = '/settings'
    }
    return (
        <Box
            className={'column fullWidth NewWeatherDefinitionPage'}
            sx={{
                background: '#f0f2f5',
                flexGrow: '1',
                height: '100%',
                alignItems: 'start',
            }}
        >
            <Box
                className={'toolbar'}
                style={{padding: '24px 24px 16px 24px'}}
            >
                <IconButton
                    onClick={() => setShowDisagreeModal(true)}
                    variant={'outlined'}
                >
                    <ArrowLeftSmallIcon size={'small'}/>
                </IconButton>
                <h3>
                    {id === -1 ? 'New' : 'Edit'}
                    {' '}
weather definition
                </h3>
                <Spacer/>
                <Button
                    disabled={disableSave}
                    onClick={() => setShowSuccessModal(true)}
                >
                    Save weather definition
                </Button>
            </Box>
            <Box
                className={'row gap16 fullWidth'}
                sx={{
                    padding: '6px 24px',
                    alignItems: 'start',
                    alignContent: 'start',
                    overflowY: 'auto',
                    height: '100%',
                    flexWrap: 'wrap',
                }}
            >
                <Paper
                    className={'column gap16'}
                    sx={{padding: '24px', flexGrow: 1, minWidth: '500px', minHeight: '224px'}}
                >
                    <Box className={'row fullWidth'}>
                        <Typography
                            variant={'label'}
                            sx={{flexGrow: 1}}
                        >
                            Title
                            <TextField
                                placeholder='Enter weather definition title'
                                value={name}
                                onChange={(event) => setName(event.target.value)}
                            />
                        </Typography>
                        <Typography variant={'label'}>
                            Icon
                            <Select
                                defaultValue={icon}
                                value={icon}
                                placeholder={''}
                                style={{width: '180px'}}
                                renderValue={(icon) => (
                                    <Box style={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                                        <Box
                                            className={'row iconBackground'}
                                            sx={{width: '24px', height: '24px', justifyContent: 'center', border: '1px solid var(--palette-grey-100)', borderRadius: '4px', backgroundColor: 'var(--palette-grey-background)'}}
                                        >
                                            <svg
                                                className={'customSvg'}
                                                style={{fill: 'var(--palette-primary-dark)'}}
                                            >
                                                <use
                                                    height="16"
                                                    width="16"
                                                    href={`${icon.url}#svg2`}
                                                />
                                            </svg>
                                        </Box>
                                        <Box style={{color: icon.name ? 'var(--palette-primary-dark)' : 'var(--palette-grey-300)', fontSize: '14px', fontWeight: '400'}}>
                                            {icon.name || 'Select icon'}
                                        </Box>
                                    </Box>
                                )}
                                MenuProps={{
                                    sx: {
                                        '& ul': {
                                            padding: 0,
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(7, auto)',
                                        },
                                    },
                                }}
                            >
                                {icons.map((iconItem) => (
                                    <IconMenuItem
                                        key={`icon_${iconItem.id}`}
                                        value={iconItem.id}
                                        onClick={() => setIcon({...iconItem})}
                                    >
                                        <svg
                                            className={'customSvg'}
                                            style={icon.id === iconItem.id ? {fill: theme.palette.info.dark} : {}}
                                        >
                                            <use
                                                height="16"
                                                width="16"
                                                href={`${iconItem.url}#svg2`}
                                            />
                                        </svg>
                                    </IconMenuItem>
                                ))}
                            </Select>
                        </Typography>
                    </Box>
                    <Typography variant={'label'}>
                        Severity
                        <Select
                            defaultValue={severity}
                            onChange={handleChangeSeverity}
                            renderValue={(value) => (
                                <Chip
                                    label={value}
                                    variant={theme.palette.weather.variant[value]}
                                    size={'small'}
                                />
                            )}
                            sx={{width: 'max-content'}}
                        >
                            <MenuItem
                                key={'Critical'}
                                value={'Critical'}
                                component={({onClick}) => (
                                    <Chip
                                        onClick={onClick}
                                        label={'Critical'}
                                        size={'small'}
                                        icon={severity === 'Critical' && <CheckIcon size={'small'}/>}
                                        variant={theme.palette.weather.variant.Critical}
                                    />
                                )}
                            />
                            <MenuItem
                                key={'Severe'}
                                value={'Severe'}
                                component={({onClick}) => (
                                    <Chip
                                        onClick={onClick}
                                        label={'Severe'}
                                        size={'small'}
                                        icon={severity === 'Severe' && <CheckIcon size={'small'}/>}
                                        variant={theme.palette.weather.variant.Severe}
                                    />
                                )}
                            />
                            <MenuItem
                                key={'Moderate'}
                                value={'Moderate'}
                                component={({onClick}) => (
                                    <Chip
                                        onClick={onClick}
                                        label={'Moderate'}
                                        size={'small'}
                                        icon={severity === 'Moderate' && <CheckIcon size={'small'}/>}
                                        variant={theme.palette.weather.variant.Moderate}
                                    />
                                )}
                            />
                        </Select>
                    </Typography>
                </Paper>
                <Box style={{width: '40%', height:"225px", overflowY:"auto"}}>
                    <DefinitionRenderPreview definition={definition}/>
                </Box>
                <Paper
                    className={'column gap24 fullWidth'}
                    sx={{padding: '24px', overflowY: 'auto', height: 'auto'}}
                >
                    {groups.map((group, i) => (
                        <Box
                            className={'column gap24 fullWidth'}
                            key={i}
                            sx={{borderRadius: '12px'}}
                        >
                            {(!!i)
                                && <Select
                                    value={group.union}
                                    onChange={({target}) => {
                                        group.union = target.value
                                        setGroups([...groups])
                                    }}
                                    sx={{width: 'max-content'}}
                                >
                                    {unionSelectOptions}
                                </Select>
                            }
                            <Paper
                                className={'column fullWidth'}
                                key={i}
                                sx={{
                                    padding: 0,
                                    background: theme.palette.grey.background,
                                }}
                            >
                                <WeatherDefinitionGroupCreator
                                    defaultValue={group.lines}
                                    onChange={(newGroup) => {
                                        group.lines = newGroup
                                        setGroups([...groups])
                                    }}
                                    onRemove={() => removeGroup(group)}
                                />
                            </Paper>
                        </Box>
                    ),
                    )}
                    <Button
                        data-cy={'new-parameter-group-button'}
                        variant={'outlined'}
                        onClick={addNewGroup}
                        startIcon={<PlusIcon/>}
                        sx={{width: 'max-content'}}
                    >
                        New Parameter Group
                    </Button>
                </Paper>
            </Box>
            {showDisagreeModal && (
                <AgreeModal data={{
                    agreeFunc: (agreed) => handleModalClose(agreed, 'disagree'),
                    title: 'Discard weather definition',
                    message: 'Are you sure you want discard your changes?',
                    agreeMsg: 'Discard',
                    disAgreeMsg: 'Go back',
                }}
                />
            )}
            {showSuccessModal && (
                <DefinitionConfirmationModal
                    definition={definition}
                    agreeFunc={(agreed) => handleModalClose(agreed, 'success')}
                    goToSettingsFunc={handleGoToSettings}
                />
            )}
        </Box>
    )
};
