import ArrowLeftSmallIcon from "shared/assets/icons/ArrowLeftSmall";
import React, {useEffect, useState} from 'react';
import {Box, Button, IconButton, Typography} from "@mui/material";
import {ChipSeverity} from "shared/ui/SpanSeverity";
import theme from "../../../app/Theme";
import CopyIcon from "../../../shared/assets/icons/Copy";
import PenIcon from "../../../shared/assets/icons/Pen";
import TrashIcon from "../../../shared/assets/icons/Trash";
import {DefinitionRenderCompact} from "../../../widgets/weatherDefinition/definitionRender/DefinitionRenderCompact";
import {fetchConfigurationGroup, fetchContacts, fetchWeatherDefinition} from "../../../pages/alerts/api/FetchAlerts";
import MapPinIcon from "../../../shared/assets/icons/MapPin";
import PausedChip from "../../../widgets/alertConfig/ui/PausedChip";
import {api} from "../../../shared/libs/Auth";

const AlertConfigurationDetails = ({alert, extendedAlertConfig, onChange}) => {

    const [definition, setDefinition] = useState(null);
    const [reportIncluded, setReportIncluded] = useState(false);
    const [isEnable] = useState(extendedAlertConfig[0].enabled);
    const [contacts, setContacts] = useState(null);
    const [userName, setUserName] = useState(null);

    useEffect(() => {
        fetchWeatherDefinition(alert.definition_id).then(data => {
            data.groups = data.rules;
            setDefinition(data);
        });
        fetchContacts().then(data => {
            setContacts(data)
        });
        api.get('/user')
            .then(response => {
                const [data] = response.data;
                setUserName(data.username);
            })
            .catch(error => {
                console.error('Error receiving user data:', error);
            })
    }, []);

    useEffect(() => {
        if (alert.groupId) {
            fetchConfigurationGroup(alert.groupId).then(data => {
                if (data && data.notification.report) {
                    setReportIncluded(data.notification.report)
                }
            });
        }
    }, [alert.groupId]);

    const getDays = () => {
        if (!extendedAlertConfig[0]?.notification?.days_checked) return null

        const days = extendedAlertConfig[0]?.notification?.days
            ? Object.entries(extendedAlertConfig[0].notification.days)
                .filter(([day, isActive]) => isActive)
                .map(([day]) => day)
            : [];

        return days.length > 0 ? days.join(', ') : null;
    }

    const getNotificationContacts = (type = "email" || "phone") => {
        return contacts?.filter(contact => {
            const config = extendedAlertConfig[0].notification.contacts[contact.id];
            return config && config[type] === true;
        })
            .map(contact => renderNotificationContacts(contact.name))
            .concat(extendedAlertConfig[0].notification[`allow_self_notification_${type === "phone" ? "sms" : "email"}`] ? renderNotificationContacts(userName) : []);
    };

    const renderNotificationContacts = (name) => (
        <div style={{
            padding: "5px 8px",
            backgroundColor: "var(--palette-grey-100)",
            borderRadius: "8px"
        }}>
            {name}
        </div>
    );

    const getCustomMessages = () => {
        const messagesConfig = [
            {key: 'fc_appears_checked', message: 'Forecast appears'},
            {key: 'fc_updates_checked', message: 'Forecast updates'},
            {key: 'we_starts_checked', message: 'Weather events starts'},
            {key: 'custom_time_checked', message: 'Custom time'}
        ];

        const customMessages = messagesConfig
            .filter(({key}) => extendedAlertConfig[0].notification[key])
            .map(({message}) => message);

        return customMessages.length > 0 ? customMessages.join(', ') : null;
    };

    if (!definition) return (<></>)

    const locations = [];
    alert.locations.forEach((location) => {
        locations.push(
            <Box sx={{
                background: '#fff',
                lineHeight: '28px',
                borderRadius: '12px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
            }}>
                <Box>
                    {<>
                        <span style={{
                            fontSize: "14px",
                            color: theme.palette.grey[700],
                            backgroundColor: theme.palette.grey[100],
                            borderRadius: '8px',
                            padding: '4px 10px',
                            marginRight: '10px'
                        }}>
                                <span style={{whiteSpace: 'nowrap'}}><MapPinIcon sx={{
                                    verticalAlign: 'middle',
                                    paddingBottom: '2px',
                                    width: '16px',
                                    marginRight: '5px'
                                }}/>{location.label}</span>
                        </span>{' '}</>
                    }
                </Box>
            </Box>
        )
    })

    return (
        <Box className={'fullHeight fullWidth settings'}>
            <Box className={'AlertConfigurationDetails'}
                 sx={{height: 'auto', borderBottom: "1px solid var(--palette-grey-100)"}}>
                <Box className={'settings-toolbar'}>
                    <IconButton onClick={onChange} variant={'outlined'} size={'small'}>
                        <ArrowLeftSmallIcon size={'small'}/>
                    </IconButton>
                    <Box sx={{color: '#5a6672', fontSize: '24px', whiteSpace: 'nowrap'}}>{alert.type}</Box>
                    <ChipSeverity severity={alert.severity} size="medium"/>
                    <PausedChip isEnable={isEnable}/>
                    <Box sx={{flexGrow: 1}}/>
                    <Box sx={{display: 'flex', gap: '10px'}}>
                        {
                            <Button
                                variant={'outlined'}
                                color={'secondary'}
                                startIcon={<CopyIcon/>}
                                onClick={() => onChange('duplicate', alert)}
                            >
                                Duplicate
                            </Button>
                        }
                        {<>
                            <Button
                                variant={'outlined'}
                                startIcon={<PenIcon/>}
                                onClick={() => onChange('edit', alert)}
                            >
                                Edit
                            </Button>
                            <Button
                                variant={'outlined'}
                                color={'error'}
                                startIcon={<TrashIcon/>}
                                onClick={() => {
                                    onChange('single_delete', alert);
                                }}
                            >
                                Delete
                            </Button>
                        </>}
                    </Box>
                </Box>
            </Box>
            <Box style={{margin: '10px', padding: '10px', display: "flex", flexDirection: "column", gap: "16px"}}>
                <Box style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    border: "1px solid var(--palette-grey-100)",
                    borderRadius: "12px",
                    padding: "24px"
                }}>
                    <Typography style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "var(--palette-grey-600)"
                    }}>Parameters</Typography>
                    <Box className="paragraph medium" sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        color: theme.palette.grey[600],
                        borderRadius: '10px',
                        border: '1px solid #f0f2f5',
                        alignItems: "center",
                        padding: "16px"
                    }}>
                        <div style={{
                            marginRight: "16px",
                            color: "var(--palette-grey-600)",
                            fontWeight: "500",
                            fontSize: "12px"
                        }}>LOCATIONS:
                        </div>
                        {locations}
                    </Box>
                    <Box className="paragraph medium" sx={{
                        color: theme.palette.grey[600],
                        padding: '16px',
                        borderRadius: '10px',
                        border: '1px solid #f0f2f5',
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px"
                    }}>
                        <div className={'row'}
                             style={{paddingBottom: "16px", borderBottom: "1px solid var(--palette-grey-100)"}}>
                            <div style={{
                                color: "var(--palette-grey-600)",
                                fontWeight: "500",
                                fontSize: "12px"
                            }}>WEATHER DEFINITION:
                            </div>
                            {definition.name}
                            <ChipSeverity severity={alert.severity} size="small"/>
                        </div>
                        {definition.rules && <DefinitionRenderCompact definition={definition}/>}
                        {!definition.rules && <Box>This is a fixed weather definition</Box>}
                    </Box>
                    <Box className="paragraph medium" sx={{
                        color: theme.palette.grey[600],
                        padding: '16px',
                        borderRadius: '10px',
                        border: '1px solid #f0f2f5',
                        display: "flex",
                        gap: "12px"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            width: "50%",
                            flexWrap: "wrap"
                        }}>
                            <div className={'row'}>
                                <div style={{
                                    color: "var(--palette-grey-600)",
                                    fontWeight: "500",
                                    fontSize: "12px"
                                }}>WHEN:
                                </div>
                                <div style={{fontWeight: "400", fontSize: "14px", color: "var(--palette-grey-900)"}}>
                                    {getCustomMessages()}
                                </div>
                            </div>
                            {getDays() &&
                                <div className={'row'}>
                                    <div style={{
                                        color: "var(--palette-grey-600)",
                                        fontWeight: "500",
                                        fontSize: "12px"
                                    }}>ON:
                                    </div>
                                    <div
                                        style={{fontWeight: "400", fontSize: "14px", color: "var(--palette-grey-900)"}}>
                                        {getDays()}
                                    </div>
                                </div>
                            }
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "8px",
                            width: "50%",
                            flexWrap: "wrap"
                        }}>
                            <div className={'row'}>
                                <div style={{
                                    color: "var(--palette-grey-600)",
                                    fontWeight: "500",
                                    fontSize: "12px"
                                }}>EMAIL:
                                </div>
                                <div style={{
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    color: "var(--palette-info-dark)",
                                    display: "flex",
                                    gap: "8px",
                                    flexWrap: "wrap"
                                }}>
                                    {getNotificationContacts("email")}
                                </div>
                            </div>
                            <div className={'row'}>
                                <div style={{
                                    color: "var(--palette-grey-600)",
                                    fontWeight: "500",
                                    fontSize: "12px"
                                }}>SEND SMS:
                                </div>
                                <div style={{
                                    fontWeight: "400",
                                    fontSize: "14px",
                                    color: "var(--palette-info-dark)",
                                    display: "flex",
                                    gap: "8px",
                                    flexWrap: "wrap"
                                }}>
                                    {getNotificationContacts("phone")}
                                </div>
                            </div>
                        </div>
                    </Box>
                    <Typography style={{
                        fontWeight: "500",
                        fontSize: "16px",
                        color: "var(--palette-grey-600)"
                    }}>Reports</Typography>
                    <Box className="paragraph medium" sx={{
                        display: 'flex',
                        color: theme.palette.grey[600],
                        padding: '10px',
                        borderRadius: '10px',
                        border: '1px solid #f0f2f5'
                    }}>
                        Report included? {reportIncluded ? "Yes" : "No"}
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default AlertConfigurationDetails;