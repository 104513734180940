import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    IconButton,
    Chip
} from '@mui/material';
import PenIcon from "shared/assets/icons/Pen";
import CustomMessagePopup, {tokens} from 'features/notification/CustomMessagePopup';


export default function DefaultNotifications({ message, title, onChange, messageType }) {

    const [showMessagePopup, setShowMessagePopup] = useState(false);
    const [tokenizedContent, setTokenizedContent] = useState([]);

    const handleChange = (newText) => {
        onChange(messageType, newText);
    };

    useEffect(() => {
        const words = message.split(" ");
        const newContent = words.reduce((acc, word, index) => {
            word = word.trim()
            const isToken = word.startsWith('{{') && word.endsWith('}}');
            const tokenId = isToken ? word.replaceAll(/[{}]/g, '') : null;
            const token = isToken ? tokens.find(t => t.id === tokenId) : null;
    
            if (token) {
                acc.push(
                    <Chip
                        key={`${tokenId}-${index}`}
                        style={{margin: '1px 3px', height: '25px'}}
                        contentEditable={false}
                        label={token.displayName}
                    />
                );
            } else if (isToken && !token) {
                // If this is token format, but token not found add as word
                acc.push(word);
            } else {
                // plain text
                acc.push(word);
            }
    
            // Add space after word except for the last
            if (index < words.length - 1) {
                acc.push(' ');
            }
    
            return acc;
        }, []);
    
        setTokenizedContent(newContent);
    }, [message, tokens]);

    return(
        <>
            <Box className={'column gap8 fullWidth'}>
                <Typography style={{color: 'var(--palette-grey-600)'}}>
                    {title}
                </Typography>
                <Box className={'row'} style={{justifyContent: 'space-between'}}>
                    <Typography>
                        {tokenizedContent}
                    </Typography>
                    <IconButton
                        variant={'outlined'}
                        size={'tiny'}
                        onClick={() => setShowMessagePopup(true)}
                    >
                        <PenIcon size={'small'}/>
                    </IconButton>

                </Box>
            </Box>
        
        <CustomMessagePopup
            visible={showMessagePopup}
            onCloseModal={() => setShowMessagePopup(false)}
            messageText={message}
            tokens={tokens}
            onChange={handleChange}
        />
        </>
    )
}
