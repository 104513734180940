import {Dialog, IconButton, Box, Button, Divider, Chip, Popover} from '@mui/material'
import CloseIcon from 'shared/assets/icons/Close'
import React, {useEffect, useState} from 'react'
import {ReactComponent as AlertRectangle} from '../assets/svg/confirmationPopUp/AlertRectangle.svg'
import {ReactComponent as ConfirmationRectangle1} from 'shared/assets/svg/confirmationPopUp/ConfirmationRectangle1.svg'
import {
    ReactComponent as ConfirmationRectangle2
} from 'shared/assets/svg/confirmationPopUp/ConfirmationAlertRectangle.svg'
import {ReactComponent as ConfirmationPin1} from 'shared/assets/svg/confirmationPopUp/ConfirmationPin.svg'
import {ReactComponent as ConfirmationAlertPin} from 'shared/assets/svg/confirmationPopUp/ConfirmationAlertPin.svg'
import {ReactComponent as ConfirmationIcon} from 'shared/assets/svg/confirmationPopUp/ConfirmationItemLow.svg'
import {ReactComponent as Background} from 'shared/assets/svg/confirmationPopUp/BackgroundAconfirmation.svg'
import theme from "../../app/Theme";
import {fetchRealLocations} from "../../pages/alerts/api/FetchAlerts";
import MapMarkerIcon from "../assets/icons/MapMarker";
import MapPinIcon from "../assets/icons/MapPin";

export default function AlertConfirmationModal({agreeFunc, goToSettingsFunc, selectedDefinitions, selectedLocations}) {

    const [isSingleWeatherDefinition] = useState(selectedDefinitions.length <= 1);
    const [locations, setLocations] = useState();
    const [anchorElLocs, setAnchorElLocs] = React.useState(null);

    useEffect(() => {
        if (isSingleWeatherDefinition) {
            fetchRealLocations().then((locs) => {
                const selectedLocs = locs.location.filter((item) => selectedLocations.has(item.id));
                setLocations(selectedLocs)
            })
        }
    }, []);

    const openLocations = (event) => {
        event.stopPropagation();
        setAnchorElLocs(event.currentTarget);
    };

    console.log("locations", locations)

    const content = (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '504px',
            gap: '24px',
            padding: '12px 16px'
        }}>
            <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center'}}>
                <Box
                    className='modal-header'
                    sx={{display: 'flex'}}
                >
                    Well done, your alert is created!
                </Box>
                <IconButton
                    className='modal__close-button'
                    onClick={agreeFunc}
                    sx={{marginLeft: 'auto', padding: '4px', width: '32px', height: '32px'}}
                >
                    <CloseIcon sx={{width: '16px', height: '16px'}}/>
                </IconButton>
            </Box>
            <Box style={{position: "relative", height: "80px", overflow: "hidden"}}>
                <Box style={{position: "absolute", left: '0px', top: '0px'}}>
                    <Background/>
                </Box>
                <Box style={{position: "absolute", left: '0px', top: '0px'}}>
                    <ConfirmationRectangle1/>
                </Box>
                <Box style={{position: "absolute", left: '-25px', top: '35px'}}>
                    <ConfirmationRectangle2/>
                </Box>
                <Box sx={{position: 'absolute', left: '205px', top: '10px'}}>
                    <ConfirmationPin1/>
                </Box>
                <Box sx={{position: 'absolute', right: '120px', top: '52px'}}>
                    <ConfirmationIcon/>
                </Box>
                <Box sx={{position: 'absolute', right: '0px', top: '0px', overflow: "hidden"}}>
                    <AlertRectangle style={{borderRadius: "0px 12px 12px 0px"}}/>
                </Box>
                <Box sx={{position: 'absolute', right: '92px', top: '0px'}}>
                    <ConfirmationAlertPin/>
                </Box>
            </Box>
            {isSingleWeatherDefinition ?
                <Box style={{
                    height: "48px",
                    padding: "8px 16px",
                    border: "1px solid var(--palette-grey-100)",
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                    <div style={{fontSize: "16px", fontWeight: "500", color: "var(--palette-grey-900)"}}>
                        {selectedDefinitions[0].name}
                    </div>
                    <div style={{display: "flex", gap: "8px"}}>
                        {locations &&
                            <>
                                <Chip
                                    icon={<MapMarkerIcon size={'small'}/>}
                                    label={locations.length > 0 ? locations[0].label : ''}
                                    size={'medium'}
                                />
                                {locations.length > 1 &&
                                    <>
                                    <span className="paragraph" style={{
                                        display: "flex",
                                        width: "32px",
                                        height: "32px",
                                        border: "1px solid var(--palette-grey-100)",
                                        borderRadius: "8px",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                        color: "var(--palette-primary-dark)",
                                        cursor: "pointer"
                                    }}
                                          onClick={openLocations}>
                                        +{locations.length - 1}
                                    </span>
                                        <Popover
                                            open={!!anchorElLocs}
                                            anchorEl={anchorElLocs}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setAnchorElLocs(null)
                                            }}
                                            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                            transformOrigin={{vertical: 'top', horizontal: 'right'}}
                                        >
                                            <Box
                                                sx={{maxWidth: "300px", display: "flex", flexWrap: "wrap", gap: "4px"}}>
                                                {locations.map(loc => (
                                                    <Chip
                                                        icon={<MapPinIcon size={'small'}/>}
                                                        label={loc.label}
                                                        size={'small'}
                                                        key={loc.id}
                                                    />
                                                ))}
                                            </Box>
                                        </Popover>
                                    </>
                                }
                            </>
                        }
                        <Chip
                            variant={theme.palette.severity.variant[selectedDefinitions[0].severity]}
                            size={'large'}
                            label={selectedDefinitions[0].severity}
                        />
                    </div>
                </Box> :
                <Box>
                    We've created a separate alert configuration for each weather definition. Once a weather event
                    triggers an alert, you can access and manage the individual alerts on the dedicated Alerts
                    page.
                </Box>
            }
            <Box
                className='modal-delete-text'
                sx={{width: '100%', fontSize: '16px'}}
            >
                {isSingleWeatherDefinition ? "You can overview an alert on the alert’s page once the weather event starts. To manage alert settings, go to Settings > Alerts." :
                    "To modify specific alert settings, navigate to Settings > Alerts and choose the desired alert."}
            </Box>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: "12px"}}>
                <Divider/>
                <Box sx={{display: 'flex', gap: '12px'}}>
                    <Button
                        style={{fontWeight: '400', height: '40px', width: '100%'}}
                        onClick={agreeFunc}
                    >
                        Got it
                    </Button>
                    <Button
                        style={{fontWeight: '400', height: '40px', width: '100%'}}
                        variant={'outlined'}
                        color={'secondary'}
                        onClick={goToSettingsFunc}
                    >
                        Go to Settings
                    </Button>
                </Box>
            </Box>
        </Box>
    )
    return (
        <Dialog
            className='report-modal'
            open={true}
            onClose={agreeFunc}
            sx={{'& .MuiPaper-root': {maxWidth: '700px'}}}
        >
            {content}
        </Dialog>
    )
}
