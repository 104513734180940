import React, {useEffect, useState} from 'react';
import {Box, Button, Typography} from "@mui/material";
import {Spacer} from "../../../../shared/ui/Spacer";
import {SearchInput} from "../../../../shared/ui/SearchInput";
import {isEmpty} from "../../../../shared/libs/Utils";
import EmptyStateActionItems from "../../../../shared/ui/emptyStates/EmptyStateActionItems";
import ActionItemsSettingsList from "./ActionItemsSettingsList";
import {
    createActionItem,
    deleteActionItem,
    editActionItem,
    fetchActionItems,
    fetchActionItemsCategories
} from "../../../alerts/api/FetchAlerts";
import UrgencySelector from "./UrgencySelector";
import CategorySelector from "./CategorySelector";
import ActionItemDetails from "./ActionItemDetails";
import EditActionItemPage from "./EditActionItemPage";
import "./ActionItems.css"
import ItemsSelectTopBar from "../../../../shared/ui/ItemSelectTopBar/ItemsSelectTopBar";
import ChangeUrgencyModal from "./ChangeUrgencyModal";
import AgreeModal from "../../../../shared/ui/AgreeModal";
import ProgressModal from "../../../../shared/ui/ProgressModal";

const ActionItemsSettings = () => {

    const [actionItems, setActionItems] = useState({});
    const [searchString, setSearchString] = useState('');
    const [urgency, setUrgency] = useState('All');
    const [allCategories, setAllCategories] = useState([]);
    const [chosenCategory, setChosenCategory] = useState('All');
    const [openActionItem, setOpenActionItem] = useState(null);
    const [editingActionItem, setEditingActionItem] = useState(null);
    const [isDuplicating, setIsDuplicating] = useState(false);
    const [selectedActionItems, setSelectedActionItems] = useState([]);
    const [openChangeUrgency, setOpenChangeUrgency] = useState(false);
    const [deletingActionItem, setDeletingActionItem] = useState(null);
    const [deletingMultipleActionItems, setDeletingMultipleActionItems] = useState(null);
    const [progressModal, setProgressModal] = useState(false);

    const allActionItemsSelected = Object.keys(actionItems).length === selectedActionItems.length;
    const someActionItemsSelected = Object.keys(actionItems).some(val => val) && !allActionItemsSelected;

    useEffect(() => {
        getActionItemsFunc()
        fetchActionItemsCategories().then((response) => setAllCategories(response))
    }, []);

    const getActionItemsFunc = () => {
        fetchActionItems().then((response) => setActionItems(response))
    }

    const deleteActionItemFunc = async (id) => {
        try {
            const response = await deleteActionItem(id);
            if (response && response.id) {
                setActionItems(prevItems => prevItems.filter(ai => ai.id !== id));
            } else {
                console.error("Error with deleting action item");
            }
        } catch (error) {
            console.error("Error during deletion:", error);
        } finally {
            getActionItemsFunc();
            setProgressModal(false);
        }
    };

    const deleteActionItemCheckAgree = (id, agree) => {
        if(agree){
            deleteActionItemFunc(id)
        }
        setDeletingActionItem(null)
    }

    const deleteMultipleActionItemsFunc = async (agree) => {
        if (agree) {
            setProgressModal(true);
            await Promise.all(selectedActionItems.map(ai => deleteActionItemFunc(ai.id)));
            setSelectedActionItems([]);
        }
        setDeletingMultipleActionItems(null);
    };

    const searchStringChanged = ({target}) => {
        setSearchString(target.value.trim().toLocaleLowerCase());
    };

    const handleUrgencyFilterChange = (event) => {
        setUrgency(event.target.value);
    };

    const handleCategoryFilterChange = (event) => {
        setChosenCategory(event.target.value);
    };

    const onAction = (action, actionItem, value = false) => {
        console.log("onAction", action, actionItem, value);
        if (action === 'open') {
            setOpenActionItem(actionItem);
        } else if (action === 'new_actionItem') {
            setEditingActionItem({});
        } else if (action === 'edit_actionItem') {
            setEditingActionItem(actionItem);
        } else if (action === 'duplicate_actionItem') {
            setIsDuplicating(true);
            setEditingActionItem(actionItem);
        }else if (action === 'save_actionItem') {
            if (actionItem.id) {
                editActionItem(actionItem).then((response) => { // edit exist action item
                    if (response && response.id) {
                        getActionItemsFunc();
                    } else {
                        console.error("Error with editing action item");
                    }
                });
            }
            else {
                createActionItem(actionItem).then((response) => { // create new action item
                    if (response && response.id) {
                        getActionItemsFunc();
                    } else {
                        console.error("Error with creating action item");
                    }
                });
            }
        } else if (action === 'delete_actionItem') {
            setDeletingActionItem(actionItem)
        } else if (action === 'select') {
            const id = actionItem.id;
            if (selectedActionItems.some(item => item.id === id)) {
                setSelectedActionItems(selectedActionItems.filter(item => item.id !== id));
            } else {
                setSelectedActionItems([...selectedActionItems, actionItem]);
            }
        } else if (action === 'selectall') {
            const allActionItems = actionItems.map(item => item.id);
            if (allActionItems.length === selectedActionItems.length) {
                setSelectedActionItems([]);
            } else {
                const newItems = actionItems.filter(item => !selectedActionItems.some(selected => selected.id === item.id));
                setSelectedActionItems([...selectedActionItems, ...newItems]);
            }
        } else if (action === 'delete') { // mass deleting
            setDeletingMultipleActionItems(selectedActionItems)
        } else if (action === 'mass_change_urgency') {
            setOpenChangeUrgency(true);
        } else if (action === 'mass_save_urgency') {
            try {
                selectedActionItems.forEach((ai) => {
                    const changedActionItem = {
                        ...ai,
                        urgency: value,
                        category: ai.category.id,
                        weather_definition: ai.weather_definition.id
                    };
                    return editActionItem(changedActionItem);
                });
            } catch (error) {
                console.error("Error during urgency update:", error);
            } finally {
                getActionItemsFunc();
                setSelectedActionItems([]);
                setOpenChangeUrgency(false);
            }
        }
    };

    return (
        <>
            <div className="settings" style={{display: (openActionItem || editingActionItem) ? "none" : "flex"}}>
                <div className={"settings-toolbar"} style={{borderBottom: "1px solid var(--palette-grey-100)"}}>
                    <h3>
                        Action items
                    </h3>

                    <div style={{display: "flex", gap: "8px"}}>
                        <SearchInput
                            style={{width: "304px"}}
                            placeholder='Search'
                            value={searchString}
                            onChange={searchStringChanged}
                        />
                        <UrgencySelector urgency={urgency} onChange={handleUrgencyFilterChange}/>
                        <CategorySelector allCategories={allCategories} category={chosenCategory}
                                          onChange={handleCategoryFilterChange}/>
                    </div>

                    <Spacer/>

                    <Button onClick={() => onAction("new_actionItem", {})}>
                        New action item
                    </Button>
                </div>

                <ItemsSelectTopBar
                    type={"actionitems"}
                    onAction={(alert, action, value) => onAction(action, alert, value)}
                    visible={selectedActionItems.length > 0}
                    allSelected={allActionItemsSelected}
                    indeterminate={someActionItemsSelected}
                />

                {isEmpty(actionItems) ?
                    <div style={{width: "100%", height: "100%"}}>
                        <EmptyStateActionItems title={"Action items will appear here"}
                                               text={"You will see action items for reports here once they are created"}/>
                    </div> :
                    <ActionItemsSettingsList actionItems={actionItems} searchString={searchString} urgency={urgency}
                                             chosenCategory={chosenCategory}
                                             selectedActionItems={selectedActionItems}
                                             onChange={(action, actionItem) => {
                                                 onAction(action, actionItem)
                                             }}
                    />
                }
            </div>

            {openActionItem && <ActionItemDetails actionItem={openActionItem} onChange={(action) => {
                onAction(action, openActionItem)
                setOpenActionItem(null);
            }}/>}

            {editingActionItem &&
                <EditActionItemPage
                    defaultValue={editingActionItem}
                    onChange={(action, actionItem) => {
                        onAction(action, actionItem);
                        setEditingActionItem(null);
                        setIsDuplicating(false);
                    }}
                    allCategories={allCategories}
                    isDuplicating={isDuplicating}
                />
            }

            <ChangeUrgencyModal visible={openChangeUrgency} onChange={(action, actionItem, value) => {
                onAction(action, actionItem, value);
                setOpenChangeUrgency(false)
            }}/>

            {deletingActionItem &&
                <AgreeModal
                    data={{
                        message: `Are you sure you want to delete this action item?`,
                        title: "Delete action item",
                        agreeMsg: "Delete",
                        mode: "deleting",
                        agreeFunc: (agree) => deleteActionItemCheckAgree(deletingActionItem.id, agree)
                    }}
                />
            }

            {deletingMultipleActionItems &&
                <AgreeModal
                    data={{
                        message: <Box className='column' sx={{alignContent: 'stretch', overflow: "hidden", "&.MuiBox-root": {width:"100%"}}}>
                            <Typography sx={{fontSize: "18px"}}>Are you sure you want delete <b>{`${deletingMultipleActionItems.length}`}</b> selected action items?</Typography>
                            <Box sx={{maxHeight: "100px", display:"flex", flexDirection:"column", flexWrap:"wrap"}}>{deletingMultipleActionItems.map(actionItem => <span>• {actionItem.name}<br/></span>)}</Box>
                        </Box>,
                        title: "Delete multiple action items",
                        agreeMsg: "Delete",
                        mode: "deleting",
                        agreeFunc: deleteMultipleActionItemsFunc
                    }}
                />
            }

            <ProgressModal visible={progressModal}/>
        </>)
}


export default ActionItemsSettings;