import React, {useState} from 'react';
import {Box, Button, ClickAwayListener, IconButton, MenuItem} from '@mui/material';
import { Spacer } from "shared/ui/Spacer";
import DesktopIcon from "../../../shared/assets/icons/Desktop";
import './Map.css';
import ClearButton from "../../../features/map/ui/clearButton/ClearButton";
import PlusIcon from "../../../shared/assets/icons/Plus";
import DesktopLoadIcon from "../../../shared/assets/icons/DesktopLoad";
import ArrowRightSmallIcon from "../../../shared/assets/icons/ArrowRightSmall";
import DragVerticalIcon from "../../../shared/assets/icons/DragVertical";
import PenIcon from "../../../shared/assets/icons/Pen";
import TrashIcon from "../../../shared/assets/icons/Trash";
import theme from "../../../app/Theme";
import DesktopSaveIcon from "../../../shared/assets/icons/DesktopSave";

export default function MapMenu({onSwitchProduct, onSwitchLocation, onSwitchLocationHash, activeProducts, activeLocations, onClearLocations, onClearLayers, onClearPqPopups, onAllClear,
                                    views = [], setOpenAddLocations = () => {}, onViewAction, locationHash }) {

    const [showMenu, setShowMenu] = useState(false);
    const [showLoadMenu, setShowLoadMenu] = useState(false);

    const loadMenuItems = views || [];
    const loadMenuItemsObj = {};
    for (let idx in views) {
        loadMenuItemsObj[views[idx].id] = views[idx];
    }

    const hideMenu = () => {
        setShowMenu(false);
        setShowLoadMenu(false);
    }

    const onClickLoadView = (e) => {
        hideMenu();
        const id = e.target.parentElement.dataset.id;
        const view = loadMenuItemsObj[id];
        const newSelectedLayers = view["includes"].layers ? view.data.layers : activeProducts;
        const newSelectedLocation = view["includes"].locations ? view.data.locations : activeLocations;
        const newMapPosition = view["includes"].position ? view.data.position : locationHash;
        onSwitchProduct(newSelectedLayers);
        onSwitchLocation(newSelectedLocation);
        onSwitchLocationHash(newMapPosition);
    }

    const onClickSaveView = (e) => {
        e.stopPropagation();
        onViewAction('save_view', e.currentTarget.parentElement.dataset.id);
    }

    const onClickEditView = (e) => {
        e.stopPropagation();
        onViewAction('edit_view', e.currentTarget.parentElement.dataset.id);
    }

    const onClickDeleteView = (e) => {
        e.stopPropagation();
        onViewAction('delete_view', e.currentTarget.parentElement.dataset.id);
    }

    return (
        <Box className={'mapMenu toolbar padding'}>
            <h3>
                Map
            </h3>
            <Spacer />
            <Box style={{position:"relative"}}>
            <IconButton variant={'outlined'} onClick={() => {setShowMenu(!showMenu)}}>
                <DesktopIcon/>
            </IconButton>
                { showMenu &&
                    <ClickAwayListener onClickAway={hideMenu}>
                        <Box style={{
                            position:"absolute",
                            left:"-150px", top:"50px",
                            zIndex:'1', width: '200px',
                            backgroundColor: '#ffffff',
                            padding: '10px 15px',
                            cursor: 'default',
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px", boxShadow: "0px 1px 3px rgba(103, 122, 142, 0.24)",
                            borderRadius: "8px",
                            border: "0",
                        }}
                        >
                            <MenuItem onClick={(e) => {setShowMenu(!showLoadMenu); onClickSaveView(e)}}>
                                <DesktopSaveIcon size={'small'}/>
                                <Box className={'spacer'}>
                                    Save view
                                </Box>
                            </MenuItem>
                            <MenuItem onClick={() => {setShowLoadMenu(!showLoadMenu)}}>
                                <DesktopLoadIcon size={'small'}/>
                                <Box className={'spacer'}>
                                    Load view
                                </Box>
                                <ArrowRightSmallIcon size={'small'}/>
                            </MenuItem>
                            { showLoadMenu &&
                                <Box className='timeline-shadow' id='timeline-load-view-menu' sx={{position: 'absolute', left: 205, top: 39, minWidth: '220px',
                                    margin: '0', backgroundColor: '#ffffff', cursor: 'default',
                                    padding: '10px 15px', display: "flex", flexDirection: "column", gap: "4px", fontSize: "14px"
                                }}>
                                    {views.length ? (loadMenuItems.map(item => (
                                        <MenuItem key={item.id} data-id={item.id} sx={{display: 'flex', justifyContent: 'start', flexDirection: 'row', alignItems: 'center'}}
                                                  onMouseEnter={(e) => {e.currentTarget.children[2].style.opacity='1'}}
                                                  onMouseLeave={(e) => {e.currentTarget.children[2].style.opacity='0'}}
                                                  onClick={onClickLoadView}
                                        >
                                            <DragVerticalIcon size={'small'}/>
                                            <Box className={'spacer'}>
                                                {item.title}
                                            </Box>
                                            <Box sx={{opacity: '0'}} data-id={item.id}>
                                                <IconButton size={'tiny'} onClick={onClickEditView}>
                                                    <PenIcon size={'small'}/>
                                                </IconButton>
                                                <IconButton size={'tiny'} onClick={onClickDeleteView}>
                                                    <TrashIcon size={'small'} sx={{color: theme.palette.error.main}}/>
                                                </IconButton>
                                            </Box>
                                        </MenuItem>
                                    ))) : "No views have been saved yet"}
                                </Box>
                            }
                        </Box>
                    </ClickAwayListener>
                }
            </Box>
            <ClearButton
                clearLocations={onClearLocations}
                clearLayers={onClearLayers}
                allClear={onAllClear}
                clearPq={onClearPqPopups}
            />
            <Button
                variant={'contained'}
                color={'primary'}
                style={{width: "150px"}}
                startIcon={<PlusIcon size={'medium'}/>}
                onClick={setOpenAddLocations}
            >
                Add location
            </Button>
        </Box>
    )
}
