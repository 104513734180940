import InfoCircleIcon from "shared/assets/icons/InfoCircle";
import React, {useState} from 'react';
import {MenuItem, IconButton, ListSubheader, Divider, Box, Tooltip} from '@mui/material';
import CloseIcon from "../../../shared/assets/icons/Close";
import {NumberInput} from "shared/ui/NumberInput";
import {Spacer} from "shared/ui/Spacer";
import {productGroups, productsDict, DICTIONARY_RELATIONS} from '../../../entities/weatherDefinition/api/Products';
import {Select} from 'shared/ui/Select';
import "./WeatherDefinitionLineCreator.css"
import TextField from "@mui/material/TextField";
import SearchIcon from "../../../shared/assets/icons/Search";

const DICTIONARY_UNIONS = {
    'AND': 'AND',
    'OR': 'OR',
}

export function WeatherDefinitionLineCreator({defaultValue, onChange, onRemove, isFirst}){

    const [productFilter, setProductFilter] = useState('');
    const [isOpenSearching, setIsOpenSearching] = useState(false);

    // const products = productGroups;
    let product = defaultValue.product_name || ''; 
    let operator = defaultValue.relation || '';
    let value = defaultValue.values || [];
    let units = defaultValue.units || '';
    let union = (isFirst ? '': (defaultValue.union || DICTIONARY_UNIONS.AND));
    const apiProduct = productsDict[product];

    if (!apiProduct && product) {  // reset line for unavailable product
        product = '';
        operator = '';
        value = [];
        units = '';
    }

    const setProduct = (val) => {
        product = val;
        setUpdate();
        if (!productsDict[product]) return;
        let new_units = productsDict[product].units
        if (!new_units || new_units === 'text') new_units = '';
        setUnits(new_units);
        const allRelations = productsDict[product].relations;
        if (!allRelations || !allRelations.length)
            return;
        if (allRelations.some(r=>(r===operator)))
            return;
        if (productsDict[product].result_type === 'numeric' && productsDict[product].product_id.indexOf('spc-'))  // wl-294
            setOperator('>');  // wl-258
        else setOperator(allRelations[0]);
    };

    const setUpdate = () => {
        onChange({
            product_name: product,
            relation: operator,
            values: value,
            units,
            union,
            isValid: true
        })
    };

    const setValue = (val) => {value = val; setUpdate()}
    const setOperator = (val) => {operator = val; setUpdate()}
    const setUnits = (val) => {units = val; setUpdate()}
    const setUnion = (val) => {union = val; setUpdate()}

    const inputComponent = (val, val_idx) => { 
        if (!apiProduct || !apiProduct.allowed_text_values.length)
            if (val_idx === 0)
                return <NumberInput value={val[0]}
                                    onChange={value => setValue([value, val[1]])}/>;
            else if (val_idx === 1)
                return <NumberInput value={val[1]}
                                    onChange={value => setValue([val[0], value])}/>;
            else
                return <NumberInput value={val[0]}
                                    onChange={value => setValue([value])}/>;
        else {
            const items = apiProduct.allowed_text_values.map(value => {
                return <MenuItem key={value} value={value}>{value}</MenuItem>;
            });
            if (val_idx === 0)
                return (<Select value={val[0]} onChange={({target})=>setValue([target.value, val[1]])}>{items}</Select>);
            else if (val_idx === 1)
                return (<Select value={val[1]} onChange={({target})=>setValue([val[0], target.value])}>{items}</Select>);
            else
                return (<Select value={val[0]} onChange={({target})=>setValue([target.value])}>{items}</Select>);
        }
    }

    const DICTIONARY_RELATIONS_FIELDS_OPERANDS = {
        '=' : inputComponent(value),
        '!=' : inputComponent(value),
        '<' : inputComponent(value),
        '>' : inputComponent(value),
        '<=' : inputComponent(value),
        '>=' : inputComponent(value),
        '><': <Box sx={{display:"flex", alignItems:"center", gap:"8px"}}>{inputComponent(value, 0)} and {inputComponent(value, 1)}</Box>,
        'd><': <Box sx={{display:"flex", alignItems:"center", gap:"8px"}}>from {inputComponent(value, 0)} to {inputComponent(value, 1)}</Box>,
    }

    let uiMenu;
    if (apiProduct && apiProduct.units && apiProduct.units !== 'text') {
        let uiUnits = apiProduct.ui_units;
        if (!uiUnits) uiUnits = [apiProduct.units];
        const uiMenuItems = uiUnits.map(value => (<MenuItem key={value} value={value}>{value}</MenuItem>));
        uiMenu = (<Select value={units} onChange={({target})=>setUnits(target.value)}>{uiMenuItems}</Select>)
    }

    const filteredProducts = Object.values(productsDict).filter(product =>
        product.name.toLowerCase().includes(productFilter.toLowerCase())
    );

    let selectField = [];
    for (let idx in productGroups){
        const group = productGroups[idx];
        const groupProducts = group.products.filter(product => filteredProducts.some(fp => fp.name === product.name));
        if (groupProducts.length > 0) {
            selectField.push(<ListSubheader key={group.name}>{group.name}</ListSubheader>);
            selectField = selectField.concat(groupProducts.map(product => (
                <MenuItem
                    key={product.id}
                    value={product.name}
                    onClick={() => {
                        setProduct(product.name);
                        setValue([product.result_type === 'numeric' ? 0 : '']);
                        setProductFilter(''); setIsOpenSearching(false)
                    }}
                    sx={{padding:"10px"}}
                >
                    {product.name}
                </MenuItem>
            )));
        }
    }

    // let selectFieldOld = [];
    // for (let idx in productGroups){
    //     const group = productGroups[idx];
    //     selectFieldOld.push(<ListSubheader key={group.name}>{group.name}</ListSubheader>);
    //     for (let pidx in group.products) {
    //         const product = group.products[pidx];
    //         selectFieldOld.push(<MenuItem key={product.name} value={product.name}>{product.name}</MenuItem>);
    //     }
    // }

    let relationSelect = [];
    let allRelations = [];
    if (productsDict[product]) {
        allRelations = productsDict[product].relations;
        if (allRelations && allRelations.length){
            relationSelect = allRelations.map(r=>{
                return <MenuItem key={r} value={r}>{DICTIONARY_RELATIONS[r]}</MenuItem>
            });
        }
    }
    
    let unionSelectOptions=[];
    for (let key in DICTIONARY_UNIONS){
        unionSelectOptions.push(<MenuItem selected={union===key} key={key} value={key}>{DICTIONARY_UNIONS[key]}</MenuItem>);
    }

    const maxHeightValue = filteredProducts.length < 4 ? filteredProducts.length * 60 : 157;

    return (
        <div className={'column gap16 fullWidth'}>
            <Box sx={{ display:"flex", justifyContent:"flex-start", flexDirection:"row", gap:"8px", alignItems:"start", width:"100%"}}>
                    {!isFirst &&
                        <Select
                            value={union}
                            onChange={({target}) => {
                                setUnion(target.value);
                            }}
                        >
                            {unionSelectOptions}
                        </Select>
                    }

                    <Box sx={{display:"flex", flexDirection:"column"}}>
                        <TextField
                            data-cy={'search-products-input'}
                            value={productFilter || product}
                            onChange={event => {setProductFilter(event.target.value); setIsOpenSearching(true)}}
                            onClick={()=>setIsOpenSearching(!isOpenSearching)}
                            placeholder={'Search for products'}
                            style={{width:"355px"}}
                            InputProps={{
                                startAdornment: <SearchIcon size={'small'}/>,
                                endAdornment: productFilter || product ? (
                                    <IconButton onClick={() => {operator = ''; setProductFilter(""); setProduct(""); setValue([]);}}>
                                        <CloseIcon size={'small'}/>
                                    </IconButton>
                                ) : null
                            }}
                        />
                        <Box data-cy={'select-products-menu'} sx={{display:"flex", flexDirection:"column", overflowY:"auto", maxHeight:`${maxHeightValue}px`, backgroundColor: 'white', borderRadius: '12px'}}
                             className={isOpenSearching? "productsVisible" : "productsInvisible"}>
                             {selectField}
                        </Box>
                    </Box>
                {apiProduct?.description &&
                    <Tooltip title={apiProduct.description}>
                        <Box className={'column'} style={{height: '40px', justifyContent: 'center'}}>
                            <InfoCircleIcon size={'small'}/>
                        </Box>
                    </Tooltip>
                }


                    {/*<Select*/}
                    {/*    value={product}*/}
                    {/*    onChange={({target}) => {*/}
                    {/*        setProduct(target.value);*/}
                    {/*        setValue([]);*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    {selectFieldOld}*/}
                    {/*</Select>*/}

                    {product !== '' &&
                        <Select
                            disabled={relationSelect.length === 1}
                            value={operator}
                            onChange={({target}) => {
                                setOperator(target.value);
                            }}
                        >
                            {relationSelect}
                        </Select>
                    }
                    {operator && DICTIONARY_RELATIONS_FIELDS_OPERANDS[operator]}
                    {uiMenu && uiMenu}
                    <Spacer/>
                    <IconButton variant={'outlined'} size={'small'} onClick={onRemove} disabled={isFirst && !defaultValue.isValid}>
                        <CloseIcon size={'small'}/>
                    </IconButton>
            </Box>
            <Divider/>
        </div>
    )
}