import {Box, IconButton, Button, Typography} from '@mui/material';
import React from 'react';
import theme from '../../app/Theme';
import TrashIcon from '../../shared/assets/icons/Trash';
import { DefinitionRenderLarge } from '../../widgets/weatherDefinition/definitionRender/DefinitionRenderLarge';
import { ChipSeverity } from 'shared/ui/SpanSeverity';
import ArrowLeftSmallIcon from "shared/assets/icons/ArrowLeftSmall";
import TriggeredRules from "./TriggeredRules";
import NewReport from "./NewReport";
import AgreeModal from "shared/ui/AgreeModal";

export default function ReportDetails({report, onAction, editing= undefined}) {

    let definition = report.weatherdefinition;
    let triggeredRules = report?.alert?.triggered_rules;
    // let loc = report.alertconfiguration.location;
    // let locationGroup = loc.location_group;
    // let locationName = loc.label;
    // if (locationGroup) {
    //     locationName = locationGroup+"/"+locationName;
    // }
    definition.groups = definition.rules;

    const [isEditing, setIsEditing] = React.useState(editing);
    const [modalReport, setModalReport] = React.useState(false)

    const doAction = (report, action, value) =>{
        if(action === 'cancel_edit_report'){
            setModalReport(true);
        }
        if(action === 'edit_report'){
            onAction(undefined, "edit_report_from_details", value)
            setIsEditing(value.report);
        }
    }

    return (
        <>
        {!isEditing &&
        <Box className="ReportDetails container">
                <Box className={'toolbar'}>
                    <IconButton size={'small'} variant={'outlined'} onClick={(ev) => { if (ev) ev.stopPropagation(); onAction(report, 'close_report'); }}>
                        <ArrowLeftSmallIcon size={'small'}/>
                    </IconButton>
                    <h3 className={'regular'}>
                        {definition.name}
                    </h3>
                    <Box sx={{marginLeft: 'auto', display: 'flex', gap: '10px'}}>
                        {/*<Button variant={'secondary blue'}*/}
                        {/*        onClick={(ev) => { if (ev) ev.stopPropagation(); onAction={doAction}; setIsEditing(report) }}*/}
                        {/*        sx={{fontWeight: 400, width: 'unset', fontSize: '16px'}}><PenIcon sx={{verticalAlign: 'middle', marginRight: '5px'}}/>Edit*/}
                        {/*</Button>*/}
                        <Button variant={'outlined'} color={'error'} onClick={(ev) => { if (ev) ev.stopPropagation(); onAction(report, 'delete_report'); }}>
                            <TrashIcon sx={{verticalAlign: 'middle', marginRight: '5px', color: theme.palette.weather.Critical.dark}}/>Delete
                        </Button>
                    </Box>
                </Box>
                <Box className={'column gap16 fullWidth'} sx={{ padding: '16px', borderRadius: '12px', border: '1px solid #f0f2f5'}}>
                    {/*<Box className={'row gap8'}>*/}
                    {/*    <Typography sx={{color: theme.palette.text.secondary}}>*/}
                    {/*        Location:*/}
                    {/*    </Typography>*/}
                    {/*    <Chip*/}
                    {/*        icon={<MapPinIcon size={'small'}/>}*/}
                    {/*        label={locationName}*/}
                    {/*        size={'small'}*/}
                    {/*    />*/}
                    {/*</Box>*/}
                    <Box className="row gap8">
                        <Typography sx={{color: theme.palette.text.secondary}}>
                            Weather definition:
                        </Typography>
                        <Typography sx={{color: theme.palette.text.primary}}>
                            {definition.name}
                        </Typography>
                        <ChipSeverity severity={definition.severity}/>
                    </Box>
                    {triggeredRules &&
                        <TriggeredRules rules={triggeredRules} sx={{color: theme.palette.text.primary}}/>
                    }
                    {definition.rules && <DefinitionRenderLarge definition={definition}/>}
                    {!definition.rules &&
                        <Typography sx={{color: theme.palette.text.primary}}>
                            This is a fixed weather definition
                        </Typography>
                    }
                </Box>
           </Box>
            }
            {isEditing &&
                <NewReport sx={{width:'100%'}} onAction={doAction} defaultValue={isEditing}/>
            }
            {modalReport &&
                <AgreeModal
                    data={{
                        message: `Are you sure you want discard your changes?`,
                        title: "Discard report editing",
                        agreeMsg: "Discard",
                        agreeFunc: agree => {
                                    if (agree) {
                                        setIsEditing(null);
                                        onAction(report, "cancel_edit_report_from_details");
                                    }
                                        setModalReport(false)
                                    },
                    }}
                />
            }
        </>
)}

